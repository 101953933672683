const styles = theme => ({
    root: {
        height: '100vh',
        maxHeight: '100%',
        width: '100%',
        display: "block",
        background: "#2c3e50",
        padding: "0px !important",
    },
    boxes: {
        height: "400px",
        display: "block",
        width: "80%",
        margin: "auto",
        padding: "0px",
    },
    btnfixed: {
        position: 'relative',
    },
    boxes2:
    {
        textAlign: "center",
    },
    videostyle: {
        width: "45%",
        boxShadow: '0 6px 16px 3px rgba(99, 91, 93, 0.3)',
        height: "300px",
        background: 'black',
        margin: '10px',
    },
    info:
    {
        fontSize: '15px',
        color: 'white',
    },
});

export default styles;
