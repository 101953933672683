/* eslint-disable react/prop-types */
import React, { useEffect } from "react";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import { clearLS, getFromLS } from "../../utils";
import { FirebaseService } from "../../Services/FirebaseService";

const styles = () => ({
  paper: {
    height: "100%",
    width: "100%",
    paddingTop: "300px",
    boxShadow: "none",
    paddingLeft: "40px",
    position: "relative",
    background: "#c3c3c3",
  },
  grid: {
    height: 720,
    width: 1000,
    padding: 0,
    margin: "auto",
  },
  pointer: {
    cursor: "pointer",
  },
});
const useStyles = makeStyles(styles);


export const EndedValidationSlide = ({ current, next, setVisibility }) => {
  const classes = useStyles();
  const nextScreen = () => {
    next(current + 1);
  };


  useEffect(() => {
    if (setVisibility) { setVisibility(true); }

    console.log("Salvar dados na base de dados Firebase.");

    // 1. buscar no local storage tudo o que foi salvo
    const resultsUser = {};
    resultsUser.userInfo = getFromLS("user");
    resultsUser.datetime = new Date().toISOString();
    resultsUser.validation = {};

    for (let j = 1; j <= 24; j += 1) {
      resultsUser.validation[`level-${j}-${j}`] = getFromLS(
        `memoria_level_${j}-${j}`
      );
    }

    clearLS();

    console.log("Dados do Participantes: ", resultsUser);
    FirebaseService.pushData("participants", resultsUser);

    /*
        Get all participants data
    FirebaseService.getDataList("participants", (list) => {
      console.log("list", list);
    }); */
  }, []);


  return (
    <div className={classes.grid}>
      <Paper
        onClick={nextScreen}
        className={`${classes.paper}  ${classes.pointer}`}
      />
    </div>
  );
};
