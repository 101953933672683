import React from "react";
import Inbox from "@material-ui/icons/MoveToInbox";
import Dashboard from "@material-ui/icons/Dashboard";
import DuoIcon from '@material-ui/icons/Duo';
import I18nProvider from "../i18nProvider/i18nProvider";
import * as locales from "./locales/Menu";

// uses https://www.npmjs.com/package/@material-ui/icons to find the icons

const Menu = [
  {
    label: <I18nProvider localeObj={locales} termKey="results" />,
    pathname: "/results",
    icon: <Inbox />,
    childKey: 1
  },
  {
    label: <I18nProvider localeObj={locales} termKey="conjuncao" />,
    pathname: "/conjuncao",
    icon: <Dashboard />,
    childKey: 2
  },
  {
    label: <I18nProvider localeObj={locales} termKey="relacional" />,
    pathname: "/relacional",
    icon: <Dashboard />,
    childKey: 2
  },
  {
    label: <I18nProvider localeObj={locales} termKey="binding" />,
    pathname: "/binding",
    icon: <Dashboard />,
    childKey: 2
  },
  {
    label: <I18nProvider localeObj={locales} termKey="softbinding" />,
    pathname: "/softbinding",
    icon: <Dashboard />,
    childKey: 2
  },
  {
    label: <I18nProvider localeObj={locales} termKey="validation" />,
    pathname: "/validation",
    icon: <Dashboard />,
    childKey: 2
  },
  {
    label: <I18nProvider localeObj={locales} termKey="video" />,
    pathname: "/video",
    icon: <DuoIcon />,
    childKey: 2
  }
];

export default Menu;
