/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import { AnswerColorSlide, RetensionSlide, ColorChoiceSlide } from "./ColorSlides";
import { isInvertedNow, getCurrentInvertedState, correctAnswer, _showConsoleInformation, getDistanceForAnswers, _showUserClick, requestAgainOrNewLevel } from "../../../../utils";

const schema = {
  0: {},
  1: {
    timeout: "1500",
    nColors: 1,
    correctTrials: 0,
    trials: 2,
    userAnswers: [],
    answers: [],
  },
  2: {
    timeout: "3000",
    nColors: 2,
    correctTrials: 0,
    trials: 2,
    userAnswers: [],
    answers: [],
  },
  3: { stop: true },
};

// level here means sublevel (level = internal step)
const TrainingColorRunning = ({ current, nextLvl, nextSubLevel, currentTheme }) => {
  const [level, setLevel] = useState(1);
  const [currentSlide, setCurrentSlide] = useState(null);
  const [reply, setReply] = useState();

  // updating trial, almost there
  useEffect(() => {
    if (reply != 0) return;
    // let's back to answerLoop
    setCurrentSlide(<AnswerColorSlide
      func={chosenColor} isInverted={getCurrentInvertedState()} />);
  }, [reply]);

  const nextScreen = () => {
    nextSubLevel(0);
    nextLvl(current + 1);
  };

  const chosenColor = (timeLoaded, colorName) => {
    schema[level].userAnswers.push(colorName);
    console.debug("colorName: ", colorName);
    schema[level].replies = schema[level].replies + 1;
    if (schema[level].replies < schema[level].nColors)
      setReply(schema[level].replies);

    if (schema[level].replies === schema[level].nColors) {
      checkNextLevelOrRetry();
    }
  };

  const checkNextLevelOrRetry = () => {
    const isCorrect = correctAnswer(
      schema[level].userAnswers,
      schema[level].answers
    );

    const distanceForAnswers = getDistanceForAnswers(schema[level].isInverted, schema[level].userAnswers, schema[level].answers);
    _showUserClick("Training Color", schema[level].wasInverted[schema[level].replies], schema[level].userAnswers, schema[level].answers, distanceForAnswers);

    if (isCorrect) {
      schema[level].correctTrials += 1;
    } else {
      schema[level].wrongTrials += 1;
    }

    const totalTrials = schema[level].correctTrials + schema[level].wrongTrials;
    const isBlueSet = currentTheme === 'set2';
    if (isBlueSet) {
      schema[level].wasInverted = getCurrentInvertedState();
    }

    _showConsoleInformation("Training Color", schema[level], isCorrect);
    isInvertedNow(currentTheme === 'set2');

    requestAgainOrNewLevel("Training Color", schema[level], level, setLevel, colorLoop);
  };

  const AnswerLoop = () => {
    schema[level].userAnswers = [];
    schema[level].replies = 0;
    schema[level].wasInverted = null;
    setReply(0);

    setCurrentSlide(<AnswerColorSlide
      func={chosenColor} isInverted={getCurrentInvertedState()} />);
  };

  const retensionLoop = () => {
    setCurrentSlide(<RetensionSlide />);
    setTimeout(() => {
      AnswerLoop();
    }, 1000);
  };

  const setAnswers = (colorName) => {
    schema[level].answers.push(colorName);
  };

  const showAnswersAndToRetension = (timeout) => {
    setTimeout(() => {
      retensionLoop();
    }, timeout);
  };

  const colorLoop = () => {
    const { timeout, nColors } = schema[level];
    schema[level].answers = [];
    console.log("colorLoop", level, timeout);
    if (schema[level].stop) {
      // found the stop command
      nextScreen();
      return;
    }

    setCurrentSlide(
      <ColorChoiceSlide
        nColors={nColors}
        setAnswers={setAnswers}
        timeout={timeout}
        setRetension={showAnswersAndToRetension}
      />
    );
  };

  useEffect(() => {
    console.log("useEffect of Level", level);
    nextSubLevel(level);
    schema[level].correctTrials = 0;
    schema[level].wrongTrials = 0;

    colorLoop();
  }, [level]);

  return (
    <div>
      {currentSlide}
    </div>
  );
};

export default TrainingColorRunning;
