import React from 'react';
import { Route, HashRouter, Switch } from 'react-router-dom';
import ResultTable from './Pages/Results/Main';
import CreateRoom from './Pages/ServerVideo/ServerVideo';
import LayoutBase from './Components/Layout/LayoutBase';
import Initial from './Pages/Application/Initial';
import InitialRelacional from './Pages/Application/Relacional/InitialRelacional';
import InitialValidation from './Pages/Validation/InitialValidation';
import InitialBinding from './Pages/Application/Binding/InitialBinding.js';
import InitialSoftBinding from './Pages/Application/SoftBinding/InitialSoftBinding';


export default () => (
    <HashRouter>
        <Switch>
            <LayoutBase>
                <Route exact path="/results" component={ResultTable} />
                <Route exact path="/" component={Initial} />
                <Route exact path="/conjuncao" component={Initial} />
                <Route exact path="/relacional" component={InitialRelacional} />
                <Route exact path="/binding" component={InitialBinding} />
                <Route exact path="/softbinding" component={InitialSoftBinding} />
                <Route exact path="/validation" component={InitialValidation} />
                <Route exact path="/video" component={CreateRoom} />
            </LayoutBase>
        </Switch>
    </HashRouter>
);
