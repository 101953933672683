
import React from "react";

const renderAttempt = (classes, currentColor, level, attempt) => {
    if (currentColor["level-" + level + "-" + attempt] === undefined)
        return <></>;
    const colorclass = classes['t' + attempt];
    return <div className={classes.trials + " " + colorclass}>
        <b>Trial {level}-{attempt}: </b>
        {currentColor["level-" + level + "-" + attempt].isCorrect ? "Correct" : "Wrong"}
        <br />
        <b>User Positions:</b> {currentColor["level-" + level + "-" + attempt].userAnswers.join(', ')}
        <br />
        <b>Right Positions:</b> {currentColor["level-" + level + "-" + attempt].answers.join(', ')}
        <br />
        {/* <b>Intervals:</b> {currentColor["level-" + level + "-" + attempt].userTimes.join(', ')} */}
    </div>

}

const renderRelacional = (classes, currentColor, level) => {
    return (
        <>
            {renderAttempt(classes, currentColor, level, 1)}
            {renderAttempt(classes, currentColor, level, 2)}
            {renderAttempt(classes, currentColor, level, 3)}
            {renderAttempt(classes, currentColor, level, 4)}
            {renderAttempt(classes, currentColor, level, 5)}
            {renderAttempt(classes, currentColor, level, 6)}

        </>
    );
};

export default renderRelacional;
