import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import styles from '../../Relacional/_styles';
import { clearLS, getFromLS } from '../../../../utils';
import { FirebaseService } from '../../../../Services/FirebaseService';
import { SmallLocations } from '../Location/LocationCmpts';

const useStyles = makeStyles(styles);

const RelacionalInstructionSlide = ({ current, next }) => {
  const classes = useStyles();
  const nextScreen = () => {
    next(current + 1);
  };

  return (
    <div className={classes.grid}>
      <Paper
        onClick={nextScreen}
        className={`${classes.paper2}  ${classes.pointer}`}>
        <Grid container direction='row'>
          <Grid item xs={4} md={4}>
            <div className={classes.instBox}>
              <div
                className={`${classes.littleBox}  ${classes.loc_p2}  ${classes.red}`}
              />
              <div
                className={`${classes.littleBox}  ${classes.loc_p4}  ${classes.green}`}
              />
            </div>
          </Grid>
          <Grid item xs={8} md={8} className={classes.instText}>
            <br />
            <br />
            Você deverá memorizar a localização de cada cor.
          </Grid>
          <Grid item xs={4} md={4}>
            <div className={classes.instBox} />
          </Grid>
          <Grid item xs={8} md={8} className={classes.instText}>
            <br />
            <br /> Aguardar um intervalo.
          </Grid>
          <Grid item xs={4} md={4}>
            <div className={classes.instBox}>
              <div
                className={`${classes.littleBox} ${classes.loc_color_p1}  ${classes.red}`}
              />
              <div
                className={`${classes.littleBox} ${classes.loc_color_p2}  ${classes.green}`}
              />
              <div
                className={`${classes.littleBox} ${classes.loc_color_p3}  ${classes.yellow}`}
              />
              <div
                className={`${classes.littleBox} ${classes.loc_color_p4}  ${classes.blue}`}
              />
              <div
                className={`${classes.littleBox} ${classes.loc_color_p5}  ${classes.lightblue}`}
              />
              <div
                className={`${classes.littleBox} ${classes.loc_color_p6}  ${classes.pink}`}
              />
              <div className={classes.subBox3}>
                <div className={classes.loc_p1}>
                  <SmallLocations number={1} />
                </div>
                <div className={classes.loc_p2}>
                  <SmallLocations number={2} />
                </div>
                <div className={classes.loc_p3}>
                  <SmallLocations number={3} />
                </div>
                <div className={classes.loc_p4}>
                  <SmallLocations number={4} />
                </div>
                <div className={classes.loc_p5}>
                  <SmallLocations number={5} />
                </div>
                <div className={classes.loc_p6}>
                  <SmallLocations number={6} />
                </div>
              </div>
            </div>
          </Grid>
          <Grid item xs={8} md={8} className={classes.instText}>
            <br />
            <br /> Escolher a cor e combiná-la com a respectiva localização.
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
};

const EndedRelacionalSlide = ({ current, next, setVisibility }) => {
  const classes = useStyles();
  const nextScreen = () => {
    next(current + 1);
  };

  console.log("--------------------------------------");
  console.log('Salvar tudo na base de dados Firebase');

  // 1. buscar no local storage tudo o que foi salvo
  const resultsUser = {};
  resultsUser.userInfo = getFromLS('user');
  resultsUser.questions = getFromLS('questions');
  resultsUser.colors = {};
  resultsUser.datetime = new Date().toISOString();
  resultsUser.location = {};
  resultsUser.relacional = {};

  for (let j = 1; j <= 4; j++) {
    for (let i = 1; i <= 3; i++) {
      resultsUser.colors[`level-${j}-${i}`] = getFromLS(
        `colors_level_${j}-${i}`,
      );
      resultsUser.location[`level-${j}-${i}`] = getFromLS(
        `location_level_${j}-${i}`,
      );
      resultsUser.relacional[`level-${j}-${i}`] = getFromLS(
        `relacional_level_${j}-${i}`,
      );
    }
  }

  clearLS();

  console.log('resultsUser', resultsUser);
  FirebaseService.pushData('participants', resultsUser);

  FirebaseService.getDataList('participants', (list) => {
    console.log('list', list);
  });
  /*
    useEffect(() => {
      console.log('setVisibility', setVisibility);
      if (setVisibility) {
        setVisibility(true);
      }
    }, []);*/

  return (
    <div className={classes.grid}>
      <Paper
        onClick={nextScreen}
        className={`${classes.paper}  ${classes.pointer}`}>
        <div className={classes.textCenter}>
          Você concluiu a tarefa das combinações.
          <div className={classes.clickToContinue}>
            Obrigado por participar!
          </div>
        </div>
      </Paper>
    </div>
  );
};



/***
 * 
 * Código para o salvar a pagina do Binding 
 */
const EndedNewRelacionalSlide = ({ current, next, setVisibility }) => {
  const classes = useStyles();
  const nextScreen = () => {
    next(current + 1);
  };

  console.log("__________________________________________________________");
  console.log('Salvar todas as informações na base de dados Firebase');

  // 1. buscar no local storage tudo o que foi salvo
  const resultsUser = {};
  resultsUser.userInfo = getFromLS('user');
  resultsUser.questions = getFromLS('questions');
  resultsUser.datetime = new Date().toISOString();


  /// Elementos a serem salvos

  resultsUser.binding = {};
  resultsUser.relational = {};
  resultsUser.blue_binding = {};
  resultsUser.blue_relational = {};

  for (let j = 1; j <= 4; j++) { //level
    for (let i = 1; i <= 6; i++) { //attempt
      resultsUser.binding[`level-${j}-${i}`] = getFromLS(
        `binding_level_${j}-${i}`,
      );
      resultsUser.relational[`level-${j}-${i}`] = getFromLS(
        `relational_level_${j}-${i}`,
      );
      resultsUser.blue_binding[`level-${j}-${i}`] = getFromLS(
        `blue_binding_level_${j}-${i}`,
      );
      resultsUser.blue_relational[`level-${j}-${i}`] = getFromLS(
        `blue_relational_level_${j}-${i}`,
      );
    }
  }

  console.log('Informação a ser salva na base de dados: ', JSON.parse(JSON.stringify(resultsUser)));

  FirebaseService.pushData('participants', resultsUser);
  FirebaseService.getDataList('participants', (list) => {
    console.log('list', list);
  });


  clearLS();
  /*
    useEffect(() => {
      console.log('setVisibility', setVisibility);
      if (setVisibility) {
        setVisibility(true);
      }
    }, []);*/

  return (
    <div className={classes.grid}>
      <Paper
        onClick={nextScreen}
        className={`${classes.paper}  ${classes.pointer}`}>
        <div className={classes.textCenter}>
          Você concluiu a tarefa das combinações.
          <div className={classes.clickToContinue}>
            Obrigado por participar!
          </div>
        </div>
      </Paper>
    </div>
  );
};




const EndedRelacionalWithNextSlide = ({ current, next }) => {
  const classes = useStyles();
  const nextScreen = () => {
    next(current + 1);
  };

  const instructionText = 'Agora iniciaremos a tarefa das cores e formas. ';

  return (
    <div className={classes.grid}>
      <Paper
        onClick={nextScreen}
        className={`${classes.paper}  ${classes.pointer}`}>
        <div className={classes.textCenter}>
          Você concluiu a tarefa das cores e localizações.
          <br /> {instructionText}
          <div className={classes.clickToContinue}>
            Clique na tela para continuar.
          </div>
        </div>
      </Paper>
    </div>
  );
};







/***
 * 
 * Código para o salvar a pagina do Soft Binding 
 */
const EndedNewSoftRelacionalSlide = ({ current, next, setVisibility }) => {
  const classes = useStyles();
  const nextScreen = () => {
    next(current + 1);
  };

  console.log("__________________________________________________________");
  console.log('Salvar todas as informações do Soft Binding na base de dados Firebase');

  // 1. buscar no local storage tudo o que foi salvo
  const resultsUser = {};
  resultsUser.userInfo = getFromLS('user');
  resultsUser.questions = getFromLS('questions');
  resultsUser.datetime = new Date().toISOString();
  resultsUser.soft_binding = true;

  /// Elementos a serem salvos

  resultsUser.binding = {};
  resultsUser.relational = {};

  for (let j = 1; j <= 4; j++) { //level
    for (let i = 1; i <= 6; i++) { //attempt
      resultsUser.binding[`level-${j}-${i}`] = getFromLS(
        `binding_level_${j}-${i}`,
      );
      resultsUser.relational[`level-${j}-${i}`] = getFromLS(
        `relational_level_${j}-${i}`,
      );
    }
  }

  console.log('Informação a ser salva na base de dados: ', JSON.parse(JSON.stringify(resultsUser)));

  FirebaseService.pushData('participants', resultsUser);
  FirebaseService.getDataList('participants', (list) => {
    console.log('list', list);
  });


  clearLS();
  /*
    useEffect(() => {
      console.log('setVisibility', setVisibility);
      if (setVisibility) {
        setVisibility(true);
      }
    }, []);*/

  return (
    <div className={classes.grid}>
      <Paper
        onClick={nextScreen}
        className={`${classes.paper}  ${classes.pointer}`}>
        <div className={classes.textCenter}>
          Você concluiu a tarefa das combinações.
          <div className={classes.clickToContinue}>
            Obrigado por participar!
          </div>
        </div>
      </Paper>
    </div>
  );
};


export { RelacionalInstructionSlide, EndedRelacionalSlide, EndedRelacionalWithNextSlide, EndedNewRelacionalSlide, EndedNewSoftRelacionalSlide };
