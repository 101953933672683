/* eslint-disable react/jsx-indent */
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { MenuItem } from "@material-ui/core";
import { Formik, Field, Form } from "formik";
import { TextField } from "formik-material-ui";
import Button from "@material-ui/core/Button";

import Paper from "@material-ui/core/Paper";
import { saveToLS, getFromLS } from "../../utils";

import styles from "./_styles";

//
// More info: https://jaredpalmer.com/formik/docs/guides/validation
//

const useStyles = makeStyles(styles);

const ranges = [
  {
    label: "Conjunto 1",
    value: "set1",
  },
  {
    label: "Conjunto 2",
    value: "set2",
  },
];

const rangesSN = [
  {
    label: "Sim",
    value: "sim",
  },
  {
    label: "Não",
    value: "nao",
  },
];


const UserForm = ({ current, next }) => {
  const classes = useStyles();

  const handleSubmit = (values, actions) => {
    actions.setSubmitting(false);

    saveToLS("user", values);

    console.log("Checking local storage ", getFromLS("user"));
    next(current + 1);
    return "";
    // should save on local storage
  };

  return (
    <div className={classes.grid}>
      <Paper className={classes.paper}>
        <div className={classes.formTextAlign}>
          <Formik
            initialValues={{
              name: "",
              rg: "",
              gender: "female",
              handedness: "lefthanded",
              age: "",
              code: "",
              education: "",
              job: "",
              city: "",
              state: "",
              country: "",
            }}
            onSubmit={handleSubmit}
            render={({ handleSubmit }) => (
              <Form>
                <Field
                  name="name"
                  type="text"
                  label="Nome"
                  component={TextField}
                />
                <br />
                <Field name="rg" type="text" label="RG" component={TextField} />
                <br />
                <Field
                  name="age"
                  type="number"
                  label="Idade"
                  component={TextField}
                />
                <br />
                <Field name="code" type="text" label="Código do Participante" component={TextField} />
                <br />
                <Field name="education" type="text" label="Escolaridade" component={TextField} />
                <br />
                <Field name="job" type="text" label="Profissão ou Ocupação" component={TextField} />
                <br />
                <Field name="city" type="text" label="Cidade" component={TextField} />
                <br />
                <Field name="state" type="text" label="Estado" component={TextField} />
                <br />
                <Field name="country" type="text" label="Pais" component={TextField} />
                <br />
                <Field
                  name="handedness"
                  label="Lateralidade"
                  component={TextField}
                  select
                >
                  <MenuItem key="lefthanded" value="lefthanded">
                    Esquerda
                  </MenuItem>
                  <MenuItem key="righthanded" value="righthanded">
                    Direita
                  </MenuItem>
                </Field>
                <br />
                <Field
                  label="Sexo"
                  name="gender"
                  component={TextField}
                  select
                >
                  <MenuItem key="male" value="male">
                    Masculino
                  </MenuItem>
                  <MenuItem key="female" value="female">
                    Feminino
                  </MenuItem>
                  <MenuItem key="other" value="other">
                    Outro
                  </MenuItem>
                </Field>
                <br />
                <br />
                <Button className={classes.btnbonito} color="primary" onClick={handleSubmit}>
                  Prosseguir
                </Button>
              </Form>
            )}
          />
        </div>
      </Paper>
    </div>
  );
};



const UserQuestions = ({ current, next, selectTheme }) => {
  const classes = useStyles();

  const handleSubmit = (values, actions) => {
    actions.setSubmitting(false);
    saveToLS("questions", values);
    selectTheme(values.set)
    next(current + 1);
  };

  return (
    <div className={classes.grid}>
      <Paper className={classes.paper}>
        <div className={classes.formTextAlign}>
          <Formik
            initialValues={{
              set: "set1",
              question1: "nao",
              textquestion1: "",
              question2: "nao",
              textquestion2: "",
            }}
            onSubmit={handleSubmit}
            render={({ handleSubmit }) => (
              <Form>
                <br />
                <br />
                <Field label="Conjunto" name="set" component={TextField} select>
                  {ranges.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Field>
                <br />
                <br />
                Faz uso de alguma medicação?
                <br />
                <Field name="question1" component={TextField} select>
                  {rangesSN.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Field>
                <br />
                <Field
                  name="textquestion1"
                  type="text"
                  className={classes.textquestion1}
                  label="Caso sim, qual medicação?"
                  component={TextField}
                />
                <br />
                <br />

                Possui algum diagnóstico psiquiátrico ou neurológico?
                <br />
                <Field name="question2" component={TextField} select>
                  {rangesSN.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Field>
                <br />
                <Field
                  name="textquestion2"
                  type="text"
                  label="Caso sim, qual?"
                  component={TextField}
                />
                <br />
                <br />
                <Button className={classes.btnbonito} color="primary" onClick={handleSubmit}>
                  Iniciar
                </Button>
              </Form>
            )}
          />
        </div>
      </Paper>
    </div>
  );
};


export { UserForm, UserQuestions };
