import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import styles from '../../_styles';

const useStyles = makeStyles(styles);

const ParticipantSlide = ({ bind, current, next }) => {
  const classes = useStyles();
  const nextScreen = () => {
    next(current + 1);
  };
  /*
  const bindText = "Você fará tarefas em que deverá memorizar cores e localizações. Antes de cada etapa da tarefa, eu lhe darei as instruções necessárias. ";
  const conjText = 'Você fará tarefas em que deverá memorizar cores e formas.  Antes de cada etapa da tarefa, eu lhe darei as instruções necessárias.';
  let instructionText = "";

  if (bind == "relacional")
    instructionText = bindText;
  else
    instructionText = conjText;
*/

  return (
    <div className={classes.grid}>
      <Paper
        onClick={nextScreen}
        className={`${classes.paper}  ${classes.pointer}`}>
        <div className={classes.textCenter}>
          Você deverá memorizar cores e formas.
          <br /> <br />
          Antes de cada etapa, você receberá as instruções.
          <div className={classes.clickToContinue}>
            Clique na tela para continuar.
          </div>
        </div>
      </Paper>
    </div>
  );
};

const ColorInstructionSlide = ({ current, next, setVisibility }) => {
  const classes = useStyles();
  const nextScreen = () => {
    next(current + 1);
  };

  useEffect(() => {
    console.log('setVisibility', setVisibility);
    if (setVisibility) {
      setVisibility(false);
    }
  }, []);

  return (
    <div className={classes.grid}>
      <Paper
        onClick={nextScreen}
        className={`${classes.paper2}  ${classes.pointer}`}>
        <Grid container direction='row'>
          <Grid item xs={4} md={4}>
            <div className={classes.instBox}>
              <div
                className={`${classes.littleBox}  ${classes.lbrandom1}  ${classes.red}`}
              />
              <div
                className={`${classes.littleBox}  ${classes.lbrandom2}  ${classes.green}`}
              />
            </div>
          </Grid>
          <Grid item xs={8} md={8} className={classes.instText}>
            <br />
            <br />
            Você deverá memorizar cores.
          </Grid>
          <Grid item xs={4} md={4}>
            <div className={classes.instBox} />
          </Grid>
          <Grid item xs={8} md={8} className={classes.instText}>
            <br />
            <br /> Aguardar um intervalo.
          </Grid>
          <Grid item xs={4} md={4}>
            <div className={classes.instBox}>
              <div
                className={`${classes.littleBox}  ${classes.lb1}  ${classes.red}`}
              />
              <div
                className={`${classes.littleBox}  ${classes.lb2}  ${classes.green}`}
              />
              <div
                className={`${classes.littleBox}  ${classes.lb3}  ${classes.yellow}`}
              />
              <div
                className={`${classes.littleBox}  ${classes.lb4}  ${classes.blue}`}
              />
              <div
                className={`${classes.littleBox}  ${classes.lb5}  ${classes.lightblue}`}
              />
              <div
                className={`${classes.littleBox}  ${classes.lb6}  ${classes.pink}`}
              />
            </div>
          </Grid>
          <Grid item xs={8} md={8} className={classes.instText}>
            <br />
            <br /> Escolher as cores que viu anteriormente.
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
};

const EndedColorSlide = ({ bind, current, next }) => {
  const classes = useStyles();
  const nextScreen = () => {
    next(current + 1);
  };

  const bindText = 'Agora iniciaremos a tarefa das localizações. ';
  const conjText = 'Agora iniciaremos a tarefa das formas.';
  let instructionText = '';

  if (bind == 'relacional') {
    instructionText = bindText;
  } else {
    instructionText = conjText;
  }

  return (
    <div className={classes.grid}>
      <Paper
        onClick={nextScreen}
        className={`${classes.paper}  ${classes.pointer}`}>
        <div className={classes.textCenter}>
          Você concluiu a tarefa das cores.
          <br /> {instructionText}
          <div className={classes.clickToContinue}>
            Clique na tela para continuar.
          </div>
        </div>
      </Paper>
    </div>
  );
};

export { ColorInstructionSlide, ParticipantSlide, EndedColorSlide };
