import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import MaterialTable from "material-table";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import IconButton from "@material-ui/core/IconButton";
import moment from "moment";
import Button from "@material-ui/core/Button";
import tableIcons from "./icons";
import renderBinding from "./renderBinding";
import renderRelacional from "./renderRelacional";


import { ExcelTable as ConjuncaoExcelParra } from "./conjuncaoExcelParra";

const styles = (theme) => ({
    root: {
        flexGrow: 1,
        marginTop: "8px",
        marginLeft: "30px",
        display: "block",
        padding: "8px",
        backgroundColor: "white",
    },
    bigwidth: {
        display: "block",
        width: "2900px",
    },
    setinfo: {
        textTransform: "uppercase",
    },
    prtcpnt: {
        fontStyle: "normal",
        fontWeight: 500,
        fontSize: "12px",
        lineHeight: "26px",
        letterSpacing: "0.01em",
        textTransform: "capitalize",
    },
    trials: {
        fontStyle: "normal",
        fontSize: "11px",
        lineHeight: "18px",
        letterSpacing: "0.01em",
        textTransform: "capitalize",
    },
    t1: {
        color: "#2A8BF2",
    },
    t2: {
        color: "#2043BE",
    },
    t3: {
        color: "#FF3366",
    },
});

const useStyles = makeStyles(styles);

const createSet = (phase, currentlevel) => {
    const obj = {};
    if (phase === undefined) return obj;
    if (phase[`level-${currentlevel}-1`]) {
        obj[`level-${currentlevel}-1`] = phase[`level-${currentlevel}-1`];
    }
    if (phase[`level-${currentlevel}-2`]) {
        obj[`level-${currentlevel}-2`] = phase[`level-${currentlevel}-2`];
    }
    if (phase[`level-${currentlevel}-3`]) {
        obj[`level-${currentlevel}-3`] = phase[`level-${currentlevel}-3`];
    }

    if (phase[`level-${currentlevel}-4`]) {
        obj[`level-${currentlevel}-4`] = phase[`level-${currentlevel}-4`];
    }

    if (phase[`level-${currentlevel}-5`]) {
        obj[`level-${currentlevel}-5`] = phase[`level-${currentlevel}-5`];
    }

    if (phase[`level-${currentlevel}-6`]) {
        obj[`level-${currentlevel}-6`] = phase[`level-${currentlevel}-6`];
    }

    return obj;
};

const renderParticipantInfo = (classes, rowData) => {
    let dt = "";
    if (rowData.userInfo.datetime) {
        dt = moment(rowData.userInfo.datetime).format("HH:mm:ss DD/MM/YYYY");
    }

    return (
        <div className={classes.prtcpnt}>
            {rowData.userInfo.name}
            <br />
            <b>Data:</b> {dt}
            <br />
            <b>RG:</b> {rowData.userInfo.rg}
            <br />
            <b>Código:</b> {rowData.userInfo.code}
            <br />
            {rowData.userInfo.gender}
            <br />
            {rowData.userInfo.handedness}
            <br />
            <b>Idade:</b>
            {rowData.userInfo.age}
            <br />
            <b className={classes.setinfo}>{rowData.userInfo.set}</b>
            <br />
            <br />
            <b>Questão 1:</b>
            {rowData.userInfo.question1} - {rowData.userInfo.textquestion1}
            <br />
            <b>Questão 2:</b>
            {rowData.userInfo.question2} - {rowData.userInfo.textquestion2}
        </div>
    );
};

const DataTable = ({ data, openDeleteBox }) => {
    const classes = useStyles();

    const [showTable, setShowTable] = useState(false);
    const [rawData, setRawData] = useState([]);

    useEffect(() => {
        const data2 = [];
        data.forEach((element) => {
            const el2 = {};
            el2.key = element.key;
            el2.userInfo = {
                datetime: element.datetime,
                ...element.userInfo,
                ...element.questions,
            };
            el2.binding1 = createSet(element.binding, 1);
            el2.binding2 = createSet(element.binding, 2);
            el2.binding3 = createSet(element.binding, 3);

            el2.relational1 = createSet(element.relational, 1);
            el2.relational2 = createSet(element.relational, 2);
            el2.relational3 = createSet(element.relational, 3);

            el2.blue_binding1 = createSet(element.blue_binding, 1);
            el2.blue_binding2 = createSet(element.blue_binding, 2);
            el2.blue_binding3 = createSet(element.blue_binding, 3);

            el2.blue_relational1 = createSet(element.blue_relational, 1);
            el2.blue_relational2 = createSet(element.blue_relational, 2);
            el2.blue_relational3 = createSet(element.blue_relational, 3);
            data2.push(el2);
        });
        setRawData(data2);
    }, [data]);

    const columns = [
        {
            title: "Opções ",
            field: "key",
            render: (rowData) => (
                <IconButton
                    onClick={() => openDeleteBox(rowData, "binding")}
                    color="primary"
                    aria-label="upload picture"
                    component="span">
                    <DeleteForeverIcon />
                </IconButton>
            ),
        },
        {
            title: "Participante",
            field: "userInfo",
            render: (rowData) => renderParticipantInfo(classes, rowData),
        },
        {
            title: "Fase Binding - 2 Bindings",
            field: "binding1",
            render: (rowData) => renderBinding(classes, rowData.binding1, 1),
        },
        {
            title: "Fase Binding - 3 Bindings",
            field: "binding2",
            render: (rowData) => renderBinding(classes, rowData.binding2, 2),
        },
        {
            title: "Fase Binding - 4 Bindings",
            field: "binding3",
            render: (rowData) => renderBinding(classes, rowData.binding3, 3),
        },

        {
            title: "Fase Relational - 2 Relationals",
            field: "relational1",
            render: (rowData) => renderRelacional(classes, rowData.relational1, 1),
        },
        {
            title: "Fase Relational - 3 Relationals",
            field: "relational2",
            render: (rowData) => renderRelacional(classes, rowData.relational2, 2),
        },
        {
            title: "Fase Relational - 4 Relationals",
            field: "relational3",
            render: (rowData) => renderRelacional(classes, rowData.relational3, 3),
        },


        {
            title: "Fase Blue Binding - 2 Bindings",
            field: "binding1",
            render: (rowData) => renderBinding(classes, rowData.blue_binding1, 1),
        },
        {
            title: "Fase Blue Binding - 3 Bindings",
            field: "blue_binding2",
            render: (rowData) => renderBinding(classes, rowData.blue_binding2, 2),
        },
        {
            title: "Fase Blue Binding - 4 Bindings",
            field: "blue_binding3",
            render: (rowData) => renderBinding(classes, rowData.blue_binding3, 3),
        },

        {
            title: "Fase Blue Relational - 2 Relationals",
            field: "blue_relational1",
            render: (rowData) => renderRelacional(classes, rowData.blue_relational1, 1),
        },
        {
            title: "Fase Blue Relational - 3 Relationals",
            field: "blue_relational2",
            render: (rowData) => renderRelacional(classes, rowData.blue_relational2, 2),
        },
        {
            title: "Fase Blue Relational - 4 Relationals",
            field: "blue_relational3",
            render: (rowData) => renderRelacional(classes, rowData.blue_relational3, 3),
        },

    ];

    return (
        <div className={classes.root}>
            <div className={classes.bigwidth} style={{ marginTop: "5px" }}>
                {!showTable && (
                    <Button
                        style={{ position: "relative" }}
                        onClick={() => setShowTable(true)}
                        color="primary">
                        Mostrar Tabela Atualizada
                    </Button>
                )}
                {showTable && (
                    <Button
                        style={{ position: "relative" }}
                        onClick={() => setShowTable(false)}
                        color="primary">
                        Esconder Tabela Atualizada
                    </Button>
                )}
                &nbsp; &nbsp; &nbsp; &nbsp;
                <ConjuncaoExcelParra title="binding Parra" data={rawData} />
                {showTable && (
                    <MaterialTable
                        components={{
                            Container: (props) => props.children,
                        }}
                        icons={tableIcons}
                        title="Resultados do Binding versão Parra"
                        columns={columns}
                        data={rawData}
                    />
                )}
            </div>
        </div>
    );
};

export { DataTable };
