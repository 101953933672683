const styles = theme => ({
    root: {
        position: 'fixed',
        height: '300px',
        width: '600px',
        left: '0px',
        top: '200px',
        "z-index": '20',
        display: "block",
        background: 'white',
        borderRadius: '0px 20px 20px 0px',
        boxShadow: '0 6px 16px 3px rgba(99, 91, 93, 0.3)',
    },
    isvisible:
    {
        visibility: 'visible'
    },
    notvisible:
    {
        visibility: 'hidden'
    },
    showhidebtn: {
        border: "none",
        boxShadow: "none",
        left: "0px",
        top: "150px",
        color: "#2d7c96"
    },
    boxes: {
        padding: '4px',
        display: "block",
        width: "100%",
        height: '100%',
    },
    videostyle: {
        width: "45%",
        height: '90%',
        background: 'black',
        margin: '2%',
    },
});

export default styles;
