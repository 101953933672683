const styles = (theme) => ({
  isPointer: {
    cursor: "pointer",
  },
  notPointer: {
    cursor: "not-allowed",
  },
  grid: {
    height: 720,
    width: 1000,
    padding: 0,
    margin: "auto",
  },
  smallLocation: {
    height: 20,
    width: 20,
  },
  smallLocations: {
    height: 20,
    width: 20,
    position: "absolute",
    display: "inline-block",
  },
  shapes: {
    height: 100,
    width: 100,
    display: "inline-block",
  },
  splash: {
    height: 100,
    width: 100,
  },
  pointer: {
    cursor: "pointer",
  },
  textCenter: {
    top: "150px",
    left: "200px",
    position: "absolute",
    fontSize: theme.typography.fontSize,
    textTransform: "uppercase",
    width: "600px",
    fontWeight: theme.typography.fontWeight,
    lineHeight: "31px",
    textAlign: "center",
    fontFamily: theme.typography.fontFamily,
  },
  shape: {
    height: 100,
    width: 100,
    padding: "17px 30px",
    fontSize: "49px",
    fontWeight: "900",
    display: "inline-block",
    textAlign: "center",
    verticalAlign: "middle",
  },
  loc_p1: {
    left: "100px",
    bottom: "110px",
    position: "absolute",
  },
  loc_p2: {
    left: "79px",
    bottom: "98px",
    position: "absolute",
  },
  loc_p3: {
    left: "79px",
    bottom: "74px",
    position: "absolute",
  },
  loc_p4: {
    left: "100px",
    bottom: "62px",
    position: "absolute",
  },
  loc_p5: {
    left: "120px",
    bottom: "74px",
    position: "absolute",
  },
  loc_p6: {
    left: "120px",
    bottom: "98px",
    position: "absolute",
  },
  noMouse: {
    cursor: "none",
  },
  location1of6: {
    left: "calc(500px - 50px)",
    bottom: "calc(600px - 50px)",
    position: "absolute",
  },
  location2of6: {
    left: "calc(292px - 50px)",
    bottom: "calc(480px - 50px)",
    position: "absolute",
  },
  location3of6: {
    left: "calc(292px - 50px)",
    bottom: "calc(240px - 50px)",
    position: "absolute",
  },
  location4of6: {
    left: "calc(500px - 50px)",
    bottom: "calc(120px - 50px)",
    position: "absolute",
  },
  location5of6: {
    left: "calc(707px - 50px)",
    bottom: "calc(240px - 50px)",
    position: "absolute",
  },
  location6of6: {
    left: "calc(707px - 50px)",
    bottom: "calc(480px - 50px)",
    position: "absolute",
  },
  divShapes: {
    display: "inline-block",
    width: "100%",
  },
  divColors: {
    position: "absolute",
    top: "0px",
    left: "0px",
    width: "100%",
    height: "100%",
  },
  paper4: {
    height: "100%",
    width: "100%",
    paddingTop: "0px",
    paddingLeft: "0px",
    position: "relative",
    boxShadow: "none",
    background: "#c3c3c3",
  },
  paper3: {
    height: "100%",
    width: "100%",
    paddingTop: "220px",
    paddingLeft: "0px",
    position: "relative",
    boxShadow: "none",
    background: "#c3c3c3",
  },
  paper2: {
    height: "100%",
    width: "100%",
    paddingTop: "100px",
    boxShadow: "none",
    paddingLeft: "40px",
    position: "relative",
    background: "#c3c3c3",
  },
  paper: {
    height: "100%",
    width: "100%",
    paddingTop: "300px",
    boxShadow: "none",
    paddingLeft: "40px",
    position: "relative",
    background: "#c3c3c3",
  },
  sp_red: {
    fill: theme.palette.red,
    "& > g": {
      fill: theme.palette.red,
    },
  },
  sp_green: {
    fill: theme.palette.green,
    "& > g": {
      fill: theme.palette.green,
    },
  },
  sp_yellow: {
    fill: theme.palette.yellow,
    "& > g": {
      fill: theme.palette.yellow,
    },
  },
  sp_blue: {
    fill: theme.palette.blue,
    "& > g": {
      fill: theme.palette.blue,
    },
  },
  sp_lightblue: {
    fill: theme.palette.lightblue,
    "& > g": {
      fill: theme.palette.lightblue,
    },
  },
  sp_pink: {
    fill: theme.palette.pink,
    "& > g": {
      fill: theme.palette.pink,
    },
  },
  subBox3: {
    position: "absolute",
    bottom: "0px",
  },
  subBox2: {
    top: "80px !important",
  },
  instBox2: {
    paddingTop: "60px",
    paddingLeft: "40px",
  },
  instBox: {
    height: "150px",
    width: "220px",
    position: "relative",
    border: "4px #544d4d solid",
    borderRadius: "5px",
    margin: "20px",
  },
  instText: {
    fontSize: theme.typography.fontSize,
    paddingBottom: "20px",
    textTransform: "uppercase",
    width: "600px",
    fontWeight: theme.typography.fontWeight,
    lineHeight: "31px",
  },
  littleBox: {
    width: "20px",
    height: "20px",
    border: "1px #544d4d solid",
    borderRadius: "5px",
    position: "absolute",
  },
  lbrandom1: {
    position: "absolute",
    top: "50px",
    left: "60px",
  },
  lbrandom2: {
    position: "absolute",
    top: "70px",
    left: "100px",
  },
  boxColor01: {
    position: "absolute",
    top: "100px",
    left: "0px",
  },
  boxColor02: {
    position: "absolute",
    top: "300px",
    left: "0px",
  },
  boxColor03: {
    position: "absolute",
    top: "500px",
    left: "0px",
  },
  boxColor04: {
    position: "absolute",
    top: "100px",
    right: "0px",
  },
  boxColor05: {
    position: "absolute",
    top: "300px",
    right: "0px",
  },
  boxColor06: {
    position: "absolute",
    top: "500px",
    right: "0px",
  },
  red: {
    background: theme.palette.red,
  },
  green: {
    background: theme.palette.green,
  },
  yellow: {
    background: theme.palette.yellow,
  },
  blue: {
    background: theme.palette.blue,
  },
  lightblue: {
    background: theme.palette.lightblue,
  },
  pink: {
    background: theme.palette.pink,
  },
  loc_color_p1: {
    left: "30px",
    bottom: "85px",
    position: "absolute",
  },
  loc_color_p2: {
    left: "30px",
    bottom: "55px",
    position: "absolute",
  },
  loc_color_p3: {
    left: "30px",
    bottom: "25px",
    position: "absolute",
  },
  loc_color_p4: {
    right: "30px",
    bottom: "85px",
    position: "absolute",
  },
  loc_color_p5: {
    right: "30px",
    bottom: "55px",
    position: "absolute",
  },
  loc_color_p6: {
    right: "30px",
    bottom: "25px",
    position: "absolute",
  },
});

export default styles;
