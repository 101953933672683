import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import styles from "../../_styles";
import Grid from "@material-ui/core/Grid";
import { SmallShapes } from "./ShapesCmpts";

const useStyles = makeStyles(styles);

const ShapeInstructionSlide = ({ current, next }) => {
  const classes = useStyles();
  const nextScreen = () => {
    next(current + 1);
  };

  return (
    <div className={classes.grid}>
      <Paper
        onClick={nextScreen}
        className={classes.paper2 + "  " + classes.pointer}
      >
        <Grid container direction="row">
          <Grid item xs={4} md={4}>
            <div className={classes.instBox}>
              <div className={classes.lbrandom1}>
                <SmallShapes number={2} />
              </div>
              <div className={classes.lbrandom2}>
                <SmallShapes number={4} />
              </div>
            </div>
          </Grid>
          <Grid item xs={8} md={8} className={classes.instText}>
            <br />
            <br />
            Você deverá memorizar formas.
          </Grid>
          <Grid item xs={4} md={4}>
            <div className={classes.instBox}></div>
          </Grid>
          <Grid item xs={8} md={8} className={classes.instText}>
            <br />
            <br /> Aguardar um intervalo.
          </Grid>
          <Grid item xs={4} md={4}>
            <div className={classes.instBox + " " + classes.instBox2}>
              <div className={classes.lb1}>
                <SmallShapes number={1} />
              </div>
              <div className={classes.lb2}>
                <SmallShapes number={2} />
              </div>
              <div className={classes.lb3}>
                <SmallShapes number={3} />
              </div>
              <div className={classes.lb4}>
                <SmallShapes number={4} />
              </div>
              <div className={classes.lb5}>
                <SmallShapes number={5} />
              </div>
              <div className={classes.lb6}>
                <SmallShapes number={6} />
              </div>
            </div>
          </Grid>
          <Grid item xs={8} md={8} className={classes.instText}>
            <br />
            <br /> Escolher as formas que viu anteriormente.
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
};

const EndedShapeSlide = ({ current, next }) => {
  const classes = useStyles();
  const nextScreen = () => {
    next(current + 1);
  };

  return (
    <div className={classes.grid}>
      <Paper
        onClick={nextScreen}
        className={classes.paper + "  " + classes.pointer}
      >
        <div className={classes.textCenter}>
          Você concluiu a tarefa das formas.
          <br></br> Agora iniciaremos a tarefa das combinações.
          <div className={classes.clickToContinue}>
            Clique na tela para continuar.
          </div>
        </div>
      </Paper>
    </div>
  );
};

export { ShapeInstructionSlide, EndedShapeSlide };
