import { blue, green } from "@material-ui/core/colors";
import { createMuiTheme } from "@material-ui/core/styles";


const lightMuiTheme = createMuiTheme({
  type: "light",
  spacing: 8,
  grid: {
    width: 1000,
  },
  palette: {
    primary: {
      main: blue[500],
    },
    secondary: {
      main: green[500],
    },
    red: "#D7117D",
    green: "#659E80",
    yellow: "#D9DF42",
    blue: "#3F6BA6",
    lightblue: "#E63500", // red 2
    pink: "#815FB8", //purple
    gray: "#c3c3c3",
  },
  typography: {
    // Use the system font instead of the default Roboto font.
    fontFamily: "\"Lato\", \"Roboto\", \"Helvetica\", \"sans-serif\"",
    fontWeight: "300px",
    fontSize: '21px',
  },
  overrides: {
    MuiButton: {
      root: {
        border: 0,
        borderRadius: 3,
        position: "fixed",
        color: "white",
        height: 48,
        boxShadow: "0 3px 5px 2px rgba(105, 137, 255, 0.3)",
        padding: "0 24px",
        fontSize: "1rem",
      },
    },
    MuiCssBaseline: {
      "@global": {
        "@font-face": "Raleway",
      },
    },
  },
});

/* Blue shades Theme */
/* The shades of blue will appear from lightest to darkest. */
const darkMuiTheme = createMuiTheme({
  type: "dark",
  spacing: 8,
  grid: {
    width: 1000,
  },
  palette: {
    primary: {
      main: blue[500],
    },
    secondary: {
      main: green[500],
    },
    red: "#7eb6e0",
    green: "#539ED6",
    yellow: "#2986cc",
    blue: "#206ba3",
    lightblue: "#18507a",
    pink: "#103551",
    gray: "#c3c3c3",
  },
  typography: {
    // Use the system font instead of the default Roboto font.
    fontFamily: "\"Lato\", \"Roboto\", \"Helvetica\", \"sans-serif\"",
    fontWeight: "300px",
    fontSize: '21px',
  },
  overrides: {
    MuiButton: {
      root: {
        border: 0,
        borderRadius: 3,
        position: "fixed",
        color: "white",
        height: 48,
        boxShadow: "0 3px 5px 2px rgba(105, 137, 255, 0.3)",
        padding: "0 24px",
        fontSize: "1rem",
      },
    },
    MuiCssBaseline: {
      "@global": {
        "@font-face": "Raleway",
      },
    },
  },
});

export const normalPalette = lightMuiTheme;
export const bluePalette = darkMuiTheme;
