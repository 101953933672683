/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import { ReactComponent as Sone } from "../../../../Shapes/003.svg";
import { ReactComponent as Stwo } from "../../../../Shapes/004.svg";
import { ReactComponent as Sthree } from "../../../../Shapes/005.svg";
import { ReactComponent as Sfour } from "../../../../Shapes/006.svg";
import { ReactComponent as Sfive } from "../../../../Shapes/007.svg";
import { ReactComponent as Ssix } from "../../../../Shapes/008.svg";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import styles from "../../_styles";
import { ClickToContinueSlide } from "../Colors/ColorSlides";

const useStyles = makeStyles(styles);

const SmallShape = ({ number, color }) => {
  const classes = useStyles();
  let clr = "";
  if (color !== undefined) clr = color;
  if (number === 1)
    return <Sone className={classes.smallShape + " " + classes["sp_" + clr]} />;
  if (number === 2)
    return <Stwo className={classes.smallShape + " " + classes["sp_" + clr]} />;
  if (number === 3)
    return (
      <Sthree className={classes.smallShape + " " + classes["sp_" + clr]} />
    );
  if (number === 4)
    return (
      <Sfour className={classes.smallShape + " " + classes["sp_" + clr]} />
    );
  if (number === 5)
    return (
      <Sfive className={classes.smallShape + " " + classes["sp_" + clr]} />
    );
  if (number === 6)
    return <Ssix className={classes.smallShape + " " + classes["sp_" + clr]} />;
  return <Sone className={classes.smallShape} />;
};

const SmallShapes = ({ number, onClick, color }) => {
  const classes = useStyles();
  return (
    <div className={classes.smallShapes} onClick={onClick}>
      <SmallShape number={number} color={color} />
    </div>
  );
};

///

const Shape = ({ number }) => {
  const classes = useStyles();
  if (number === 1) return <Sone className={classes.shape} />;
  if (number === 2) return <Stwo className={classes.shape} />;
  if (number === 3) return <Sthree className={classes.shape} />;
  if (number === 4) return <Sfour className={classes.shape} />;
  if (number === 5) return <Sfive className={classes.shape} />;
  if (number === 6) return <Ssix className={classes.shape} />;
  return <Sone className={classes.shape} />;
};

const Shapes = ({ number, onClick, pointer }) => {
  const isPointer = !!pointer;
  const classes = useStyles();
  return (
    <div className={classes.shapes + " " + (isPointer ? classes.pointer : "")} onClick={onClick}>
      <Shape number={number} />
    </div>
  );
};

const EmptyShape = () => {
  const classes = useStyles();
  return (
    <div className={classes.shapes}>
    </div>
  );
};



const AnswerShapeSlide = ({ func }) => {
  const classes = useStyles();
  const timeLoaded = Date.now();
  const [answeredList, setAnsweredList] = useState([]);


  const myclick = (element) => {
    setAnsweredList([...answeredList, element]);
    func(timeLoaded, element);
  };

  const createShapes = (element) => {
    if (answeredList.includes(element)) {
      return <EmptyShape />;
    }
    return (<Shapes pointer onClick={() => myclick(element)} number={element} />);
  };

  return (
    <div className={classes.grid}>
      <Paper className={classes.paper}>
        {createShapes(1)}
        {createShapes(2)}
        {createShapes(3)}
        {createShapes(4)}
        {createShapes(5)}
        {createShapes(6)}
      </Paper>
    </div>
  );
};

const ShapeChoiceSlide = ({ nShapes, setAnswers, setRetension, timeout }) => {
  const classes = useStyles();
  const [waiting, setWaiting] = useState(true);

  let listEl = [];
  const myIndex = [1, 2, 3, 4, 5, 6];

  useEffect(() => {
    if (!waiting) {
      setTimeout(() => {
        setRetension();
      }, timeout);
    }
  }, [waiting]);

  if (waiting)
    return (
      <ClickToContinueSlide setWaiting={setWaiting}> </ClickToContinueSlide>
    );


  for (let i = 1; i <= nShapes; i++) {
    const index = Math.floor(Math.random() * myIndex.length);
    const myName = myIndex[index];
    myIndex.splice(index, 1);

    let el = (
      <div key={i} className={classes["location" + i + "of" + nShapes]}>
        <Shape number={myName} />
      </div>
    );

    listEl.push(el);
    setAnswers(myName);
  }

  return (
    <div className={classes.grid}>
      <Paper className={classes.paper}>{listEl}</Paper>
    </div>
  );
};

export { AnswerShapeSlide, ShapeChoiceSlide, Shape, EmptyShape, Shapes, SmallShapes };
