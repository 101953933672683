import React, { useEffect, useState } from "react";

import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import { FirebaseService } from "../../Services/FirebaseService";
import { DataTable as DatatableRelational } from "./DatatableRelational";
import { DataTable as DatatableValidation } from "./DatatableValidation";
import { DataTable as DatatableBinding } from "./DatatableBinding";
import { DataTable as DatatableParra } from "./DatatableParra";

const styles = (theme) => ({
  column: {
    display: "flex",
    flexDirection: "column",
    flexBasis: "100%",
    flex: 1,
  },
  loadingbox: {
    display: "flex",
    "flex-basis": "100%",
    marginTop: "130px",
    alignContent: "flex-start",
    alignItems: "center",
    flexWrap: "nowrap",
    justifyContent: "center",
  },
});
const useStyles = makeStyles(styles);

const ResultTable = () => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [idUser, setIdUser] = useState();
  const [type, setType] = useState();

  const handleClickOpen = (e, type) => {
    setIdUser(e.key);
    setType(type);
    setOpen(true);
  };

  const removeUser = () => {
    FirebaseService.removeUser(idUser, type);
    // window.location.reload();
    setOpen(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [dataBinding, setDataBinding] = useState();
  const [dataRelational, setDataRelational] = useState();
  const [dataValidation, setDataValidation] = useState();
  const [dataParra, setDataParra] = useState();
  const [dataSoftBinding, setDataSoftBinding] = useState();


  useEffect(() => {
    console.log("ResultTable");
    FirebaseService.getDataList("participants", (list) => {
      console.log("full list", list);
      const resultBinding = list.filter((n) => {
        // console.log("registro:", n);
        return n.binding !== undefined;
      });
      const resultRelacional = list.filter((n) => {
        return n.relacional !== undefined;
      });
      const resultValidation = list.filter((n) => {
        return n.validation !== undefined;
      });

      const resultParra = list.filter((n) => {
        return n.blue_relational !== undefined;
      });

      const resultSoftBinding = list.filter((n) => {
        return n.soft_binding === true && n.relational !== undefined;
      });

      console.log("__________________________________________________________");
      console.log("Resultados filtrados por teste: Binding", resultBinding);
      console.log("Resultados filtrados por teste: Relacional", resultRelacional);
      console.log("Resultados filtrados por teste: Validation", resultValidation);
      console.log("Resultados filtrados por teste: Parra", resultParra);
      console.log("Resultados filtrados por teste: SoftBinding", resultSoftBinding);


      setDataBinding(resultBinding);
      setDataRelational(resultRelacional);
      setDataValidation(resultValidation);
      setDataParra(resultParra);
      setDataSoftBinding(resultSoftBinding);
    });
  }, []);

  if (!dataBinding || !dataRelational || !dataValidation) {
    return (
      <div className={classes.loadingbox}>
        <CircularProgress />
      </div>
    );
  }

  return (
    <>
      <AlertDialog
        removeUser={removeUser}
        handleClose={handleClose}
        open={open}
      />
      <div className={classes.column}>
        <br /> <h3>&nbsp;&nbsp;&nbsp;Versão Parra Completa:</h3>
        {dataParra !== undefined ? (
          <DatatableParra
            openDeleteBox={handleClickOpen}
            data={dataParra}
          />
        ) : null}
        <br />  <br /> <h3>&nbsp;&nbsp;&nbsp;Versão Soft Binding:</h3>

        {dataSoftBinding !== undefined ? (
          <DatatableParra
            openDeleteBox={handleClickOpen}
            data={dataSoftBinding}
          />
        ) : null}
        <br />

        {/* {dataBinding !== undefined ? (
          <DatatableBinding
            openDeleteBox={handleClickOpen}
            data={dataBinding}
          />
        ) : null}
        <br />
        {dataRelational !== undefined ? (
          <DatatableRelational
            openDeleteBox={handleClickOpen}
            data={dataRelational}
          />
        ) : null}
        <br />
        {dataValidation !== undefined ? (
          <DatatableValidation
            openDeleteBox={handleClickOpen}
            data={dataValidation}
          />
        ) : null} */}
      </div>
    </>
  );
};

function AlertDialog({ removeUser, handleClose, open }) {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
      <DialogTitle id="alert-dialog-title">
        {" "}
        Confirma a remoção de um registro?
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description" />
      </DialogContent>
      <DialogActions>
        <Button
          style={{ position: "relative" }}
          variant="outlined"
          color="primary"
          onClick={handleClose}>
          Cancelar
        </Button>
        <Button
          style={{ position: "relative" }}
          variant="contained"
          onClick={removeUser}
          color="primary"
          autoFocus>
          Remover
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ResultTable;
