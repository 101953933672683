import React, { useEffect, useRef, useState } from 'react';
import { Box, CircularProgress, Typography } from '@material-ui/core';
import { makeStyles } from "@material-ui/core/styles";
import { useCreatePeer } from './hooks';
import Frame from './../Iframe';
import { FirebaseService } from "./../../Services/FirebaseService";
import moment from "moment";
import Button from '@material-ui/core/Button';
import VolumeDownIcon from '@material-ui/icons/VolumeDown';
import VolumeOffIcon from '@material-ui/icons/VolumeOff';

import styles from "./_styles";

const useStyles = makeStyles(styles);

const CreateRoom = () => {

    const videoMyself = useRef(null);
    const videoOthers = useRef(null);
    const [peer, error] = useCreatePeer();
    //    const [audiostream,setAudiostream] = use
    const classes = useStyles();
    const [progress, setProgress] = useState();

    const toggleClientAudio = () => {
        console.log("toggle ClientAudio");
        if (videoOthers.current.srcObject)
            videoOthers.current.srcObject.getAudioTracks()[0].enabled = !videoOthers.current.srcObject.getAudioTracks()[0].enabled;
    }


    const toggleServerAudio = () => {
        console.log("toggle ServerAudio", videoMyself.current.srcObject.getAudioTracks());
        if (videoMyself.current.srcObject)
            videoMyself.current.srcObject.getAudioTracks()[0].enabled = !videoMyself.current.srcObject.getAudioTracks()[0].enabled;
    }

    useEffect(() => {
        const interval = setInterval(() => {
            console.debug('Requesting progress each 5 seconds')
            let prog = FirebaseService.getProgressUser();
            if (prog.length)
                prog[0].updated = moment(prog[0].timestamp).format("HH:mm:ss DD/MM/YYYY");
            setProgress(prog[0]);
        }, 5000);
        return () => clearInterval(interval);
    }, []);


    // we received call
    useEffect(() => {
        if (peer) {

            //send peer.id to firebase
            FirebaseService.resetIdCall(peer.id);

            peer.on('call', (mediaConnection) => {
                console.log("we received call");
                // remove id from list
                FirebaseService.removeIdCall(peer.id);

                navigator.mediaDevices.getUserMedia({ audio: true, video: true }
                ).then((videoStream) => {
                    mediaConnection.answer(videoStream);
                    if (videoMyself !== null && videoMyself.current !== null) {
                        videoMyself.current.srcObject = videoStream;
                    }
                    mediaConnection.on('stream', (stream) => {
                        if (videoOthers !== null && videoOthers.current !== null) {
                            videoOthers.current.srcObject = stream;
                        }
                    });
                });
            });
        }
    }, [peer]);

    if (peer)
        console.log(`${peer.id}`);
    //console.log(`${window.location.protocol}//${window.location.host}/join-room/${peer.id}`);


    return (
        <div className={classes.root} >
            <Box p={4} className={classes.boxes} >
                <Frame>
                    <>
                        <video width="50%"
                            height="100%" className={classes.videostyle} ref={videoOthers} autoPlay />
                        <video width="50%"
                            height="100%" className={classes.videostyle} ref={videoMyself} autoPlay muted="muted" />
                    </>
                </Frame>
                <br>
                </br>
                {!peer && (
                    <Typography align="center" variant="h6">
                        <CircularProgress />
                    </Typography>
                )}
                {peer && (
                    <Typography align="center" variant="h4" className={classes.info}>
                        Código da chamada: <b>{peer.id}</b>
                    </Typography>
                )}
            </Box>
            <Box p={4} className={classes.boxes2} >
                {(progress !== undefined) ?
                    <>
                        <br />
                        <Typography align="center" variant="h4" className={classes.info}>
                            Nivel: <b>{progress.currentLevel}</b><br />
                    Subnivel: <b>{progress.currentSublevel}</b><br />
                    Última Atualização: <b>{progress.updated}</b><br />
                    Resposta: <b>{JSON.stringify(progress.data)}</b><br />
                        </Typography>
                        <br />
                    </> : null}
                {videoMyself.current ?
                    <>
                        <Button
                            variant="contained"
                            color="default"
                            className={classes.btnfixed}
                            onClick={() => toggleServerAudio()}
                            startIcon={videoMyself.current.srcObject && videoMyself.current.srcObject.getAudioTracks()[0].enabled ? <VolumeDownIcon /> : <VolumeOffIcon />}
                        >
                            Audio Pesquisador
                </Button>
                &nbsp;
                    <Button
                            variant="contained"
                            color="default"
                            className={classes.btnfixed}
                            onClick={() => toggleClientAudio()}
                            startIcon={videoOthers.current.srcObject && videoOthers.current.srcObject.getAudioTracks()[0].enabled ? <VolumeDownIcon /> : <VolumeOffIcon />}
                        >
                            Audio Participante
                </Button>
                    </> : null}
            </Box>

        </div>
    );
};

export default CreateRoom;
