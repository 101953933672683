import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import styles from '../../_styles';
import { clearLS, getFromLS } from '../../../../utils';
import { FirebaseService } from '../../../../Services/FirebaseService';
import { SmallShapes } from '../Shapes/ShapesCmpts';

const useStyles = makeStyles(styles);

const BindingInstructionSlide = ({ current, next }) => {
  const classes = useStyles();
  const nextScreen = () => {
    next(current + 1);
  };

  return (
    <div className={classes.grid}>
      <Paper
        onClick={nextScreen}
        className={`${classes.paper2}  ${classes.pointer}`}>
        <Grid container direction='row'>
          <Grid item xs={4} md={4}>
            <div className={classes.instBox}>
              <div className={classes.lbrandom1}>
                <SmallShapes number={2} color='red' />
              </div>
              <div className={classes.lbrandom2}>
                <SmallShapes number={4} color='blue' />
              </div>
            </div>
          </Grid>
          <Grid item xs={8} md={8} className={classes.instText}>
            <br />
            <br />
            Você deverá memorizar formas coloridas.
          </Grid>
          <Grid item xs={4} md={4}>
            <div className={classes.instBox} />
          </Grid>
          <Grid item xs={8} md={8} className={classes.instText}>
            <br />
            <br /> Aguardar um intervalo.
          </Grid>
          <Grid item xs={4} md={4}>
            <div className={classes.instBox}>

              <div className={classes.subBox3}>

                <div
                  className={`${classes.littleBox} ${classes.subBox2}  ${classes.lb1}  ${classes.red}`}
                />
                <div
                  className={`${classes.littleBox} ${classes.subBox2}  ${classes.lb2}  ${classes.green}`}
                />
                <div
                  className={`${classes.littleBox} ${classes.subBox2}  ${classes.lb3}  ${classes.yellow}`}
                />
                <div
                  className={`${classes.littleBox} ${classes.subBox2}  ${classes.lb4}  ${classes.blue}`}
                />
                <div
                  className={`${classes.littleBox} ${classes.subBox2}  ${classes.lb5}  ${classes.lightblue}`}
                />
                <div
                  className={`${classes.littleBox} ${classes.subBox2}  ${classes.lb6}  ${classes.pink}`}
                />
              </div>
              <div className={classes.subBox4}>
                <div className={classes.lb1}>
                  <SmallShapes number={1} />
                </div>
                <div className={classes.lb2}>
                  <SmallShapes number={2} />
                </div>
                <div className={classes.lb3}>
                  <SmallShapes number={3} />
                </div>
                <div className={classes.lb4}>
                  <SmallShapes number={4} />
                </div>
                <div className={classes.lb5}>
                  <SmallShapes number={5} />
                </div>
                <div className={classes.lb6}>
                  <SmallShapes number={6} />
                </div>
              </div>
            </div>
          </Grid>
          <Grid item xs={8} md={8} className={classes.instText}>
            <br />
            <br /> Escolher a cor e combiná-la com a respectiva forma.
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
};

const EndedBindingSlide = ({ current, next, setVisibility }) => {
  const classes = useStyles();
  const nextScreen = () => {
    next(current + 1);
  };

  console.log('salvar tudo na base de dados Firebase');

  // 1. buscar no local storage tudo o que foi salvo
  const resultsUser = {};
  resultsUser.userInfo = getFromLS('user');
  resultsUser.questions = getFromLS('questions');
  resultsUser.colors = {};
  resultsUser.shapes = {};
  resultsUser.datetime = new Date().toISOString();
  resultsUser.binding = {};

  for (let j = 1; j <= 4; j++) {
    for (let i = 1; i <= 3; i++) {
      resultsUser.colors[`level-${j}-${i}`] = getFromLS(
        `colors_level_${j}-${i}`,
      );
      resultsUser.shapes[`level-${j}-${i}`] = getFromLS(
        `shapes_level_${j}-${i}`,
      );
      resultsUser.binding[`level-${j}-${i}`] = getFromLS(
        `binding_level_${j}-${i}`,
      );
    }
  }

  clearLS();

  console.log('resultsUser', resultsUser);
  FirebaseService.pushData('participants', resultsUser);

  FirebaseService.getDataList('participants', (list) => {
    console.log('list', list);
  });

  /* useEffect(() => {
     if (setVisibility) {
       setVisibility(true);
     }
   }, []);*/

  return (
    <div className={classes.grid}>
      <Paper
        onClick={nextScreen}
        className={`${classes.paper}  ${classes.pointer}`}>
        <div className={classes.textCenter}>
          Você concluiu a tarefa das combinações.
          <div className={classes.clickToContinue}>
            Obrigado por participar!
          </div>
        </div>
      </Paper>
    </div>
  );
};

const EndedBindingWithNextSlide = ({ current, next }) => {
  const classes = useStyles();
  const nextScreen = () => {
    next(current + 1);
  };

  const instructionText = 'Agora iniciaremos a tarefa das cores e localizações. ';

  return (
    <div className={classes.grid}>
      <Paper
        onClick={nextScreen}
        className={`${classes.paper}  ${classes.pointer}`}>
        <div className={classes.textCenter}>
          Você concluiu a tarefa das cores e formas.
          <br /> {instructionText}
          <div className={classes.clickToContinue}>
            Clique na tela para continuar.
          </div>
        </div>
      </Paper>
    </div>
  );
};



export { BindingInstructionSlide, EndedBindingSlide, EndedBindingWithNextSlide };
