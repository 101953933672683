import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import styles from "./_styles";


const useStyles = makeStyles(styles);


const ClickToContinueSlide = ({ current, next }) => {
  const classes = useStyles();
  const nextScreen = () => {
    next(current + 1);
  };

  return (
    <div className={classes.grid}>
      <Paper
        onClick={nextScreen}
        className={`${classes.paper}  ${classes.pointer}`}
      >
        <div className={classes.textCenter}>
          <div className={classes.clickToContinue}>
            Clique na tela para continuar.
          </div>
        </div>
      </Paper>
    </div>
  );
};


const ExplicationPhase4Slide = ({ current, next }) => {
  const classes = useStyles();

  const nextScreen = () => {
    next(current + 1);
  };

  return (
    <div className={classes.grid}>
      <Paper
        onClick={nextScreen}
        className={`${classes.paper}  ${classes.pointer}`}
      >
        <div className={classes.textCenter}>
          Você verá uma cor e um local marcado por um alvo.
          <br />
          Depois de 1 segundo, você deverá escolher a cor e local corretos.
          <div className={classes.clickToContinue} />
        </div>
      </Paper>
    </div>
  );
};


const ExplicationPhase3Slide = ({ current, next }) => {
  const classes = useStyles();

  const nextScreen = () => {
    next(current + 1);
  };

  return (
    <div className={classes.grid}>
      <Paper
        onClick={nextScreen}
        className={`${classes.paper}  ${classes.pointer}`}
      >
        <div className={classes.textCenter}>
          Você verá separadamente uma cor e um local e deverá apontar qual objeto apresenta essa cor e esse local juntos.
          <div className={classes.clickToContinue} />
        </div>
      </Paper>
    </div>
  );
};


const ExplicationPhase2Slide = ({ current, next }) => {
  const classes = useStyles();

  const nextScreen = () => {
    next(current + 1);
  };


  return (
    <div className={classes.grid}>
      <Paper
        onClick={nextScreen}
        className={`${classes.paper}  ${classes.pointer}`}
      >
        <div className={classes.textCenter}>
          Agora você verá uma cor e uma forma e deverá combinar mentalmente essas características.
          <div className={classes.clickToContinue}>
            Depois de um segundo, você apontará qual objeto combinou mentalmente.
          </div>
        </div>
      </Paper>
    </div>
  );
};


const ExplicationPhase1Slide = ({ current, next, setVisibility }) => {
  const classes = useStyles();
  const nextScreen = () => {
    next(current + 1);
  };


  useEffect(() => {
    console.log("setVisibility", setVisibility);
    if (setVisibility) { setVisibility(true); }
  }, [setVisibility]);


  return (
    <div className={classes.grid}>
      <Paper
        onClick={nextScreen}
        className={`${classes.paper}  ${classes.pointer}`}
      >
        <div className={classes.textCenter}>
          Você verá separadamente uma cor e uma forma e deverá apontar qual objeto apresenta essa cor e essa forma juntas.
          <div className={classes.clickToContinue} />
        </div>
      </Paper>
    </div>
  );
};


export {
  ExplicationPhase1Slide as ExplicationSlide, ExplicationPhase2Slide, ExplicationPhase3Slide, ExplicationPhase4Slide, ClickToContinueSlide,
};
