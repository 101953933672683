
const colorIndexInverted = ['red', 'green', 'yellow', 'blue', 'lightblue', 'pink'];


const colorIndexNormal = ['pink', 'lightblue', 'blue', 'yellow', 'green', 'red'];

export const colorIndex = ["red", "green", "yellow", "blue", "lightblue", "pink"];

const colorOfBlue = {
    red: "Blue-0",
    green: "Blue-1",
    yellow: "Blue-2",
    blue: "Blue-3",
    lightblue: "Blue-4",
    pink: "Blue-5",
}

global.isInverted = false;

export function isInvertedNow(isBlueState = false) {
    if (!isBlueState) return false;
    global.isInverted = !global.isInverted;
    console.log("Mudando 'isInverted' para: ", global.isInverted);
    return global.isInverted;
}

export function getCurrentInvertedState() {
    // console.log("getCurrentInvertedState: ", global.isInverted);
    return global.isInverted;
};


export function correctAnswer(user, correctOnes) {
    const result = correctOnes.filter((n) => user.indexOf(n) > -1);
    return result.length === correctOnes.length;
}


export function storageData(
    stage,
    level,
    isCorrect,
    userAnswers,
    answers,
    attempt,
    userTimes,
    wasInverted = {}
) {
    const newData = {
        attempt,
        level,
        isCorrect,
        userAnswers,
        answers,
        userTimes,
        wasInverted
    };
    const name = `${stage}_level_${level}-${attempt}`;
    saveToLS(name, newData);

    console.log('Dado salvo em memória com o  nome: ', name, ' e os valores: ', newData);
}

export function saveToLS(key, value) {
    if (global.localStorage) {
        global.localStorage.setItem(key, JSON.stringify(value));
    }
}

export function clearLS() {
    if (global.localStorage) {
        try {
            global.localStorage.clear();
            console.log("Dados limpos do cache do navegador. ");
            return true;
        } catch (e) {
            return false;
        }
    }
}

export function getFromLS(key) {
    let ls = {};
    if (global.localStorage) {
        try {
            ls = JSON.parse(global.localStorage.getItem(key));
        } catch (e) {
            ls = 'error';
        }
    }
    return ls;
}

export function sid() {
    return (1 + Math.random() * 429496729125).toFixed().toString();
}


export function getIndexColor(colorName) {
    return colorIndex.findIndex((nm) => nm === colorName);
}


export const getDistanceForAnswers = (isInverted, userAnswers, answers) => {
    let answerDistance = 0;
    let rightDistance = 0;

    if (isInverted) {
        userAnswers.forEach((answer) => {
            answerDistance += colorIndexInverted.indexOf(answer);
        });
        answers.forEach((answer) => {
            rightDistance += colorIndexInverted.indexOf(answer);
        });
    }
    else {
        userAnswers.forEach((answer) => {
            answerDistance += colorIndexNormal.indexOf(answer);
        });
        answers.forEach((answer) => {
            rightDistance += colorIndexNormal.indexOf(answer);
        });
    };
    return answerDistance - rightDistance;
}


export const _showUserClick = (currentState, isInverted, userAnswers, Answers) => {
    console.log("__________________________________________________________");
    console.log(`${currentState}:: Está invertido: `, isInverted);
    console.log(`${currentState}:: Respostas do Usuário: `, userAnswers);
    if (isInverted)
        userAnswers.forEach((answer) => {
            console.log(`${currentState}:: Na posição: `, colorIndexInverted.indexOf(answer));
        });
    else {
        userAnswers.forEach((answer) => {
            console.log(`${currentState}:: Na posição: `, colorIndexNormal.indexOf(answer));
        });

    }
    console.log(`${currentState}:: Respostas certas: `, Answers);

}


export const _showConsoleInformation = (currentState, schemaLevel, isCorrect) => {
    console.log("__________________________________________________________");
    console.log(`${currentState}:: Coleta da tentativa terminado. `);

    if (isCorrect) {
        console.log(`${currentState}:: Acertou a tentativa.`);
    }
    else {
        console.log(`${currentState}:: Errou a tentativa.`);
    }
    console.log(`${currentState}:: Estado atual. Acertos: `, schemaLevel.correctTrials);
    console.log(`${currentState}:: Estado atual. Erros: `, schemaLevel.wrongTrials);
    console.log(`${currentState}:: Estado atual. Trials requisitados: `, schemaLevel.trials);
}

export const requestAgainOrNewLevel = (currentState, schemaLevel, _level, _setLevel, _nextLoop) => {
    const totalTrials = schemaLevel.correctTrials + schemaLevel.wrongTrials;
    if (totalTrials === schemaLevel.trials) {
        // next level.
        console.log(`${currentState}:: Mudando para o próximo nivel.`);
        _setLevel(_level + 1);
    } else {
        console.log(`${currentState}:: Pedindo outro Trial.`);
        _nextLoop();
    }
}
