/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import { ShowInfoOnConsole, RetensionSlide } from "../Colors/ColorSlides";
import {
  AnswerBindingSlide as AnswerSlide,
  BindingChoiceSlide as ChoiceSlide,
} from "./BindingSlides";
import { isInvertedNow, getCurrentInvertedState, correctAnswer, _showConsoleInformation, requestAgainOrNewLevel } from "../../../../utils";

const schema = {
  0: {},
  1: {
    timeout: "3000",
    correctTrials: 0,
    nShapes: 1,
    trials: 2,
    userAnswers: [],
    answers: [],
  },
  2: {
    timeout: "6000",
    correctTrials: 0,
    nShapes: 2,
    trials: 2,
    userAnswers: [],
    answers: [],
  },
  3: { stop: true },
};

// level here means sublevel (level = internal step)
const ApplicationBindingRunning = ({ current, nextLvl, nextSubLevel, currentTheme }) => {
  const [level, setLevel] = useState(1);
  const [currentSlide, setCurrentSlide] = useState(null);
  const [reply, setReply] = useState();

  // getting next anwser for the same test
  useEffect(() => {
    if (reply != 0) return;
    setCurrentSlide(<AnswerSlide func={chosenColor} isInverted={getCurrentInvertedState()} />);
  }, [reply]);

  const nextScreen = () => {
    nextSubLevel(0);
    nextLvl(current + 1);
  };

  const chosenColor = (timeLoaded, colorName) => {
    schema[level].userAnswers.push(colorName);
    console.log("🚀 ~ Color Or Shape clicked: ", colorName);
    schema[level].replies = schema[level].replies + 1;
    if (schema[level].replies < schema[level].nShapes * 2) { setReply(schema[level].replies); }

    if (schema[level].replies === schema[level].nShapes * 2) { checkNextLevelOrRetry(); }
  };

  const checkNextLevelOrRetry = () => {
    const isCorrect = correctAnswer(
      schema[level].userAnswers,
      schema[level].answers
    );

    if (isCorrect) {
      schema[level].correctTrials += 1;
    } else {
      schema[level].wrongTrials += 1;
    }

    const isBlueSet = currentTheme === 'set2';
    if (isBlueSet) {
      schema[level].wasInverted = getCurrentInvertedState();
    }


    _showConsoleInformation("Training Binding", schema[level], isCorrect);

    isInvertedNow(currentTheme === 'set2');
    requestAgainOrNewLevel("Training Binding", schema[level], level, setLevel, colorLoop);

  };

  const AnswerLoop = () => {
    schema[level].userAnswers = [];
    schema[level].replies = 0;
    schema[level].wasInverted = null;
    setReply(0);

    setCurrentSlide(<AnswerSlide func={chosenColor} isInverted={getCurrentInvertedState()} />);
  };

  const retensionLoop = () => {
    setCurrentSlide(<RetensionSlide />);
    setTimeout(() => {
      AnswerLoop();
    }, 1000);
  };

  const setAnswers = (colorName) => {
    schema[level].answers.push(colorName);
  };

  const showAnswersAndToRetension = (timeout) => {
    setTimeout(() => {
      retensionLoop();
    }, timeout);
  };

  const colorLoop = () => {
    const { timeout, nShapes } = schema[level];
    schema[level].answers = [];
    if (schema[level].stop) {
      // found the stop command
      nextScreen();
      return;
    }

    setCurrentSlide(
      <ChoiceSlide
        nShapes={nShapes}
        setAnswers={setAnswers}
        timeout={timeout}
        setRetension={showAnswersAndToRetension}
      />
    );
  };

  useEffect(() => {
    console.log("Limpando level: ", level);
    nextSubLevel(level);
    schema[level].correctTrials = 0;
    schema[level].wrongTrials = 0;

    colorLoop();
  }, [level]);

  return (
    <div>
      <ShowInfoOnConsole level={level} data={schema[level]} />
      {currentSlide}
    </div>
  );
};

export default ApplicationBindingRunning;
