import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import MaterialTable from "material-table";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import IconButton from "@material-ui/core/IconButton";
import moment from "moment";
import Button from "@material-ui/core/Button";
import renderValidation from "./renderValidation";
import tableIcons from "./icons";

import { ExcelTable as ValidationExcel } from "./validationExcel";


const styles = (theme) => ({
  root: {
    flexGrow: 1,
    marginTop: "8px",
    marginLeft: "30px",
    display: "block",
    padding: "8px",
    backgroundColor: "white",
  },
  bigwidth: {
    display: "block",
    width: "2900px",
  },
  setinfo: {
    textTransform: "uppercase",
  },
  prtcpnt: {
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "12px",
    lineHeight: "26px",
    letterSpacing: "0.01em",
    textTransform: "capitalize",
  },
  trials: {
    fontStyle: "normal",
    fontSize: "11px",
    lineHeight: "18px",
    letterSpacing: "0.01em",
    textTransform: "capitalize",
  },
  t1: {
    color: "#2A8BF2",
  },
  t2: {
    color: "#2043BE",
  },
  t3: {
    color: "#FF3366",
  },
});

const useStyles = makeStyles(styles);

const renderParticipantInfo = (classes, rowData) => {
  let dt = "";
  if (rowData.userInfo.datetime) {
    dt = moment(rowData.userInfo.datetime).format("hh:mm:ss DD/MM/YYYY");
  }

  return (
    <div className={classes.prtcpnt}>
      {rowData.userInfo.name}
      <br />
      <b>Data:</b> {dt}
      <br />
      <b>RG:</b> {rowData.userInfo.rg}
      <br />
      <b>Código:</b> {rowData.userInfo.code}
      <br />
      {rowData.userInfo.gender}
      <br />
      {rowData.userInfo.handedness}
      <br />
      <b>Idade:</b>
      {rowData.userInfo.age}
      <br />
      <b className={classes.setinfo}>{rowData.userInfo.set}</b>
      <br />
    </div>
  );
};

const createSet = (phase, currentlevel) => {
  const obj = {};
  if (phase === undefined) return obj;
  if (phase[`level-${currentlevel}-${currentlevel}`]) {
    obj[`level-${currentlevel}-${currentlevel}`] =
      phase[`level-${currentlevel}-${currentlevel}`];
  }
  return obj;
};

const DataTable = ({ data, openDeleteBox }) => {
  const classes = useStyles();

  const [showTable, setShowTable] = useState(false);
  const [rawData, setRawData] = useState([]);

  useEffect(() => {
    const data2 = [];
    data.forEach((element) => {
      const el2 = {};
      el2.key = element.key;
      el2.userInfo = { ...element.userInfo, ...element.questions };
      for (let i = 1; i <= 24; i++) {
        el2[`validation${i}`] = createSet(element.validation, i);
      }
      data2.push(el2);
    });
    console.log("data2", data2);
    setRawData(data2);
  }, [data]);

  const columns = [
    {
      title: "Opções ",
      field: "key",
      render: (rowData) => (
        <IconButton
          onClick={() => openDeleteBox(rowData, "validation")}
          color="primary"
          aria-label="upload picture"
          component="span">
          <DeleteForeverIcon />
        </IconButton>
      ),
    },
    {
      title: "Participante",
      field: "userInfo",
      render: (rowData) => renderParticipantInfo(classes, rowData),
    },
  ];

  for (let i = 1; i <= 24; i++) {
    columns.push({
      title: `Validação - Prova ${i}`,
      field: `validation${i}`,
      render: (rowData) =>
        renderValidation(classes, rowData[`validation${i}`], i),
    });
  }

  return (
    <div className={classes.root}>
      <div className={classes.bigwidth}>
        {!showTable && (
          <Button
            style={{ position: "relative" }}
            onClick={() => setShowTable(true)}
            color="primary">
            Mostrar Resultados da Validação
          </Button>
        )}
        {showTable && (
          <Button
            style={{ position: "relative" }}
            onClick={() => setShowTable(false)}
            color="primary">
            Esconder Resultados da Validação
          </Button>
        )}
        &nbsp; &nbsp; &nbsp; &nbsp;
        <ValidationExcel title="validation" data={rawData} />

        {showTable && (
          <MaterialTable
            components={{
              Container: (props) => props.children,
            }}
            icons={tableIcons}
            title="Resultados da Validação"
            columns={columns}
            data={rawData}
          />
        )}
      </div>
    </div>
  );
};

export { DataTable };
