/* eslint-disable react/prop-types */
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import React, { useEffect, useState } from "react";
import { ReactComponent as Splash } from "../../../../splash.svg";
import styles from "../../Relacional/_styles";
import { ClickToContinueSlide, SplashColor } from "../Colors/ColorSlides";
import { Locations } from "../Location/LocationCmpts";

const useStyles = makeStyles(styles);

const Colors = ({ color, onClick }) => {
  const classes = useStyles();
  return (
    <div className={classes.shapes} onClick={onClick}>
      <Splash className={`${classes.splash} ${classes[`sp_${color}`]}`} />
    </div>
  );
  /* return (
      <div className={classes.colors} onClick={onClick}>
        <div className={classes[color]} />
      </div>
    );
    */
};

const AnswerRelacionalSlide = ({ func, isInverted = false }) => {
  const timeLoaded = Date.now();
  const classes = useStyles();
  const [isHead, setIsHead] = useState(false);
  const [answeredList, setAnsweredList] = useState([]);

  const clickedIn = (headClicked, value) => {
    if (isHead !== headClicked) {
      console.log("Miss Clcick!");
      return "";
    }
    setAnsweredList([...answeredList, value]);
    func(timeLoaded, value);
    setIsHead(!isHead);
  };

  const myclick = (coin, element) => {
    clickedIn(coin, element);
  };

  const createLocations = (element, number) => {
    if (answeredList.includes(element)) {
      return null;
    }
    return <Locations onClick={() => myclick(true, element)} number={number} />;
  };

  const createColors = (element, colorClass) => {
    if (answeredList.includes(element)) {
      return null;
    }
    return (
      <div className={colorClass}>
        <Colors onClick={() => myclick(false, element)} color={element} />
      </div>
    );
  };

  const createAllColors = () => {
    if (isInverted) {

      return (<div className={!isHead ? classes.pointer : classes.notPointer}>
        {createColors("red", classes.boxColor01)}
        {createColors("green", classes.boxColor02)}
        {createColors("yellow", classes.boxColor03)}
        {createColors("blue", classes.boxColor04)}
        {createColors("lightblue", classes.boxColor05)}
        {createColors("pink", classes.boxColor06)}
      </div>);
    }
    return (<div className={!isHead ? classes.pointer : classes.notPointer}>
      {createColors("pink", classes.boxColor01)}
      {createColors("lightblue", classes.boxColor02)}
      {createColors("blue", classes.boxColor03)}
      {createColors("yellow", classes.boxColor04)}
      {createColors("green", classes.boxColor05)}
      {createColors("red", classes.boxColor06)}
    </div>);
  }


  return (
    <div className={classes.grid}>
      <Paper className={classes.paper4}>
        <div className={isHead ? classes.pointer : classes.notPointer}>
          {createLocations("p1", 1)}
          {createLocations("p2", 2)}
          {createLocations("p3", 3)}
          {createLocations("p4", 4)}
          {createLocations("p5", 5)}
          {createLocations("p6", 6)}
        </div>

        {createAllColors()};

      </Paper>
    </div>
  );
};

const RelacionalChoiceSlide = ({
  nShapes,
  setAnswers,
  setRetension,
  timeout,
}) => {
  const classes = useStyles();
  const [waiting, setWaiting] = useState(true);

  const listEl = [];
  const locationIndex = ["1", "2", "3", "4", "5", "6"];
  const colorIndex = ["red", "green", "yellow", "blue", "lightblue", "pink"];

  useEffect(() => {
    if (!waiting) {
      setTimeout(() => {
        setRetension();
      }, timeout);
    }
  }, [waiting]);

  if (waiting) {
    return (
      <ClickToContinueSlide setWaiting={setWaiting}> </ClickToContinueSlide>
    );
  }

  for (let i = 1; i <= nShapes; i++) {
    const index = Math.floor(Math.random() * locationIndex.length);
    const shapeIndex = locationIndex[index];
    const shapeName = `p${locationIndex[index]}`;
    locationIndex.splice(index, 1);
    const index2 = Math.floor(Math.random() * colorIndex.length);
    const colorName = colorIndex[index2];
    colorIndex.splice(index2, 1);
    const el = (
      <div key={i} className={classes[`location${shapeIndex}of6`]}>
        <SplashColor color={colorName} />
      </div>
    );

    listEl.push(el);
    setAnswers(shapeName);
    setAnswers(colorName);
  }

  return (
    <div className={classes.grid}>
      <Paper className={`${classes.paper4} ${classes.noMouse}`}>{listEl}</Paper>
    </div>
  );
};

export { AnswerRelacionalSlide, RelacionalChoiceSlide };
