import React from 'react';
import Routes from './routes';
import { ThemeProvider } from "@material-ui/core";
import { normalPalette } from "./theme";
import { makeStyles } from '@material-ui/core/styles';

const styles = () => ({
    app: {
        display: "block",
        backgroundColor: "white",
        position: 'absolute',
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        height: 'calc(100%)',
        width: '100%',
    },
});


const useStyles = makeStyles(styles);

const App = () => {
    const classes = useStyles();

    return (
        <div className={classes.app}>
            <ThemeProvider theme={normalPalette}>
                <Routes />
            </ThemeProvider>
        </div>
    )
};

export default App;
