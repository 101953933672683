import React, { useEffect, useState, useRef } from 'react';
import { Box, CircularProgress, Typography } from '@material-ui/core';
import { makeStyles } from "@material-ui/core/styles";
import TextField from '@material-ui/core/TextField';
import styles from "./_styles";
import { getPeer } from '../../Services/Peer/peer';
import Button from "@material-ui/core/Button";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import VisibilityIcon from "@material-ui/icons/Visibility";
import Frame from '../Iframe';
import { FirebaseService } from "../../Services/FirebaseService";

const useStyles = makeStyles(styles);

const JoinRoom = ({ videoIsVisible, setVideoIsVisible }) => {
    const videoMyself = useRef(null);
    const videoOthers = useRef(null);
    const [idAvailable, setIdAvailable] = useState([]);

    const [myid, setMyid] = useState("");
    const [id, setId] = useState();
    const [newVideoIsVisible, setNewVideoIsVisible] = useState(false); //videoIsVisible

    const [peer, setPeer] = useState();
    const [connected, setConnected] = useState(false);
    const [error, setError] = useState();

    const classes = useStyles();

    const sendRequest = () => {
        console.log("Connection availables: ", idAvailable.length);
        if (idAvailable.length === 1) {
            console.log("Connecting to ID: ", idAvailable[0]);
            setId(idAvailable[0]);
        }
    };

    // init
    useEffect(() => {
        getPeer().then((peerResult) => {
            setPeer(peerResult);
            peerResult.on('error', (error) => {
                console.log('XXXA error', error);
                setId(null);

                setError(error.message);
            })
                .on('disconnected', (error) => {
                    console.log('XXXA disconnected', error);
                    //  retrySocketConnection();
                });

        });

        FirebaseService.getIdsCalls(setIdAvailable);

    }, []);

    let conn = {};
    // connect to server
    useEffect(() => {

        if (id && peer) {
            conn = peer.connect(id, {
                reliable: true,
            });
            conn.on('open', () => {
                setConnected(true);
            });
            conn.on('disconnected', () => {
                console.log(" disconnected  ");
                //  retrySocketConnection();
            });
            conn.on('error', (error) => {
                console.log(' error', error);
                setError(error.message);
            });
        }

    }, [id, peer]);


    // we call directly when connection establish
    useEffect(() => {
        if (peer && id && connected) {
            console.log("peer", peer);
            console.log("id", id);
            console.log("connected", connected);
            console.log("navigator", navigator);
            if (navigator)
                navigator.mediaDevices.getUserMedia({
                    audio: true, video: true
                }).then((videoStream) => {
                    const mediaConnection = peer.call(id, videoStream);
                    if (videoMyself !== null && videoMyself.current !== null) {
                        videoMyself.current.srcObject = videoStream;
                    }
                    mediaConnection.on('stream', (stream) => {
                        if (videoOthers !== null && videoOthers.current !== null) {
                            videoOthers.current.srcObject = stream;
                        }
                    });
                });
        }
    }, [peer, connected, id]);

    const toggleInfoBox = () => {
        //setVideoIsVisible(!videoIsVisible);
        //setNewVideoIsVisible(!newVideoIsVisible);
        //console.log("@C ~ toggleInfoBox ~ videoIsVisible: ", newVideoIsVisible)
    };


    return (
        <>
            {newVideoIsVisible ?
                (<Button
                    onClick={toggleInfoBox}
                    variant="outlined"
                    size="small"
                    color="primary"
                    className={classes.showhidebtn}
                >
                    <VisibilityIcon />
                </Button>)
                : (
                    <Button
                        onClick={toggleInfoBox}
                        variant="outlined"
                        size="small"
                        color="primary"
                        className={classes.showhidebtn}
                    >
                        <VisibilityOffIcon />
                    </Button>)}


            <div className={classes.root + " " + (newVideoIsVisible ? classes.isvisible : classes.notvisible)} >

                <Box p={4} className={classes.boxes} >
                    {!id && (
                        <Typography align="center" variant="h6">
                            {(idAvailable.length === 0) ?
                                <span><br></br>Em instantes o pesquisador estará disponível. Por favor aguarde.
                                </span> : null
                            }

                            <br />
                            <br />
                            {(idAvailable.length == 1) ?
                                <span>O pesquisador está aguardando,<br></br> clique no botão a seguir para iniciar.<br /><br />
                                    <Button
                                        className={classes.btnbonito}
                                        color="primary" onClick={sendRequest}>
                                        Conectar
                                    </Button>
                                </span> : null
                            }

                        </Typography>
                    )}
                    {id && (
                        <Frame>
                            <>
                                <video
                                    width="50%"
                                    height="100%" className={classes.videostyle}
                                    ref={videoOthers} autoPlay />
                                <video
                                    width="50%"
                                    height="100%"
                                    className={classes.videostyle}
                                    ref={videoMyself} autoPlay muted="muted" />
                            </>
                        </Frame>
                    )}

                </Box>

            </div>
        </>
    );
};

export { JoinRoom };
