/* eslint-disable react/prop-types */
import React, { useEffect, useRef, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import { SVG, extend as SVGextend, Element as SVGElement } from "@svgdotjs/svg.js"
import styles from "./_styles";
import { ReactComponent as Splash } from "../../splash.svg";

const useStyles = makeStyles(styles);

const ShowInfoOnConsole = ({ level, data }) => {
  console.log("level: ", level, data);
  return null;
};


// colors

const SplashColor = ({ color }) => {
  const classes = useStyles();
  return <Splash className={`${classes.splash} ${classes[`sp_${color}`]}`} />
};

const Colors = ({ color, onClick, pointer }) => {
  const isPointer = !!pointer;
  const classes = useStyles();
  return (
    <div className={`${classes.shapes} ${isPointer ? classes.pointer : ""}`} onClick={onClick}>
      <Splash className={`${classes.splash} ${classes[`sp_${color}`]}`} />
    </div>
  );
};


const ColorsSmall = ({ color, onClick, pointer }) => {
  const isPointer = !!pointer;
  const classes = useStyles();
  return (
    <div className={`${classes.smallshapes} ${isPointer ? classes.pointer : ""}`} onClick={onClick}>
      <Splash className={`${classes.smallsplash} ${classes[`sp_${color}`]}`} />
    </div>
  );
};


const Boxes = ({ color }) => {
  const classes = useStyles();
  return (
    <div className={classes.shapes}>
      <div className={`${classes.box} ${classes[color]}`} />
    </div>
  );
};


const Circles = ({ color }) => {
  const classes = useStyles();
  return (
    <div className={classes.shapes}>
      <div className={`${classes.circle} ${classes[color]}`} />
    </div>
  );
};


const Target = ({ color }) => {
  const classes = useStyles();
  const svgDrawing = useRef(null);

  /* The useEffect Hook is for running side effects outside of React,
     for instance inserting elements into the DOM using D3 */
  useEffect(
    () => {
      if (svgDrawing.current) {
        const draw = SVG(svgDrawing.current);
        const crcle3 = draw.circle(98);
        const crcle2 = draw.circle(75);
        const crcle1 = draw.circle(50);
        crcle3.fill("#c3c3c3").move(1, 1);
        crcle2.fill("#c3c3c3").move(12.5, 12.5);
        crcle1.fill("#c3c3c3").move(25, 25);
        const line1 = draw.line(50, 40, 50, 60);
        const line2 = draw.line(40, 50, 60, 50);
        crcle1.stroke({ color: "black", width: 2, linecap: "round" })
        crcle2.stroke({ color: "black", width: 2, linecap: "round" })
        crcle3.stroke({ color: "black", width: 2, linecap: "round" })
        line1.stroke({ color: "black", width: 2, linecap: "round" })
        line2.stroke({ color: "black", width: 2, linecap: "round" })
      }
    },
    [svgDrawing.current]
  );

  // const draw = SVG().addTo('body').size(600, 600);
  return (
    <div className={classes.shapes}>
      <svg ref={svgDrawing} width="100%" height="100%" />
    </div>
  );
};


const Triangles = ({ color }) => {
  const classes = useStyles();
  const svgDrawing = useRef(null);

  let clr = color;
  if (color === "green") { clr = "#73e831"; }
  /* The useEffect Hook is for running side effects outside of React,
     for instance inserting elements into the DOM using D3 */
  useEffect(
    () => {
      if (svgDrawing.current) {
        const draw = SVG(svgDrawing.current);
        //             let lineX = draw.line(0, 100, 50, 50)
        //               lineX.stroke({ color: 'black', width: 2, linecap: 'round' })
        const polygon = draw.polygon("50,0 0,98 98,98");
        polygon.fill(clr).move(0, 0);
        polygon.stroke({ color: "black", width: 2, linecap: "round" })
        // '#f06'
      }
    },
    [svgDrawing.current]
  )


  // const draw = SVG().addTo('body').size(600, 600);
  return (
    <div className={classes.shapes}>
      <svg ref={svgDrawing} width="100%" height="100%" />
    </div>
  );
};

//


const TemplateSlide = ({ func, data }) => {
  const classes = useStyles();
  const timeLoaded = Date.now();
  const {
    Element1, Element2, Element3, Element4, text1, text2,
  } = data;

  return (
    <div className={classes.grid}>
      <Paper className={classes.paper}>
        <span className={`${classes.textBase} ${classes.textPos1}`}>{text1}</span>
        <div className={classes.boxesCenter1}>
          <div className={classes.boxLeft}>
            {Element1}
          </div>
          <div className={classes.boxRight}>
            {Element2}
          </div>
        </div>
        <br />
        <span className={`${classes.textBase} ${classes.textPos2}`}>{text2}</span>
        {" "}
        <br />
        <div className={classes.boxesCenter2}>
          <div className={`${classes.boxLeft} ${classes.pointer}`} onClick={() => func("01")}>
            {Element3}
          </div>
          <div className={`${classes.boxRight} ${classes.pointer}`} onClick={() => func("02")}>
            {Element4}
          </div>
        </div>
      </Paper>
    </div>
  );
};


const Template2Slide = ({ func, data }) => {
  const classes = useStyles();
  const { Element1, Element2, text1 } = data;

  return (
    <div className={classes.grid}>
      <Paper className={classes.paper} onClick={() => func()}>
        <br />
        <span className={`${classes.textBase} ${classes.textPos1}`}>{text1}</span>
        <br />
        <div className={classes.boxesCenter1}>
          <div className={classes.boxLeft}>
            {Element1}
          </div>
          <div className={classes.boxRight}>
            {Element2}
          </div>
        </div>
      </Paper>
    </div>
  );
};


const Answer2Slide = ({ func, data }) => {
  const classes = useStyles();
  const { Element3, Element4, text2 } = data;

  const getAnswer = (type) => {
    func(type);
  };


  return (
    <div className={classes.grid}>
      <Paper className={classes.paper}>
        <br />
        <span className={`${classes.textBase} ${classes.textPos1}`}>{text2}</span>
        <br />
        <div className={classes.boxesCenter1}>
          <div className={`${classes.boxLeft} ${classes.pointer}`} onClick={() => getAnswer("01")}>
            {Element3}
          </div>
          <div className={`${classes.boxRight} ${classes.pointer}`} onClick={() => getAnswer("02")}>
            {Element4}
          </div>
        </div>
      </Paper>
    </div>
  );
};


const Template3Slide = ({ func, data }) => {
  const classes = useStyles();
  const {
    Element1, Element2, Element3, Element4, text1, text2, class1, class2, classbase,
  } = data;

  return (
    <div className={classes.grid}>
      <Paper className={classes.paper}>
        <span className={`${classes.textBase} ${classes.textPos1}`}>
          Se colocarmos essa cor
          {" "}
          {Element1}
          nesse local do retângulo
        </span>
        <div className={classes.boxesCenterBorder1}>
          <div className={classes[classbase]}>
            {Element2}
          </div>
        </div>
        <br />
        <span className={`${classes.textBase} ${classes.textPos2Boxes}`}>{text2}</span>
        {" "}
        <br />
        <div className={classes.boxesCenterBorder2}>
          <div className={`${classes[class1]} ${classes.pointer}`} onClick={() => func("01")}>
            {Element3}
          </div>
          <div className={`${classes[class2]} ${classes.pointer}`} onClick={() => func("02")}>
            {Element4}
          </div>
        </div>
      </Paper>
    </div>
  );
};


const Template4Slide = ({ func, data }) => {
  const classes = useStyles();
  const {
    Element1, Element2, text1, class1,
  } = data;

  return (
    <div className={`${classes.grid} ${classes.noMouse}`}>
      <Paper className={classes.paper} onClick={() => func()}>
        <span className={`${classes.textBase} ${classes.textPos1}`}>
          Se colocarmos essa cor
          {" "}
          {Element1}
          nesse local
        </span>
        <div className={classes[class1]}>
          {Element2}
        </div>
      </Paper>
    </div>
  );
};


const Answer4Slide = ({ func, data }) => {
  const classes = useStyles();
  const {
    Element3, Element4, text2, class1, class2,
  } = data;

  return (
    <div className={classes.grid}>
      <Paper className={classes.paper}>
        <br />
        <span className={`${classes.textBase} ${classes.textPos1}`}>{text2}</span>
        <br />
        <div>
          <div className={`${classes[class1]} ${classes.pointer}`} onClick={() => func("01")}>
            {Element3}
          </div>
          <div className={`${classes[class2]} ${classes.pointer}`} onClick={() => func("02")}>
            {Element4}
          </div>
        </div>
      </Paper>
    </div>
  );
};


export {
  SplashColor,
  ShowInfoOnConsole,
  TemplateSlide,
  Template2Slide,
  Template3Slide,
  Template4Slide,
  ColorsSmall,
  Answer2Slide,
  Answer4Slide,
  Boxes,
  Target,
  Triangles,
  Circles,
  Colors,
};
