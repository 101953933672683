/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import { ReactComponent as Splash } from '../../../../splash.svg';
import styles from '../../_styles';

const useStyles = makeStyles(styles);

const ShowInfoOnConsole = ({ level, data }) =>
  // console.log('level: ', level, data);
  null
  ;

const SplashColor = ({ color }) => {
  const classes = useStyles();
  return <Splash className={`${classes.splash} ${classes[`sp_${color}`]}`} />;
};

const Colors = ({ color, onClick, pointer }) => {
  const isPointer = !!pointer;
  const classes = useStyles();
  return (
    <div
      className={`${classes.shapes} ${isPointer ? classes.pointer : ''}`}
      onClick={onClick}>
      <Splash className={`${classes.splash} ${classes[`sp_${color}`]}`} />
    </div>
  );
};

const AnswerColorSlide = ({ func: callbackFunction, isInverted = false }) => {
  const classes = useStyles();
  const timeLoaded = Date.now();
  const [answeredList, setAnsweredList] = useState([]);
  const myclick = (color) => {
    setAnsweredList([...answeredList, color]);
    callbackFunction(timeLoaded, color);
  };

  const createColors = (color) => {
    if (answeredList.includes(color)) {
      // Empty space (the Color equals to background)
      return <Colors color='gray' />;
    }
    return <Colors pointer onClick={() => myclick(color)} color={color} />;
  };
  console.log("________>isInverted: ", isInverted);
  if (isInverted)
    return (
      <div className={classes.grid}>
        <Paper className={classes.paper}>
          {createColors('red')}
          {createColors('green')}
          {createColors('yellow')}
          {createColors('blue')}
          {createColors('lightblue')}
          {createColors('pink')}
        </Paper>
      </div>
    );

  return (
    <div className={classes.grid}>
      <Paper className={classes.paper}>
        {createColors('pink')}
        {createColors('lightblue')}
        {createColors('blue')}
        {createColors('yellow')}
        {createColors('green')}
        {createColors('red')}
      </Paper>
    </div>
  );
};

const ClickToContinueSlide = ({ setWaiting }) => {
  const classes = useStyles();
  const clickedOn = () => {
    setWaiting(false);
  };

  return (
    <div className={classes.grid}>
      <Paper
        onClick={clickedOn}
        className={`${classes.paper}  ${classes.pointer}`}>
        <div className={classes.textCenter}>
          <div className={classes.clickToContinue}>
            Clique na tela para continuar.
          </div>
        </div>
      </Paper>
    </div>
  );
};

const ToGoGameSlide = ({ current, next }) => {
  const classes = useStyles();
  const nextScreen = () => {
    next(current + 1);
  };

  return (
    <div className={classes.grid}>
      <Paper
        onClick={nextScreen}
        className={`${classes.paper}  ${classes.pointer}`}>
        <div className={classes.textCenter}>
          Você concluiu o treino.
          <br /> Agora iniciaremos a tarefa.
          <div className={classes.clickToContinue}>
            Clique na tela para continuar.
          </div>
        </div>
      </Paper>
    </div>
  );
};

const RetensionSlide = () => {
  const classes = useStyles();
  return (
    <div className={classes.grid}>
      <Paper className={classes.paper} />
    </div>
  );
};

const ColorChoiceSlide = ({ nColors, setAnswers, setRetension, timeout }) => {
  const classes = useStyles();
  const [waiting, setWaiting] = useState(true);

  const listEl = [];
  const colorIndex = ['red', 'green', 'yellow', 'blue', 'lightblue', 'pink'];

  useEffect(() => {
    if (!waiting) {
      setTimeout(() => {
        setRetension();
      }, timeout);
    }
  }, [waiting]);

  if (waiting) {
    return (
      <ClickToContinueSlide setWaiting={setWaiting}> </ClickToContinueSlide>
    );
  }

  for (let i = 1; i <= nColors; i++) {
    const index = Math.floor(Math.random() * colorIndex.length);
    const colorName = colorIndex[index];
    console.log('Estamos Mostrando o Elemento de Cor: ', colorName);
    colorIndex.splice(index, 1);

    let el = null;
    el = (
      <div key={i} className={classes[`location${i}of${nColors}`]}>
        <SplashColor color={colorName} />
      </div>
    );

    listEl.push(el);
    setAnswers(colorName);
  }
  return (
    <div className={classes.grid}>
      <Paper className={classes.paper}>{listEl}</Paper>
    </div>
  );
};

export {
  SplashColor,
  ClickToContinueSlide,
  ShowInfoOnConsole,
  AnswerColorSlide,
  RetensionSlide,
  ColorChoiceSlide,
  ToGoGameSlide,
  Colors,
};
