/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import {
  TemplateSlide, Colors, Boxes, Triangles, Circles,
} from "./Cmpts";
import { storageData } from "../../utils";


const stages = {
  1: {
    stage: "1",
    text1: "Se pintarmos essa forma com essa cor",
    text2: "Como vai ficar?",
    Element1: <Boxes onClick={() => null} color="#c3c3c3" />,
    Element2: <Colors onClick={() => null} color="red" />,
    Element3: <Boxes onClick={() => null} color="red" />,
    Element4: <Boxes onClick={() => null} color="blue" />,
    answer: "01",
  },
  2: {
    stage: "2",
    text1: "Se pintarmos essa forma com essa cor",
    text2: "Como vai ficar?",
    Element1: <Circles onClick={() => null} color="" />,
    Element2: <Colors onClick={() => null} color="blue" />,
    Element3: <Circles onClick={() => null} color="green" />,
    Element4: <Circles onClick={() => null} color="blue" />,
    answer: "02",
  },
  3: {
    stage: "3",
    text1: "Se pintarmos essa forma com essa cor",
    text2: "Como vai ficar?",
    Element1: <Triangles onClick={() => null} color="#c3c3c3" />,
    Element2: <Colors onClick={() => null} color="green" />,
    Element3: <Circles onClick={() => null} color="green" />,
    Element4: <Triangles onClick={() => null} color="green" />,
    answer: "02",
  },
  4: {
    stage: "4",
    text1: "Se pintarmos essa forma com essa cor",
    text2: "Como vai ficar?",
    Element1: <Boxes onClick={() => null} color="" />,
    Element2: <Colors onClick={() => null} color="red" />,
    Element3: <Boxes onClick={() => null} color="red" />,
    Element4: <Circles onClick={() => null} color="red" />,
    answer: "01",
  },
  5: {
    stage: "5",
    text1: "Se pintarmos essa forma com essa cor",
    text2: "Como vai ficar?",
    Element1: <Circles onClick={() => null} color="" />,
    Element2: <Colors onClick={() => null} color="blue" />,
    Element3: <Circles onClick={() => null} color="blue" />,
    Element4: <Boxes onClick={() => null} color="red" />,
    answer: "01",
  },
  6: {
    stage: "6",
    text1: "Se pintarmos essa forma com essa cor",
    text2: "Como vai ficar?",
    Element1: <Triangles onClick={() => null} color="#c3c3c3" />,
    Element2: <Colors onClick={() => null} color="green" />,
    Element3: <Circles onClick={() => null} color="red" />,
    Element4: <Triangles onClick={() => null} color="green" />,
    answer: "02",
  },
};


const Phase1Slide = ({
  stage, current, nextLvl, nextSubLevel,
}) => {
  const [answer, setAnswer] = useState("00");
  const [currentSlide, setCurrentSlide] = useState(null);

  const nextScreen = () => {
    nextSubLevel(0);
    nextLvl(current + 1);
  };

  useEffect(() => {
    if (answer === "00") return;
    const data = stages[stage];

    const isCorrect = data.answer === answer;
    console.log("set answer  ", answer, data.stage, isCorrect);
    // changed current for data.stage
    storageData(
      "memoria",
      data.stage,
      isCorrect,
      [answer],
      [data.answer],
      data.stage,
      0
    );
    nextScreen();
  }, [answer]);


  useEffect(() => {
    console.log("New stage: ", stage);
    const data = stages[stage];
    setAnswer("00");
    setCurrentSlide(<TemplateSlide func={setAnswer} data={data} />);
  }, [stage]);

  return (
    <div>
      {currentSlide}
    </div>
  );
};

export default Phase1Slide;
