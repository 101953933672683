/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Fab from "@material-ui/core/Fab";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import Button from "@material-ui/core/Button";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { ThemeProvider } from "@material-ui/core";
import styles from "./_styles";
import { bluePalette, normalPalette } from "../../theme";
import { getFromLS } from "../../utils";
import { UserForm } from "../Application/Forms";


import Phase1Slide from "./MemoryColor";
import Phase2Slide from "./MemoryPhase2";
import Phase3Slide from "./MemoryBinding";
import Phase4Slide from "./MemoryBinding2";
import { EndedValidationSlide } from "./EndedSlide";
import { JoinRoom } from "../Video/VideoCall";
import { FirebaseService } from "../../Services/FirebaseService";

import {
  ClickToContinueSlide,
  ExplicationSlide,
  ExplicationPhase2Slide,
  ExplicationPhase3Slide,
  ExplicationPhase4Slide,
} from "./InformationSlides";

import {
  EmptySlide,
} from "../Application/Slides/InformationSlides";


const dictLevelToStage = {
  1: "1",
  2: "2",
  3: "3",
  4: "4",
  5: "5",
  6: "6",
  8: "7",
  9: "8",
  10: "9",
  11: "10",
  12: "11",
  13: "12",
  15: "13",
  16: "14",
  17: "15",
  18: "16",
  19: "17",
  20: "18",
  22: "19",
  23: "20",
  24: "21",
  25: "22",
  26: "23",
  27: "24",
}

const useStyles = makeStyles(styles);

const ShowInfos = ({
  level, sublevel, nextLevel, previousLevel, data,
}) => {
  const classes = useStyles();
  const [currentSublevel, setCurrentSublevel] = useState(0);
  const [currentLevel, setCurrentLevel] = useState(0);
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    setCurrentLevel(level);
  }, [level]);

  useEffect(() => {
    setCurrentSublevel(sublevel);
  }, [sublevel]);

  useEffect(() => {
    let dt = {};
    if (data) { dt = data; }

    const currentProgress = {
      currentLevel: level,
      data: dt,
      currentSublevel: sublevel,
      timestamp: Date.now(),
      online: true,
    };
    console.log("currentProgress", currentProgress);
    FirebaseService.updateProgressUser(currentProgress);
  }, [level, sublevel]);

  const toggleInfoBox = () => {
    setVisible(!visible);
  };

  if (!visible) {
    return (
      <Button
        onClick={toggleInfoBox}
        variant="outlined"
        size="small"
        color="primary"
        className={classes.showhidebtn}
      >
        <VisibilityIcon />
      </Button>
    );
  }

  return (
    <>
      <div className={classes.showInfo}>
        <div className={classes.label}>
          Level:
          {" "}
          {currentLevel}
          {" "}
        </div>
        <div className={classes.label}>
          Sublevel:
          {" "}
          {currentSublevel}
          {" "}
        </div>
        <Fab
          onClick={previousLevel}
          size="small"
          color="primary"
          aria-label="previous"
          className={classes.fabbtn}
        >
          <ArrowBackIcon />
        </Fab>
        <Fab
          onClick={nextLevel}
          size="small"
          color="primary"
          aria-label="previous"
          className={classes.fabbtn}
        >
          <ArrowForwardIcon />
        </Fab>
      </div>
      <Button
        onClick={toggleInfoBox}
        variant="outlined"
        size="small"
        color="primary"
        className={classes.showhidebtn}
      >
        <VisibilityOffIcon />
      </Button>
    </>
  );
};


const schma = (level, fcNextLevel, fcNextSubLevel, setMtheme, setVideoIsVisible) => {
  // phase schema
  const phases = {
    0: {
      level: 0,
      slide: <UserForm current={level} next={fcNextLevel} />,
    },
    1: {
      level: 1,
      slide: <ExplicationSlide current={level} next={fcNextLevel} setVisibility={setVideoIsVisible} />,
    },
    2: {
      level: 2,
      slide: <Phase1Slide stage={1} current={level} nextLvl={fcNextLevel} nextSubLevel={fcNextSubLevel} />,
    },
    3: {
      level: 3,
      slide: <Phase1Slide stage={2} current={level} nextLvl={fcNextLevel} nextSubLevel={fcNextSubLevel} />,
    },
    4: {
      level: 4,
      slide: <Phase1Slide stage={3} current={level} nextLvl={fcNextLevel} nextSubLevel={fcNextSubLevel} />,
    },
    5: {
      level: 5,
      slide: <Phase1Slide stage={4} current={level} nextLvl={fcNextLevel} nextSubLevel={fcNextSubLevel} />,
    },
    6: {
      level: 6,
      slide: <Phase1Slide stage={5} current={level} nextLvl={fcNextLevel} nextSubLevel={fcNextSubLevel} />,
    },
    7: {
      level: 7,
      slide: <Phase1Slide stage={6} current={level} nextLvl={fcNextLevel} nextSubLevel={fcNextSubLevel} />,
    },
    8: {
      level: 8,
      slide: <ExplicationPhase2Slide current={level} next={fcNextLevel} />,
    },
    9: {
      level: 9,
      slide: <Phase2Slide stage={7} current={level} nextLvl={fcNextLevel} nextSubLevel={fcNextSubLevel} />,
    },
    10: {
      level: 10,
      slide: <Phase2Slide stage={8} current={level} nextLvl={fcNextLevel} nextSubLevel={fcNextSubLevel} />,
    },
    11: {
      level: 11,
      slide: <Phase2Slide stage={9} current={level} nextLvl={fcNextLevel} nextSubLevel={fcNextSubLevel} />,
    },
    12: {
      level: 12,
      slide: <Phase2Slide stage={10} current={level} nextLvl={fcNextLevel} nextSubLevel={fcNextSubLevel} />,
    },
    13: {
      level: 13,
      slide: <Phase2Slide stage={11} current={level} nextLvl={fcNextLevel} nextSubLevel={fcNextSubLevel} />,
    },
    14: {
      level: 14,
      slide: <Phase2Slide stage={12} current={level} nextLvl={fcNextLevel} nextSubLevel={fcNextSubLevel} />,
    },
    15: {
      level: 15,
      slide: <ExplicationPhase3Slide current={level} next={fcNextLevel} />,
    },
    16: {
      level: 16,
      slide: <Phase3Slide stage={13} current={level} nextLvl={fcNextLevel} nextSubLevel={fcNextSubLevel} />,
    },
    17: {
      level: 17,
      slide: <Phase3Slide stage={14} current={level} nextLvl={fcNextLevel} nextSubLevel={fcNextSubLevel} />,
    },
    18: {
      level: 18,
      slide: <Phase3Slide stage={15} current={level} nextLvl={fcNextLevel} nextSubLevel={fcNextSubLevel} />,
    },
    19: {
      level: 19,
      slide: <Phase3Slide stage={16} current={level} nextLvl={fcNextLevel} nextSubLevel={fcNextSubLevel} />,
    },
    20: {
      level: 20,
      slide: <Phase3Slide stage={17} current={level} nextLvl={fcNextLevel} nextSubLevel={fcNextSubLevel} />,
    },
    21: {
      level: 21,
      slide: <Phase3Slide stage={18} current={level} nextLvl={fcNextLevel} nextSubLevel={fcNextSubLevel} />,
    },
    22: {
      level: 22,
      slide: <ExplicationPhase4Slide current={level} next={fcNextLevel} />,
    },
    23: {
      level: 23,
      slide: <Phase4Slide stage={19} current={level} nextLvl={fcNextLevel} nextSubLevel={fcNextSubLevel} />,
    },
    24: {
      level: 24,
      slide: <Phase4Slide stage={20} current={level} nextLvl={fcNextLevel} nextSubLevel={fcNextSubLevel} />,
    },
    25: {
      level: 25,
      slide: <Phase4Slide stage={21} current={level} nextLvl={fcNextLevel} nextSubLevel={fcNextSubLevel} />,
    },
    26: {
      level: 26,
      slide: <Phase4Slide stage={22} current={level} nextLvl={fcNextLevel} nextSubLevel={fcNextSubLevel} />,
    },
    27: {
      level: 27,
      slide: <Phase4Slide stage={23} current={level} nextLvl={fcNextLevel} nextSubLevel={fcNextSubLevel} />,
    },
    28: {
      level: 28,
      slide: <Phase4Slide stage={24} current={level} nextLvl={fcNextLevel} nextSubLevel={fcNextSubLevel} />,
    },
    29: {
      level: 29,
      slide: <EndedValidationSlide current={level} next={fcNextLevel} setVisibility={setVideoIsVisible} />,
    },
    30: {
      level: 30,
      slide: <EmptySlide current={level} next={fcNextLevel} />,
    },
  };

  if (phases[level]) return phases[level];
  return phases[19];
};

// 8: {
//   level: 8,
//   slide: <ClickToContinueSlide current={level} next={fcNextLevel} />
// },

const InitialRelacional = () => {
  const [level, setLevel] = useState(0);
  const [sublevel, setSublevel] = useState(0);
  const classes = useStyles();
  const [mtheme, setMtheme] = useState("set1");
  const [videoIsVisible, setVideoIsVisible] = useState(false);
  console.log("InitialRelacional");
  useEffect(() => {
    console.log("New Level", level);
    //   setSublevel(0);
  }, [level]);

  useEffect(() => {
    console.log("New Sublevel", sublevel);
  }, [sublevel]);

  const previousLevel = () => {
    setSublevel(0);
    setLevel(level - 1);
  };

  const nextLevel = () => {
    setSublevel(0);
    setLevel(level + 1);
  };

  const crrnt = schma(level, setLevel, setSublevel, setMtheme, setVideoIsVisible).slide;

  const selectedTheme = {
    set1: normalPalette,
    set2: bluePalette,
  }

  const lastLevel = level - 1;
  const dtz = getFromLS(`memoria_level_${lastLevel}-${dictLevelToStage[lastLevel]}`);

  return (
    <ThemeProvider theme={selectedTheme[mtheme]}>
      <div className={classes.root}>
        <JoinRoom videoIsVisible={videoIsVisible} setVideoIsVisible={setVideoIsVisible} />
        <ShowInfos
          level={level}
          sublevel={sublevel}
          nextLevel={nextLevel}
          previousLevel={previousLevel}
          data={dtz}
        />
        {crrnt}
      </div>
    </ThemeProvider>
  );
};

export default InitialRelacional;
