/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import { ShowInfoOnConsole, RetensionSlide } from "../Colors/ColorSlides";
import {
    AnswerLocationSlide as AnswerSlide,
    LocationChoiceSlide as ChoiceSlide,
} from "./LocationCmpts";
import { correctAnswer, requestAgainOrNewLevel, _showConsoleInformation } from "../../../../utils";

const schema = {
    0: {},
    1: {
        timeout: "1500",
        nShapes: 1,
        correctTrials: 0,
        trials: 2,
        userAnswers: [],
        answers: [],
    },
    2: {
        timeout: "3000",
        nShapes: 2,
        correctTrials: 0,
        trials: 2,
        userAnswers: [],
        answers: [],
    },
    3: { stop: true },
};

// level here means sublevel (level = internal step)
const TrainingLocationRunning = ({ current, nextLvl, nextSubLevel }) => {
    const [level, setLevel] = useState(1);
    const [currentSlide, setCurrentSlide] = useState(null);
    const [reply, setReply] = useState();

    // getting next anwser for the same test
    useEffect(() => {
        if (reply != 0) return;
        setCurrentSlide(<AnswerSlide func={chosenColor} />);
    }, [reply]);

    const nextScreen = () => {
        nextSubLevel(0);
        nextLvl(current + 1);
    };

    const chosenColor = (timeLoaded, colorName) => {
        schema[level].userAnswers.push(colorName);
        console.log("colorName: ", colorName);
        schema[level].replies = schema[level].replies + 1;
        if (schema[level].replies < schema[level].nShapes)
            setReply(schema[level].replies);

        if (schema[level].replies === schema[level].nShapes)
            checkNextLevelOrRetry();
    };

    const checkNextLevelOrRetry = () => {
        const isCorrect = correctAnswer(
            schema[level].userAnswers,
            schema[level].answers
        );

        if (isCorrect) {
            schema[level].correctTrials += 1;
        } else {
            schema[level].wrongTrials += 1;
        }
        _showConsoleInformation("Training Binding", schema[level], isCorrect);

        requestAgainOrNewLevel("Training Binding", schema[level], level, setLevel, colorLoop);
    };

    const AnswerLoop = () => {
        schema[level].userAnswers = [];
        schema[level].replies = 0;
        setReply(0);

        setCurrentSlide(<AnswerSlide func={chosenColor} />);
    };

    const retensionLoop = () => {
        setCurrentSlide(<RetensionSlide />);
        setTimeout(() => {
            AnswerLoop();
        }, 1000);
    };

    const setAnswers = (colorName) => {
        schema[level].answers.push(colorName);
    };

    const showAnswersAndToRetension = (timeout) => {
        setTimeout(() => {
            retensionLoop();
        }, timeout);
    };


    const colorLoop = () => {
        const { timeout, nShapes } = schema[level];
        schema[level].answers = [];
        if (schema[level].stop) {
            nextScreen();
            return;
        }

        setCurrentSlide(
            <ChoiceSlide
                nShapes={nShapes}
                setAnswers={setAnswers}
                timeout={timeout}
                setRetension={showAnswersAndToRetension} />);
    };

    useEffect(() => {
        console.log("useEffect of Level", level);
        nextSubLevel(level);
        schema[level].correctTrials = 0;
        schema[level].wrongTrials = 0;

        colorLoop();
    }, [level]);

    return (
        <div>
            <ShowInfoOnConsole level={level} data={schema[level]} />
            {currentSlide}
        </div>
    );
};

export default TrainingLocationRunning;
