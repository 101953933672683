import React, { useEffect, useState } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import styles from "../_styles";
import { Checkbox } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import moment from "moment";


const useStyles = makeStyles(styles);

const TCLESlide = ({ current, next, setVisibility }) => {

  const [isaccepted, setIsaccepted] = useState(false);
  const classes = useStyles();

  const handleSubmit = () => {
    console.log("handleSubmit");
    next(current + 1);
    return "";
  };


  useEffect(() => {
    console.log("setVisibility", setVisibility);
    if (setVisibility)
      setVisibility(true);
  }, [setVisibility]);


  return (
    <div className={classes.grid}>
      <Paper
        className={classes.paper}
      >
        <div className={classes.tclebigbox}>
          <div className={classes.tclebox}>
            <b className={classes.tcletitle}>Desenvolvimento de instrumento neuropsicológico online para avaliação da memória de trabalho</b>
            <br /><br />

            A Doença de Alzheimer tem sido apontada como a causa mais comum de demência nos últimos anos, caracterizada como condição irreversível e progressiva. Algumas tarefas de memória mostraram-se úteis para a diferenciação entre envelhecimento normal e demências. A principal vantagem de se utilizar tais tarefas é a de tornar a avaliação mais rápida e sensível ao diagnóstico.
            <br /><br />
            <b>O objetivo deste estudo é desenvolver tarefas cognitivas e validá-las para estabelecer um instrumento neuropsicológico adequado e sensível a detecção da demência do tipo Alzheimer.</b> Para tanto, convidamos você a participar desta pesquisa. Sua colaboração será útil para verificarmos se a tarefa de memória desenvolvida é adequada para este diagnóstico.
            <br /><br />
            Caso esteja de acordo, é preciso saber que o estudo consiste na realização de uma avaliação neuropsicológica e de uma tarefa cognitiva computadorizada, que serão realizadas individualmente. Na tarefa cognitiva, você deverá memorizar e reconhecer formas geométricas apresentadas na tela de um notebook. Estas atividades serão realizadas em uma sessão de aproximadamente duas horas, podendo haver um intervalo para descanso. Após duas semanas, você será convidado a realizar novamente a tarefa cognitiva para verificarmos se alguma resposta mudou ao longo do tempo (estabilidade temporal).
            <br /><br />
            Não há previsão de riscos ou desconforto para os participantes da pesquisa, porém algumas pessoas podem se sentir cansadas ou incomodadas no caso de encontrarem alguma dificuldade na realização das atividades propostas. Se isso acontecer, você poderá interromper ou mesmo desistir de participar do estudo sem que isso cause qualquer problema. Você poderá receber informações sobre o andamento das atividades sempre que julgar necessário.
            <br /><br />
            Não haverá benefícios diretos para os participantes da pesquisa. <b>Os resultados obtidos pela pesquisa poderão ajudar na identificação das demências em fase inicial, especialmente a Doença de Alzheimer.</b>
            <br /><br />
            Não haverá qualquer tipo de gasto ou remuneração para os participantes da pesquisa, mas caso exista algum gasto em decorrência da pesquisa é seu direito o ressarcimento. Os dados obtidos durante o estudo serão utilizados pela pesquisadora apenas para publicações científicas, garantindo-se o sigilo com relação à identidade de todos os participantes.
            <br /><br />
            <i>
              Pesquisadora responsável:
              MSc. Karim Fachinello
              Laboratório de Psicologia Cognitiva
              FFCLRP-USP
              <br />
              Fone: (46) 99978-1217
              E-mail: karimfachinello@usp.br
            </i>
            <br /><br />
            Para eventuais dúvidas a respeito dos aspectos éticos desta pesquisa, você poderá entrar em contato com o Comitê de Ética em Pesquisa da FFCLRP-USP, localizado na Av. Bandeirantes, 3900, Bloco 1 – Prédio da Administração, Sala 07, Ribeirão Preto - SP, CEP 14040-901, Fone (16) 3315-4811, e-mail coetp@listas.ffclrp.usp.br.


          </div>
          <div className={classes.tcleacceptedbox}>
            <br />
            Fui informado(a) dos objetivos do estudo acima e os procedimentos nele envolvidos, e sei que em caso de dúvidas poderei contatar os pesquisadores responsáveis. Se está ciente de todos os termos, aceita participar da pesquisa e deseja começar, clique em “Sim”. Se não deseja continuar e não quer participar da pesquisa, clique em “Não” e a pesquisa será automaticamente finalizada e nenhum dado será registrado sem nenhum tipo de prejuízo a você.
            <br>
            </br>
            <br />
            <FormControlLabel
              control={
                <Checkbox
                  checked={isaccepted}
                  onChange={() => setIsaccepted(true)}
                />}
              label="Sim, concordo em participar desta pequisa."
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={!isaccepted}
                  onChange={() => setIsaccepted(false)}
                />}
              label="Não concordo em participar desta pequisa."
            />
            <div className={classes.tcledate}>
              <b>Dia: {
                moment(Date.now()).format("DD/MM/YYYY")
              }</b>
            </div>
            <br />
            {(isaccepted) ?
              <Button className={classes.btnbonito} color="primary" onClick={handleSubmit}>
                Continuar
              </Button> : null}


          </div>

        </div>
      </Paper>
    </div >
  );
};



const EmptySlide = ({ current, next }) => {
  const classes = useStyles();

  return (
    <div className={classes.grid}>
      <Paper
        className={classes.paper}
      >
      </Paper>
    </div >
  );
};

export { TCLESlide, EmptySlide };
