/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import {
  Template2Slide, Answer2Slide, Colors, Boxes, Triangles, Circles,
} from "./Cmpts";
import { storageData } from "../../utils";
import { RetensionSlide } from "../Application/Slides/Colors/ColorSlides";


const stages = {
  7: {
    stage: "7",
    text1: "Se pintarmos essa forma com essa cor",
    text2: "Como vai ficar?",
    Element1: <Boxes onClick={() => null} color="#c3c3c3" />,
    Element2: <Colors onClick={() => null} color="red" />,
    Element3: <Boxes onClick={() => null} color="red" />,
    Element4: <Boxes onClick={() => null} color="blue" />,
    answer: "01",
  },
  8: {
    stage: "8",
    text1: "Se pintarmos essa forma com essa cor",
    text2: "Como vai ficar?",
    Element1: <Circles onClick={() => null} color="" />,
    Element2: <Colors onClick={() => null} color="blue" />,
    Element3: <Circles onClick={() => null} color="green" />,
    Element4: <Circles onClick={() => null} color="blue" />,
    answer: "02",
  },
  9: {
    stage: "9",
    text1: "Se pintarmos essa forma com essa cor",
    text2: "Como vai ficar?",
    Element1: <Triangles onClick={() => null} color="#c3c3c3" />,
    Element2: <Colors onClick={() => null} color="green" />,
    Element3: <Circles onClick={() => null} color="green" />,
    Element4: <Triangles onClick={() => null} color="green" />,
    answer: "02",
  },
  10: {
    stage: "10",
    text1: "Se pintarmos essa forma com essa cor",
    text2: "Como vai ficar?",
    Element1: <Boxes onClick={() => null} color="" />,
    Element2: <Colors onClick={() => null} color="red" />,
    Element3: <Boxes onClick={() => null} color="red" />,
    Element4: <Circles onClick={() => null} color="red" />,
    answer: "01",
  },
  11: {
    stage: "11",
    text1: "Se pintarmos essa forma com essa cor",
    text2: "Como vai ficar?",
    Element1: <Circles onClick={() => null} color="" />,
    Element2: <Colors onClick={() => null} color="blue" />,
    Element3: <Circles onClick={() => null} color="blue" />,
    Element4: <Boxes onClick={() => null} color="red" />,
    answer: "01",
  },
  12: {
    stage: "12",
    text1: "Se pintarmos essa forma com essa cor",
    text2: "Como vai ficar?",
    Element1: <Triangles onClick={() => null} color="#c3c3c3" />,
    Element2: <Colors onClick={() => null} color="green" />,
    Element3: <Circles onClick={() => null} color="red" />,
    Element4: <Triangles onClick={() => null} color="green" />,
    answer: "02",
  },
};


const Phase2Slide = ({
  stage, current, nextLvl, nextSubLevel,
}) => {
  const [answer, setAnswer] = useState("00");
  const [currentSlide, setCurrentSlide] = useState(null);

  const delayLoop = () => {
    setTimeout(() => {
      AnswerLoop();
    }, 2000);
  };

  const AnswerLoop = () => {
    const data = stages[stage];
    setAnswer("00");
    setCurrentSlide(<Answer2Slide func={setAnswer} data={data} />);
  };

  const nextScreen = () => {
    nextSubLevel(0);
    nextLvl(current + 1);
  };

  useEffect(() => {
    console.log("useEffect ", answer);
    if (answer === "00") return;
    const data = stages[stage];

    const isCorrect = data.answer === answer;
    console.log("set answer  ", answer, data.stage, isCorrect);
    // changed current for data.stage
    storageData(
      "memoria",
      data.stage,
      isCorrect,
      [answer],
      [data.answer],
      data.stage,
      0
    );
    nextScreen();
  }, [answer]);


  const showAnswersAndToRetension = () => {
    delayLoop();
  };

  useEffect(() => {
    console.log("New stage: ", stage);
    const data = stages[stage];
    // setAnswer('00');
    setCurrentSlide(<Template2Slide func={() => { }} data={data} />);
    showAnswersAndToRetension();
  }, [stage]);

  return (
    <div>
      {currentSlide}
    </div>
  );
};

export default Phase2Slide;
