const styles = () => ({
  root: {
    height: 'calc(100%) !important',
    width: '100%',
    display: "block",
    padding: "0px !important",
  },
  content: {
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    display: "flex",
    flexGrow: 1,
  },
  contentBlur: {
    opacity: 0.2
  },
  menuButton: {
    cursor: "pointer",
    position: "fixed",
    top: "10px",
    left: "10px",
    color: "black",
    marginRight: '8px',
  },
  localeButton: {
    height: 'unset',
    background: 'unset',
    boxShadow: 'unset',
  },
  title: {
    flexGrow: 1,
  },
});

export default styles;
