/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import {
  Template4Slide,
  Answer4Slide,
  ColorsSmall,
  Colors,
  Boxes,
  Target,
  Triangles,
  Circles,
} from "./Cmpts";
import { storageData } from "../../utils";
import { RetensionSlide } from "../Application/Slides/Colors/ColorSlides";

const stages = {
  19: {
    stage: "19",
    text1: "Se colocarmos essa cor &nbsp;&nbsp;&nbsp;&nbsp; nesse local",
    text2: "Como vai ficar?",
    Element1: (
      <ColorsSmall
        style={{ width: "100%", height: "100%", position: "absolute" }}
        onClick={() => null}
        color="red"
      />
    ),
    Element2: <Target onClick={() => null} color="#c3c3c3" />,
    Element3: <Colors onClick={() => null} color="red" />,
    Element4: <Colors onClick={() => null} color="blue" />,
    class1: "targetPercepcao1",
    class2: "targetPercepcao2",
    answer: "01",
  },
  20: {
    stage: "20",
    text1: "Se colocarmos essa cor &nbsp;&nbsp;&nbsp;&nbsp; nesse local",
    text2: "Como vai ficar?",
    Element1: (
      <ColorsSmall
        style={{ width: "100%", height: "100%", position: "absolute" }}
        onClick={() => null}
        color="green"
      />
    ),
    Element2: <Target onClick={() => null} color="#c3c3c3" />,
    Element3: <Colors onClick={() => null} color="green" />,
    Element4: <Colors onClick={() => null} color="red" />,
    class1: "targetPercepcao3",
    class2: "targetPercepcao4",
    answer: "01",
  },
  21: {
    stage: "21",
    text1: "Se colocarmos essa cor &nbsp;&nbsp;&nbsp;&nbsp; nesse local",
    text2: "Como vai ficar?",
    Element1: (
      <ColorsSmall
        style={{ width: "100%", height: "100%", position: "absolute" }}
        onClick={() => null}
        color="blue"
      />
    ),
    Element2: <Target onClick={() => null} color="#c3c3c3" />,
    Element3: <Colors onClick={() => null} color="blue" />,
    Element4: <Colors onClick={() => null} color="blue" />,
    class1: "targetPercepcao5",
    class2: "targetPercepcao7",
    answer: "01",
  },
  22: {
    stage: "22",
    text1: "Se colocarmos essa cor &nbsp;&nbsp;&nbsp;&nbsp; nesse local",
    text2: "Como vai ficar?",
    Element1: (
      <ColorsSmall
        style={{ width: "100%", height: "100%", position: "absolute" }}
        onClick={() => null}
        color="green"
      />
    ),
    Element2: <Target onClick={() => null} color="#c3c3c3" />,
    Element3: <Colors onClick={() => null} color="green" />,
    Element4: <Colors onClick={() => null} color="green" />,
    class1: "targetPercepcao7",
    class2: "targetPercepcao9",
    answer: "01",
  },
  23: {
    stage: "23",
    text1: "Se colocarmos essa cor &nbsp;&nbsp;&nbsp;&nbsp; nesse local",
    text2: "Como vai ficar?",
    Element1: (
      <ColorsSmall
        style={{ width: "100%", height: "100%", position: "absolute" }}
        onClick={() => null}
        color="red"
      />
    ),
    Element2: <Target onClick={() => null} color="#c3c3c3" />,
    Element3: <Colors onClick={() => null} color="red" />,
    Element4: <Colors onClick={() => null} color="blue" />,
    class1: "targetPercepcao9",
    class2: "targetPercepcao10",
    answer: "01",
  },
  24: {
    stage: "24",
    text1: "Se colocarmos essa cor &nbsp;&nbsp;&nbsp;&nbsp; nesse local",
    text2: "Como vai ficar?",
    Element1: (
      <ColorsSmall
        style={{ width: "100%", height: "100%", position: "absolute" }}
        onClick={() => null}
        color="blue"
      />
    ),
    Element2: <Target onClick={() => null} color="#c3c3c3" />,
    Element3: <Colors onClick={() => null} color="blue" />,
    Element4: <Colors onClick={() => null} color="green" />,
    class1: "targetPercepcao11",
    class2: "targetPercepcao12",
    answer: "01",
  },
};

const Phase4Slide = ({
  stage, current, nextLvl, nextSubLevel,
}) => {
  const [answer, setAnswer] = useState("00");
  const [currentSlide, setCurrentSlide] = useState(null);

  const delayLoop = () => {
    // setCurrentSlide(<RetensionSlide />);
    setTimeout(() => {
      AnswerLoop();
    }, 2000);
  };

  const AnswerLoop = () => {
    const data = stages[stage];
    setAnswer("00");
    setCurrentSlide(<Answer4Slide func={setAnswer} data={data} />);
  };

  const nextScreen = () => {
    nextSubLevel(0);
    nextLvl(current + 1);
  };

  useEffect(() => {
    if (answer === "00") return;
    const data = stages[stage];

    const isCorrect = data.answer === answer;
    console.log("set answer  ", answer, data.stage, isCorrect);
    // changed current for data.stage
    storageData(
      "memoria",
      data.stage,
      isCorrect,
      [answer],
      [data.answer],
      data.stage,
      0,
    );
    nextScreen();
  }, [answer]);

  const showAnswersAndToRetension = () => {
    delayLoop();
  };

  useEffect(() => {
    console.log("New stage: ", stage);
    const data = stages[stage];
    setAnswer("00");
    setCurrentSlide(
      <Template4Slide func={() => { }} data={data} />,
    );
    showAnswersAndToRetension();
  }, [stage]);

  return <div>{currentSlide}</div>;
};

export default Phase4Slide;
