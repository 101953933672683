/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Fab from "@material-ui/core/Fab";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import Button from "@material-ui/core/Button";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { ThemeProvider } from "@material-ui/core";
import styles from "../_styles";
import TrainingColorRunning from "../Slides/Colors/TrainingColor";
import TrainingLocationRunning from "./../Slides/Location/TrainingLocation";
import TrainingRelacionalRunning from "../Slides/Relational/TrainingRelational";


import ApplicationLocationRunning from "./../Slides/Location/ApplicationLocation";
import ApplicationColorRunning from "../Slides/Colors/ApplicationColor";
import ApplicationRelacionalRunning from "../Slides/Relational/ApplicationRelacional";
import { UserForm, UserQuestions } from "../Forms";

import { ToGoGameSlide } from "../Slides/Colors/ColorSlides";

import { bluePalette, normalPalette } from "../../../theme";

import { JoinRoom } from "../../Video/VideoCall";

import { FirebaseService } from "../../../Services/FirebaseService";

import {
    ColorInstructionSlide,
    ParticipantSlide,
    EndedColorSlide,
} from "../Slides/Colors/ColorInformationSlides";

import {
    LocationInstructionSlide,
    EndedLocationSlide,
} from "./../Slides/Location/LocationInstructionSlides";

import {
    TCLESlide, EmptySlide,
} from "../Slides/InformationSlides";

import {
    RelacionalInstructionSlide,
    EndedRelacionalSlide,
} from "../Slides/Relational/RelationalInformationSlides";

const useStyles = makeStyles(styles);

const ShowInfos = ({ level, sublevel, nextLevel, previousLevel }) => {
    const classes = useStyles();
    const [currentSublevel, setCurrentSublevel] = useState(0);
    const [currentLevel, setCurrentLevel] = useState(0);
    const [visible, setVisible] = useState(false);

    useEffect(() => {
        setCurrentLevel(level);
    }, [level]);

    useEffect(() => {
        setCurrentSublevel(sublevel);
    }, [sublevel]);

    useEffect(() => {
        const currentProgress = {
            currentLevel: level,
            currentSublevel: sublevel,
            timestamp: Date.now(),
            online: true,
        };
        FirebaseService.updateProgressUser(currentProgress);

    }, [level, sublevel]);



    const toggleInfoBox = () => {
        setVisible(!visible);
    };

    if (!visible) {
        return (
            <Button
                onClick={toggleInfoBox}
                variant="outlined"
                size="small"
                color="primary"
                className={classes.showhidebtn}
            >
                <VisibilityIcon />
            </Button>
        );
    }

    return (
        <>
            <div className={classes.showInfo}>
                <div className={classes.label}>Level: {currentLevel} </div>
                <div className={classes.label}>Sublevel: {currentSublevel} </div>
                <Fab
                    onClick={previousLevel}
                    size="small"
                    color="primary"
                    aria-label="previous"
                    className={classes.fabbtn}
                >
                    <ArrowBackIcon />
                </Fab>
                <Fab
                    onClick={nextLevel}
                    size="small"
                    color="primary"
                    aria-label="previous"
                    className={classes.fabbtn}
                >
                    <ArrowForwardIcon />
                </Fab>
            </div>
            <Button
                onClick={toggleInfoBox}
                variant="outlined"
                size="small"
                color="primary"
                className={classes.showhidebtn}
            >
                <VisibilityOffIcon />
            </Button>
        </>
    );
};



const schma = (level, fcNextLevel, fcNextSubLevel, setMtheme, setVideoIsVisible) => {
    // phase schema
    const phases = {
        0: {
            level: 0,
            slide: <TCLESlide current={level} next={fcNextLevel} setVisibility={setVideoIsVisible} />,
        },
        1: {
            level: 1,
            slide: <UserForm current={level} next={fcNextLevel} />,
        },
        2: {
            level: 2,
            slide: <UserQuestions current={level} next={fcNextLevel} selectTheme={setMtheme} />,
        },
        3: {
            level: 3,
            slide: <ParticipantSlide bind="relacional" current={level} next={fcNextLevel} />,
        },
        4: {
            level: 4,
            slide: <ColorInstructionSlide current={level} next={fcNextLevel} setVisibility={setVideoIsVisible} />,
        },
        5: {
            level: 5,
            slide: (
                <TrainingColorRunning
                    current={level}
                    nextLvl={fcNextLevel}
                    nextSubLevel={fcNextSubLevel}
                />
            ),
        },
        6: {
            level: 6,
            slide: <ToGoGameSlide current={level} next={fcNextLevel} />,
        },
        7: {
            level: 7,
            slide: (
                <ApplicationColorRunning
                    current={level}
                    nextLvl={fcNextLevel}
                    nextSubLevel={fcNextSubLevel}
                />
            ),
        },
        8: {
            level: 8,
            slide: <EndedColorSlide bind="relacional" current={level} next={fcNextLevel} />,
        },
        9: {
            level: 9,
            slide: <LocationInstructionSlide current={level} next={fcNextLevel} />,
        },
        10: {
            level: 10,
            slide: (
                <TrainingLocationRunning
                    current={level}
                    nextLvl={fcNextLevel}
                    nextSubLevel={fcNextSubLevel}
                />
            ),
        },
        11: {
            level: 11,
            slide: <ToGoGameSlide current={level} next={fcNextLevel} />,
        },
        12: {
            level: 12,
            slide: (
                <ApplicationLocationRunning
                    current={level}
                    nextLvl={fcNextLevel}
                    nextSubLevel={fcNextSubLevel}
                />
            ),
        },
        13: {
            level: 13,
            slide: <EndedLocationSlide current={level} next={fcNextLevel} />,
        },
        14: {
            level: 14,
            slide: <RelacionalInstructionSlide current={level} next={fcNextLevel} />,
        },
        15: {
            level: 15,
            slide: (
                <TrainingRelacionalRunning
                    current={level}
                    nextLvl={fcNextLevel}
                    nextSubLevel={fcNextSubLevel}
                />
            ),
        },
        16: {
            level: 16,
            slide: <ToGoGameSlide current={level} next={fcNextLevel} />,
        },
        17: {
            level: 17,
            slide: (
                <ApplicationRelacionalRunning
                    current={level}
                    nextLvl={fcNextLevel}
                    nextSubLevel={fcNextSubLevel}
                />
            ),
        },
        18: {
            level: 18,
            slide: <EndedRelacionalSlide current={level} next={fcNextLevel} setVisibility={setVideoIsVisible} />,
        },
        19: {
            level: 19,
            slide: <EmptySlide current={level} next={fcNextLevel} />,
        },
    };

    if (phases[level]) return phases[level];
    return phases[19];
};

const InitialRelacional = () => {
    const [level, setLevel] = useState(0);
    const [sublevel, setSublevel] = useState(0);
    const classes = useStyles();
    const [mtheme, setMtheme] = useState('set1');
    const [videoIsVisible, setVideoIsVisible] = useState(false);
    console.log("InitialRelacional");
    useEffect(() => {
        console.log("New Level", level);
        //   setSublevel(0);
    }, [level]);

    useEffect(() => {
        console.log("New Sublevel", sublevel);
    }, [sublevel]);

    const previousLevel = () => {
        setSublevel(0);
        setLevel(level - 1);
    };

    const nextLevel = () => {
        setSublevel(0);
        setLevel(level + 1);
    };

    const crrnt = schma(level, setLevel, setSublevel, setMtheme, setVideoIsVisible).slide;

    const selectedTheme = {
        set1: normalPalette,
        set2: bluePalette,
    }

    return (
        <ThemeProvider theme={selectedTheme[mtheme]}>
            <div className={classes.root}>
                <JoinRoom videoIsVisible={videoIsVisible} setVideoIsVisible={setVideoIsVisible} />
                <ShowInfos
                    level={level}
                    sublevel={sublevel}
                    nextLevel={nextLevel}
                    previousLevel={previousLevel}
                />
                {crrnt}
            </div>
        </ThemeProvider>
    );
};

export default InitialRelacional;
