import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import MaterialTable from "material-table";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import IconButton from "@material-ui/core/IconButton";
import moment from "moment";
import Button from "@material-ui/core/Button";
import tableIcons from "./icons";
import renderColor from "./renderColor";
import renderLocation from "./renderLocation";
import renderRelacional from "./renderRelacional";

import { ExcelTable as RelacionalExcel } from "./relacionalExcel";

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    marginTop: "8px",
    marginLeft: "30px",
    display: "block",
    padding: "8px",
    backgroundColor: "white",
  },
  bigwidth: {
    display: "block",
    width: "2900px",
  },
  setinfo: {
    textTransform: "uppercase",
  },
  prtcpnt: {
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "12px",
    lineHeight: "26px",
    letterSpacing: "0.01em",
    textTransform: "capitalize",
  },
  trials: {
    fontStyle: "normal",
    fontSize: "11px",
    lineHeight: "18px",
    letterSpacing: "0.01em",
    textTransform: "capitalize",
  },
  t1: {
    color: "#2A8BF2",
  },
  t2: {
    color: "#2043BE",
  },
  t3: {
    color: "#FF3366",
  },
});

const useStyles = makeStyles(styles);

const createSet = (phase, currentlevel) => {
  const obj = {};
  if (phase === undefined) return obj;
  if (phase[`level-${currentlevel}-1`]) {
    obj[`level-${currentlevel}-1`] = phase[`level-${currentlevel}-1`];
  }
  if (phase[`level-${currentlevel}-2`]) {
    obj[`level-${currentlevel}-2`] = phase[`level-${currentlevel}-2`];
  }
  if (phase[`level-${currentlevel}-3`]) {
    obj[`level-${currentlevel}-3`] = phase[`level- ${currentlevel} - 3`];
  }
  return obj;
};

const renderParticipantInfo = (classes, rowData) => {
  let dt = "";
  if (rowData.userInfo.datetime) {
    dt = moment(rowData.userInfo.datetime).format("hh:mm:ss DD/MM/YYYY");
  }

  return (
    <div className={classes.prtcpnt}>
      {rowData.userInfo.name}
      <br />
      <b>Data:</b> {dt}
      <br />
      <b>RG:</b> {rowData.userInfo.rg}
      <br />
      <b>Código:</b> {rowData.userInfo.code}
      <br />
      {rowData.userInfo.gender}
      <br />
      {rowData.userInfo.handedness}
      <br />
      <b>Idade:</b>
      {rowData.userInfo.age}
      <br />
      <b className={classes.setinfo}>{rowData.userInfo.set}</b>
      <br />
      <br />
      <b>Questão 1:</b>
      {rowData.userInfo.question1} - {rowData.userInfo.textquestion1}
      <br />
      <b>Questão 2:</b>
      {rowData.userInfo.question2} - {rowData.userInfo.textquestion2}
    </div>
  );
};

const DataTable = ({ data, openDeleteBox }) => {
  const classes = useStyles();

  const [showTable, setShowTable] = useState(false);
  const [rawData, setRawData] = useState([]);

  useEffect(() => {
    const data2 = [];
    data.forEach((element) => {
      const el2 = {};
      el2.userInfo = {
        datetime: element.datetime,
        ...element.userInfo,
        ...element.questions,
      };
      el2.key = element.key;
      el2.colors1 = createSet(element.colors, 1);
      el2.colors2 = createSet(element.colors, 2);
      el2.colors3 = createSet(element.colors, 3);
      el2.colors4 = createSet(element.colors, 4);
      el2.location1 = createSet(element.location, 1);
      el2.location2 = createSet(element.location, 2);
      el2.location3 = createSet(element.location, 3);
      el2.location4 = createSet(element.location, 4);
      el2.relacional1 = createSet(element.relacional, 1);
      el2.relacional2 = createSet(element.relacional, 2);
      el2.relacional3 = createSet(element.relacional, 3);
      el2.relacional4 = createSet(element.relacional, 4);
      data2.push(el2);
    });
    console.log("data2", data2);
    setRawData(data2);
  }, [data]);

  const columns = [
    {
      title: "Opções ",
      field: "key",
      render: (rowData) => (
        <IconButton
          onClick={() => openDeleteBox(rowData, "relacional")}
          color="primary"
          aria-label="upload picture"
          component="span">
          <DeleteForeverIcon />
        </IconButton>
      ),
    },
    {
      title: "Participante",
      field: "userInfo",
      render: (rowData) => renderParticipantInfo(classes, rowData),
    },
    {
      title: "Fase Cor - 1 Cor",
      field: "colors1",
      render: (rowData) => renderColor(classes, rowData.colors1, 1),
    },
    {
      title: "Fase Cor - 2 Cores",
      field: "colors2",
      render: (rowData) => renderColor(classes, rowData.colors2, 2),
    },
    {
      title: "Fase Cor - 3 Cores",
      field: "colors3",
      render: (rowData) => renderColor(classes, rowData.colors3, 3),
    },
    {
      title: "Fase Cor - 4 Cores",
      field: "colors4",
      render: (rowData) => renderColor(classes, rowData.colors4, 4),
    },

    {
      title: "Fase Localização - 1 ",
      field: "location1",
      render: (rowData) => renderLocation(classes, rowData.location1, 1),
    },
    {
      title: "Fase Localização - 2 ",
      field: "location2",
      render: (rowData) => renderLocation(classes, rowData.location2, 2),
    },
    {
      title: "Fase Localização - 3 ",
      field: "location3",
      render: (rowData) => renderLocation(classes, rowData.location3, 3),
    },
    {
      title: "Fase Localização - 4 ",
      field: "location4",
      render: (rowData) => renderLocation(classes, rowData.location4, 4),
    },
    {
      title: "Fase Relacional - 1 ",
      field: "relacional1",
      render: (rowData) => renderRelacional(classes, rowData.relacional1, 1),
    },
    {
      title: "Fase Relacional - 2 ",
      field: "relacional2",
      render: (rowData) => renderRelacional(classes, rowData.relacional2, 2),
    },
    {
      title: "Fase Relacional - 3 ",
      field: "relacional3",
      render: (rowData) => renderRelacional(classes, rowData.relacional3, 3),
    },
    {
      title: "Fase Relacional - 4 ",
      field: "relacional4",
      render: (rowData) => renderRelacional(classes, rowData.relacional4, 4),
    },
  ];

  return (
    <div className={classes.root}>
      <div className={classes.bigwidth}>
        {!showTable && (
          <Button
            style={{ position: "relative" }}
            onClick={() => setShowTable(true)}
            color="secondary">
            Mostrar Tabela de Relacional
          </Button>
        )}
        {showTable && (
          <Button
            style={{ position: "relative" }}
            onClick={() => setShowTable(false)}
            color="secondary">
            Esconder Tabela de Relacional
          </Button>
        )}
        &nbsp; &nbsp; &nbsp; &nbsp;
        <RelacionalExcel title="relacional" data={rawData} />
        {showTable && (
          <MaterialTable
            components={{
              Container: (props) => props.children,
            }}
            icons={tableIcons}
            title="Resultados do Binding Relacional"
            columns={columns}
            data={rawData}
          />
        )}
      </div>
    </div>
  );
};

export { DataTable };
