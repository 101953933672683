/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import { ReactComponent as Sone } from '../../../../Shapes/003.svg';
import { ReactComponent as Stwo } from '../../../../Shapes/004.svg';
import { ReactComponent as Sthree } from '../../../../Shapes/005.svg';
import { ReactComponent as Sfour } from '../../../../Shapes/006.svg';
import { ReactComponent as Sfive } from '../../../../Shapes/007.svg';
import { ReactComponent as Ssix } from '../../../../Shapes/008.svg';
import styles from '../../_styles';
import { Shapes, EmptyShape } from '../Shapes/ShapesCmpts';
import { Colors, ClickToContinueSlide } from '../Colors/ColorSlides';

const useStyles = makeStyles(styles);

const BindingShape = ({ number, color }) => {
  const classes = useStyles();
  if (number === 1) {
    return <Sone className={`${classes.shape} ${classes[`sp_${color}`]}`} />;
  }
  if (number === 2) {
    return <Stwo className={`${classes.shape} ${classes[`sp_${color}`]}`} />;
  }
  if (number === 3) {
    return <Sthree className={`${classes.shape} ${classes[`sp_${color}`]}`} />;
  }
  if (number === 4) {
    return <Sfour className={`${classes.shape} ${classes[`sp_${color}`]}`} />;
  }
  if (number === 5) {
    return <Sfive className={`${classes.shape} ${classes[`sp_${color}`]}`} />;
  }
  if (number === 6) {
    return <Ssix className={`${classes.shape} ${classes[`sp_${color}`]}`} />;
  }
  return <Sone className={classes.shape} />;
};

const AnswerBindingSlide = ({ func, isInverted = false }) => {
  const timeLoaded = Date.now();
  const classes = useStyles();
  const [isHead, setIsHead] = useState(false);
  const [answeredList, setAnsweredList] = useState([]);

  const clickedIn = (headClicked, value) => {
    if (isHead !== headClicked) {
      console.log('Miss Clcick!');
      return '';
    }
    setAnsweredList([...answeredList, value]);
    func(timeLoaded, value);
    setIsHead(!isHead);
  };

  const myclick = (coin, element) => {
    clickedIn(coin, element);
  };

  const createShapes = (element) => {
    if (answeredList.includes(element)) {
      return <EmptyShape />;
    }
    return <Shapes onClick={() => myclick(true, element)} number={element} />;
  };

  const createColors = (element) => {
    if (answeredList.includes(element)) {
      return <Colors color='gray' />;
    }
    return <Colors onClick={() => myclick(false, element)} color={element} />;
  };

  const createAllColors = () => {
    if (isInverted) {

      return (
        <div className={`${!isHead ? classes.pointer : ''} ${classes.divColors}`}>
          {createColors('red')}
          {createColors('green')}
          {createColors('yellow')}
          {createColors('blue')}
          {createColors('lightblue')}
          {createColors('pink')}
        </div>);
    }
    return (<div className={`${!isHead ? classes.pointer : ''} ${classes.divColors}`}>
      {createColors("pink")}
      {createColors("lightblue")}
      {createColors("blue")}
      {createColors("yellow")}
      {createColors("green")}
      {createColors("red")}
    </div>);
  }


  return (
    <div className={classes.grid}>
      <Paper className={classes.paper3}>
        {createAllColors()}
        <div
          className={`${isHead ? classes.pointer : ''} ${classes.divShapes}`}>
          {createShapes(1)}
          {createShapes(2)}
          {createShapes(3)}
          {createShapes(4)}
          {createShapes(5)}
          {createShapes(6)}
        </div>
      </Paper>
    </div>
  );
};

const BindingChoiceSlide = ({ nShapes, setAnswers, setRetension, timeout }) => {
  const classes = useStyles();
  const [waiting, setWaiting] = useState(true);

  const listEl = [];
  const shapeIndex = [1, 2, 3, 4, 5, 6];
  const colorIndex = ['red', 'green', 'yellow', 'blue', 'lightblue', 'pink'];

  useEffect(() => {
    if (!waiting) {
      setTimeout(() => {
        setRetension();
      }, timeout);
    }
  }, [waiting]);

  if (waiting) {
    return (
      <ClickToContinueSlide setWaiting={setWaiting}> </ClickToContinueSlide>
    );
  }

  for (let i = 1; i <= nShapes; i++) {
    const index = Math.floor(Math.random() * shapeIndex.length);
    const shapeName = shapeIndex[index];
    shapeIndex.splice(index, 1);
    const index2 = Math.floor(Math.random() * colorIndex.length);
    const colorName = colorIndex[index2];
    colorIndex.splice(index2, 1);

    const el = (
      <div key={i} className={classes[`location${i}of${nShapes}`]}>
        <BindingShape number={shapeName} color={colorName} />
      </div>
    );

    listEl.push(el);
    setAnswers(shapeName);
    setAnswers(colorName);
  }

  return (
    <div className={classes.grid}>
      <Paper className={classes.paper}>{listEl}</Paper>
    </div>
  );
};

export { AnswerBindingSlide, BindingChoiceSlide };
