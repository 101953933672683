import firebase from "firebase";

const prodConfig = {
  apiKey: "AIzaSyDl7JAk1i4VnxnU-fWufO6qXYjA1v59hBc",
  authDomain: "workingmemory-test.firebaseapp.com",
  databaseURL: "https://workingmemory-test.firebaseio.com",
  projectId: "workingmemory-test",
  storageBucket: "workingmemory-test.appspot.com",
  messagingSenderId: "756459823362",
  appId: "1:756459823362:web:dd705f44dbdd97e7a7621b",
  measurementId: "G-NJQ6PV9X3R",
};
const devConfig = {
  apiKey: "AIzaSyDl7JAk1i4VnxnU-fWufO6qXYjA1v59hBc",
  authDomain: "workingmemory-test.firebaseapp.com",
  databaseURL: "https://workingmemory-test.firebaseio.com",
  projectId: "workingmemory-test",
  storageBucket: "workingmemory-test.appspot.com",
  messagingSenderId: "756459823362",
  appId: "1:756459823362:web:dd705f44dbdd97e7a7621b",
  measurementId: "G-NJQ6PV9X3R",
};

const config = process.env.NODE_ENV === "production" ? prodConfig : devConfig;

// Initialize Firebase
export const firebaseImpl = firebase.initializeApp(config);

export const firebaseDatabase = firebase.database();
// firebase.analytics();
// export const firebaseDatabase = firebase.storage();

console.log("-----", firebase.app().name);
