import React, { useState } from 'react'
import { makeStyles } from "@material-ui/core/styles";
import { createPortal } from 'react-dom'
import styles from "./_styles";

const useStyles = makeStyles(styles);

const IFrame = ({ children, ...props }) => {

    const classes = useStyles();

    const [contentRef, setContentRef] = useState(null)
    const mountNode = contentRef && contentRef.contentWindow.document.body

    return (
        <iframe className={classes.root} width="100%" height="100%" {...props} ref={setContentRef} allow="camera *;microphone *">
            {mountNode &&
                createPortal(
                    React.Children.only(children),
                    mountNode
                )}
        </iframe>
    )
}

export default IFrame;
