const styles = (theme) => ({
  textBase: {
    fontSize: theme.typography.fontSize,
    textTransform: "uppercase",
    fontWeight: theme.typography.fontWeight,
    lineHeight: "31px",
    textAlign: "center",
    fontFamily: theme.typography.fontFamily,
  },
  noMouse:
  {
    cursor: "none",
  },
  textPos1: {
    top: "50px",
    left: "200px",
    width: "600px",
    position: "absolute",
  },
  textPos2: {
    top: "300px",
    left: "200px",
    width: "600px",
    position: "absolute",
  },
  textPos2Boxes: {
    top: "  350px",
    left: "200px",
    width: "600px",
    position: "absolute",
  },
  boxesCenter1: {
    top: "150px",
    left: "200px",
    position: "absolute",
    width: "600px",
  },
  boxesCenter2: {
    top: "400px",
    left: "200px",
    position: "absolute",
    width: "600px",
  },
  targetPercepcao1: {
    top: "200px",
    right: "100px",
    position: "absolute",
  },
  targetPercepcao2: {
    top: "200px",
    right: "200px",
    position: "absolute",
  },
  targetPercepcao3: {
    top: "250px",
    left: "400px",
    position: "absolute",
  },
  targetPercepcao4: {
    top: "250px",
    left: "500px",
    position: "absolute",
  },
  targetPercepcao5: {
    top: "180px",
    left: "100px",
    position: "absolute",
  },
  targetPercepcao6: {
    top: "180px",
    left: "200px",
    position: "absolute",
  },
  targetPercepcao7: {
    top: "200px",
    left: "400px",
    position: "absolute",
  },
  targetPercepcao8: {
    top: "200px",
    left: "300px",
    position: "absolute",
  },
  targetPercepcao9: {
    top: "300px",
    left: "150px",
    position: "absolute",
  },
  targetPercepcao10: {
    top: "100px",
    right: "200px",
    position: "absolute",
  },
  targetPercepcao11: {
    top: "200px",
    right: "150px",
    position: "absolute",
  },
  targetPercepcao12: {
    top: "100px",
    right: "300px",
    position: "absolute",
  },
  boxesCenterBorder1: {
    top: "130px",
    left: "200px",
    display: "block",
    width: "600px",
    border: "1px solid black",
    position: "absolute",
    height: "200px",
    paddingTop: "30px",
  },
  boxesCenterBorder2: {
    top: "400px",
    left: "200px",
    display: "block",
    border: "1px solid black",
    position: "absolute",
    width: "600px",
    height: "200px",
    paddingTop: "30px",
  },
  boxBorderLeft: {
    left: "10px",
    position: "absolute",
  },
  targetMemory1: {
    left: "150px",
    position: "absolute",
  },
  "targetMemory1.5": {
    left: "195px",
    position: "absolute",
  },
  targetMemory2: {
    left: "235px",
    position: "absolute",
  },
  targetMemory3: {
    top: "20px",
    left: "150px",
    position: "absolute",
  },
  "targetMemory3.5": {
    top: "20px",
    left: "100px",
    position: "absolute",
  },
  targetMemory4: {
    top: "20px",
    left: "50px",
    position: "absolute",
  },
  targetMemory5: {
    top: "10px",
    right: "-31px",
    position: "absolute",
  },
  targetMemory6: {
    top: "50px",
    right: "250px",
    position: "absolute",
  },
  targetMemory7: {
    top: "30px",
    left: "20px",
    position: "absolute",
  },
  targetMemory8: {
    top: "10px",
    right: "0px",
    position: "absolute",
  },
  targetMemory9: {
    top: "10px",
    right: "20px",
    position: "absolute",
  },
  targetMemory10: {
    top: "30px",
    right: "150px",
    position: "absolute",
  },
  targetMemory11: {
    top: "30px",
    left: "200px",
    position: "absolute",
  },
  targetMemory12: {
    top: "20px",
    left: "100px",
    position: "absolute",
  },
  boxBorderRight: {
    right: "10px",
    position: "absolute",
  },
  boxLeft: {
    left: "0px",
    position: "absolute",
  },
  boxRight: {
    right: "0px",
    position: "absolute",
  },
  root: {
    display: "block",
    flexGrow: 1,
    height: "100vh",
    minHeight: "720px",
    width: "100%",
    padding: "0px",
    background: "#c3c3c3",
  },
  pointer: {
    cursor: "pointer",
  },
  showhidebtn: {
    border: "none",
    boxShadow: "none",
    right: "0px",
    top: "150px",
    color: "#2d7c96",
  },
  fabbtn: {
    margin: 10,
  },
  grid: {
    height: 720,
    width: 1000,
    padding: 0,
    margin: "auto",
  },
  showInfo: {
    textAlign: "center",
    padding: "20px",
    top: "0px",
    right: "0px",
    boxShadow: "0 3px 5px 2px rgba(105, 137, 255, 0.3)",
    height: "auto",
    width: "200px",
    "z-index": "10",
    background: "#0e6d8a",
    position: "fixed",
  },
  label: {
    display: "block",
    fontSize: "14px",
    color: "white",
    fontWeight: "700",
  },
  shape: {
    height: 100,
    width: 100,
    "& > g": {
      transform: "translate(-293px,2188px) scale(0.148, -0.148) !important",
      stroke: "#544d4d",
      fill: "#c3c3c3",
      strokeWidth: "600px",
    },
  },
  splash: {
    height: 100,
    width: 100,
  },
  smallsplash: {
    height: 50,
    width: 50,
  },
  box: {
    border: "2px solid black",
    height: "100%",
    width: "100%",
  },
  circle: {
    border: "2px solid black",
    borderRadius: "50%",
    height: "100%",
    width: "100%",
  },
  smallshapes: {
    width: "50px",
    height: "50px",
    margin: "0px 32px",
    display: "inline-block",
  },
  shapes: {
    height: 100,
    width: 100,
    marginRight: 60,
    display: "inline-block",
  },
  colors: {
    height: 100,
    width: 100,
    marginRight: 60,
    display: "inline-block",
    border: "4px #544d4d solid",
    borderRadius: "5px",
  },
  textCenter: {
    top: "150px",
    left: "200px",
    position: "absolute",
    fontSize: theme.typography.fontSize,
    textTransform: "uppercase",
    width: "600px",
    fontWeight: theme.typography.fontWeight,
    lineHeight: "31px",
    textAlign: "center",
    fontFamily: theme.typography.fontFamily,
  },
  clickToContinue: {
    paddingTop: "100px",
  },
  divShapes: {
    display: "inline-block",
    width: "100%",
  },
  divColors: {
    paddingTop: "50px",
    display: "inline-block",
    width: "100%",
  },
  paper3: {
    height: "100%",
    width: "100%",
    paddingTop: "220px",
    paddingLeft: "40px",
    position: "relative",
    boxShadow: "none",
    background: "#c3c3c3",
  },
  paper2: {
    height: "100%",
    width: "100%",
    paddingTop: "100px",
    boxShadow: "none",
    paddingLeft: "40px",
    position: "relative",
    background: "#c3c3c3",
  },
  paper: {
    height: "100%",
    width: "100%",
    paddingTop: "300px",
    boxShadow: "none",
    paddingLeft: "40px",
    position: "relative",
    background: "#c3c3c3",
  },
  red: {
    width: "100%",
    height: "100%",
    background: theme.palette.red,
  },
  green: {
    width: "100%",
    height: "100%",
    background: theme.palette.green,
  },
  yellow: {
    width: "100%",
    height: "100%",
    background: theme.palette.yellow,
  },
  blue: {
    width: "100%",
    height: "100%",
    background: theme.palette.blue,
  },
  lightblue: {
    width: "100%",
    height: "100%",
    background: theme.palette.lightblue,
  },
  pink: {
    width: "100%",
    height: "100%",
    background: theme.palette.pink,
  },
  sp_red: {
    fill: theme.palette.red,
    "& > g": {
      fill: theme.palette.red,
    },
  },
  sp_green: {
    fill: theme.palette.green,
    "& > g": {
      fill: theme.palette.green,
    },
  },
  sp_yellow: {
    fill: theme.palette.yellow,
    "& > g": {
      fill: theme.palette.yellow,
    },
  },
  sp_blue: {
    fill: theme.palette.blue,
    "& > g": {
      fill: theme.palette.blue,
    },
  },
  sp_lightblue: {
    fill: theme.palette.lightblue,
    "& > g": {
      fill: theme.palette.lightblue,
    },
  },
  sp_pink: {
    fill: theme.palette.pink,
    "& > g": {
      fill: theme.palette.pink,
    },
  },
  location3of4: {
    top: "500px",
    left: "300px",
    position: "absolute",
  },
  location4of4: {
    top: "500px",
    left: "600px",
    position: "absolute",
  },
});

export default styles;
