const menuPT = {
  results: "Resultados",
  conjuncao: "Conjunção",
  relacional: "Relacional",
  binding: "Binding",
  softbinding: "SoftBinding",
  test: "Iniciar Teste",
  validation: "Percepção e Memória",
  video: "Abrir Video",
};

const menuEN = {
  results: "Resultados",
  conjuncao: "Conjunção",
  relacional: "Relacional",
  binding: "Binding",
  softbinding: "SoftBinding",
  test: "Iniciar Teste",
  validation: "Percepção e Memória",
  video: "Abrir Video"
};

export default { menuPT, menuEN };
