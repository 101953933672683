/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import {
  Template3Slide, ColorsSmall, Colors, Boxes, Target, Triangles, Circles,
} from "./Cmpts";
import { storageData } from "../../utils";


const stages = {
  13: {
    stage: "13",
    text1: "Se colocarmos essa cor &nbsp;&nbsp;&nbsp;&nbsp; nesse local do retângulo",
    text2: "Como vai ficar?",
    Element1: <ColorsSmall style={{ width: "100%", height: "100%", position: "absolute" }} onClick={() => null} color="green" />,
    Element2: <Target onClick={() => null} color="#c3c3c3" />,
    Element3: <Colors onClick={() => null} color="green" />,
    Element4: <Colors onClick={() => null} color="red" />,
    answer: "01",
    classbase: "targetMemory1.5",
    class1: "targetMemory1",
    class2: "targetMemory2",
  },
  14: {
    stage: "14",
    text1: "Se colocarmos essa cor &nbsp;&nbsp;&nbsp;&nbsp; nesse local do retângulo",
    text2: "Como vai ficar?",
    Element1: <ColorsSmall style={{ width: "100%", height: "100%", position: "absolute" }} onClick={() => null} color="blue" />,
    Element2: <Target onClick={() => null} color="#c3c3c3" />,
    Element3: <Colors onClick={() => null} color="blue" />,
    Element4: <Colors onClick={() => null} color="red" />,
    answer: "01",
    classbase: "targetMemory3.5",
    class1: "targetMemory3",
    class2: "targetMemory4",
  },
  15: {
    stage: "15",
    text1: "Se colocarmos essa cor &nbsp;&nbsp;&nbsp;&nbsp; nesse local do retângulo",
    text2: "Como vai ficar?",
    Element1: <ColorsSmall style={{ width: "100%", height: "100%", position: "absolute" }} onClick={() => null} color="red" />,
    Element2: <Target onClick={() => null} color="#c3c3c3" />,
    Element3: <Colors onClick={() => null} color="red" />,
    Element4: <Colors onClick={() => null} color="red" />,
    answer: "01",
    classbase: "targetMemory5",
    class1: "targetMemory5",
    class2: "targetMemory6",
  },
  16: {
    stage: "16",
    text1: "Se colocarmos essa cor &nbsp;&nbsp;&nbsp;&nbsp; nesse local do retângulo",
    text2: "Como vai ficar?",
    Element1: <ColorsSmall style={{ width: "100%", height: "100%", position: "absolute" }} onClick={() => null} color="green" />,
    Element2: <Target onClick={() => null} color="#c3c3c3" />,
    Element3: <Colors onClick={() => null} color="green" />,
    Element4: <Colors onClick={() => null} color="green" />,
    answer: "01",
    classbase: "targetMemory7",
    class1: "targetMemory7",
    class2: "targetMemory8",
  },
  17: {
    stage: "17",
    text1: "Se colocarmos essa cor &nbsp;&nbsp;&nbsp;&nbsp; nesse local do retângulo",
    text2: "Como vai ficar?",
    Element1: <ColorsSmall style={{ width: "100%", height: "100%", position: "absolute" }} onClick={() => null} color="red" />,
    Element2: <Target onClick={() => null} color="#c3c3c3" />,
    Element3: <Colors onClick={() => null} color="red" />,
    Element4: <Colors onClick={() => null} color="green" />,
    answer: "01",
    classbase: "targetMemory9",
    class1: "targetMemory9",
    class2: "targetMemory10",
  },
  18: {
    stage: "18",
    text1: "Se colocarmos essa cor &nbsp;&nbsp;&nbsp;&nbsp; nesse local do retângulo",
    text2: "Como vai ficar?",
    Element1: <ColorsSmall style={{ width: "100%", height: "100%", position: "absolute" }} onClick={() => null} color="green" />,
    Element2: <Target onClick={() => null} color="#c3c3c3" />,
    Element3: <Colors onClick={() => null} color="green" />,
    Element4: <Colors onClick={() => null} color="blue" />,
    answer: "01",
    classbase: "targetMemory11",
    class1: "targetMemory11",
    class2: "targetMemory12",
  },
};


const Phase3Slide = ({
  stage, current, nextLvl, nextSubLevel,
}) => {
  const [answer, setAnswer] = useState("00");
  const [currentSlide, setCurrentSlide] = useState(null);

  const nextScreen = () => {
    nextSubLevel(0);
    nextLvl(current + 1);
  };

  useEffect(() => {
    if (answer === "00") return;
    const data = stages[stage];

    const isCorrect = data.answer === answer;
    console.log("set answer  ", answer, data.stage, isCorrect);
    // changed current for data.stage
    storageData(
      "memoria",
      data.stage,
      isCorrect,
      [answer],
      [data.answer],
      data.stage,
      0
    );
    nextScreen();
  }, [answer]);


  useEffect(() => {
    const data = stages[stage];
    setAnswer("00");
    setCurrentSlide(<Template3Slide func={setAnswer} data={data} />);
  }, [stage]);


  console.log("Phase3Slide ", answer);

  return (
    <div>
      {currentSlide}
    </div>
  );
};


export default Phase3Slide;
