const styles = (theme) => ({
  tclebigbox: {
    top: '20px',
    left: '100px',
    position: 'absolute',
    width: '700px',
    lineHeight: '25px',
    textAlign: 'center',
    fontFamily: theme.typography.fontFamily,
  },
  textquestion1: {
    width: '300px',
  },
  tclebox: {
    background: 'white',
    padding: '20px',
    textTransform: 'none',
    fontWeight: 'normal',
    fontSize: '17px',
    height: '230px',
    overflow: 'scroll',
  },
  tcledate: {
    fontSize: '15px',
  },
  tcleacceptedbox: {
    textTransform: 'none',
    fontWeight: 'normal',
    fontSize: '16px',
  },
  tcletitle: {
    fontSize: '18px',
  },

  root: {
    display: 'block',
    flexGrow: 1,
    height: '100vh',
    minHeight: '720px',
    width: '100%',
    padding: '0px',
    background: '#c3c3c3',
  },
  btnbonito: {
    background: 'linear-gradient(45deg, #ffffff 30%, #f3ebe7 90%)',
    border: 0,
    borderRadius: 3,
    boxShadow: '0 3px 5px 2px  rgba(99, 91, 93, 0.3)',
    cursor: 'pointer',
    height: 48,
    color: '#2c4c56',
    fontSize: '15px',
    width: '200px',
    position: 'relative',
    fontWeight: 'bold',
    padding: '8px',
  },
  showhidebtn: {
    border: 'none',
    boxShadow: 'none',
    right: '0px',
    top: '150px',
    color: '#2d7c96',
  },
  fabbtn: {
    margin: 10,
  },
  grid: {
    height: 720,
    width: 1000,
    padding: 0,
    margin: 'auto',
  },
  pointer: {
    cursor: 'pointer',
  },
  formTextAlign: {
    top: '30px',
    left: '350px',
    position: 'absolute',
    fontSize: theme.typography.fontSize,
    width: '600px',
    fontWeight: theme.typography.fontWeight,
    lineHeight: '25px',
    fontFamily: theme.typography.fontFamily,
  },
  textCenter: {
    top: '150px',
    left: '200px',
    position: 'absolute',
    fontSize: theme.typography.fontSize,
    textTransform: 'uppercase',
    width: '600px',
    fontWeight: theme.typography.fontWeight,
    lineHeight: '31px',
    textAlign: 'center',
    fontFamily: theme.typography.fontFamily,
  },
  clickToContinue: {
    paddingTop: '100px',
  },
  showInfo: {
    textAlign: 'center',
    padding: '20px',
    top: '0px',
    right: '0px',
    boxShadow: '0 3px 5px 2px rgba(105, 137, 255, 0.3)',
    height: 'auto',
    width: '200px',
    'z-index': '10',
    background: '#0e6d8a',
    position: 'fixed',
  },
  label: {
    display: 'block',
    fontSize: '14px',
    color: 'white',
    fontWeight: '700',
  },
  shape: {
    height: 100,
    width: 100,
    '& > g': {
      transform: 'translate(-293px,2188px) scale(0.148, -0.148) !important',
      stroke: '#544d4d',
      fill: '#c3c3c3',
      strokeWidth: '600px',
    },
  },
  splash: {
    height: 100,
    width: 100,
  },
  smallShape: {
    height: 20,
    width: 20,
    '& > g': {
      transform: 'translate(-293px,2188px) scale(0.148, -0.148) !important',
      stroke: '#544d4d',
      fill: '#c3c3c3',
      strokeWidth: '1000px',
    },
  },

  smallShapes: {
    height: 20,
    width: 20,
    position: 'absolute',
    display: 'inline-block',
  },
  shapes: {
    height: 100,
    width: 100,
    marginRight: 60,
    display: 'inline-block',
  },
  colors: {
    height: 100,
    width: 100,
    marginRight: 60,
    display: 'inline-block',
    border: '4px #544d4d solid',
    borderRadius: '5px',
  },
  divShapes: {
    paddingTop: '50px',
    display: 'inline-block',
    width: '100%',
  },
  divColors: {
    display: 'inline-block',
    width: '100%',
  },
  paper3: {
    height: '100%',
    width: '100%',
    paddingTop: '220px',
    paddingLeft: '40px',
    position: 'relative',
    boxShadow: 'none',
    background: '#c3c3c3',
  },
  paper2: {
    height: '100%',
    width: '100%',
    paddingTop: '100px',
    boxShadow: 'none',
    paddingLeft: '40px',
    position: 'relative',
    background: '#c3c3c3',
  },
  paper: {
    height: '100%',
    width: '100%',
    paddingTop: '300px',
    boxShadow: 'none',
    paddingLeft: '40px',
    position: 'relative',
    background: '#c3c3c3',
  },
  red: {
    width: '100%',
    height: '100%',
    background: theme.palette.red,
  },
  green: {
    width: '100%',
    height: '100%',
    background: theme.palette.green,
  },
  yellow: {
    width: '100%',
    height: '100%',
    background: theme.palette.yellow,
  },
  blue: {
    width: '100%',
    height: '100%',
    background: theme.palette.blue,
  },
  lightblue: {
    width: '100%',
    height: '100%',
    background: theme.palette.lightblue,
  },
  pink: {
    width: '100%',
    height: '100%',
    background: theme.palette.pink,
  },
  gray: {
    width: '100%',
    height: '100%',
    background: theme.palette.gray,
  },
  sp_red: {
    fill: theme.palette.red,
    '& > g': {
      fill: theme.palette.red,
    },
  },
  sp_green: {
    fill: theme.palette.green,
    '& > g': {
      fill: theme.palette.green,
    },
  },
  sp_yellow: {
    fill: theme.palette.yellow,
    '& > g': {
      fill: theme.palette.yellow,
    },
  },
  sp_blue: {
    fill: theme.palette.blue,
    '& > g': {
      fill: theme.palette.blue,
    },
  },
  sp_lightblue: {
    fill: theme.palette.lightblue,
    '& > g': {
      fill: theme.palette.lightblue,
    },
  },
  sp_pink: {
    fill: theme.palette.pink,
    '& > g': {
      fill: theme.palette.pink,
    },
  },
  sp_gray: {
    fill: theme.palette.gray,
    '& > g': {
      fill: theme.palette.gray,
    },
  },
  location1of1: {
    top: '320px',
    left: '450px',
    position: 'absolute',
  },
  location1of2: {
    top: '320px',
    left: '300px',
    position: 'absolute',
  },
  location2of2: {
    top: '320px',
    left: '600px',
    position: 'absolute',
  },
  location1of3: {
    top: '500px',
    left: '300px',
    position: 'absolute',
  },
  location2of3: {
    top: '500px',
    left: '600px',
    position: 'absolute',
  },
  location3of3: {
    top: '200px',
    left: '450px',
    position: 'absolute',
  },
  location1of4: {
    top: '200px',
    left: '300px',
    position: 'absolute',
  },
  location2of4: {
    top: '200px',
    left: '600px',
    position: 'absolute',
  },
  location3of4: {
    top: '500px',
    left: '300px',
    position: 'absolute',
  },
  location4of4: {
    top: '500px',
    left: '600px',
    position: 'absolute',
  },
  subBox3: {
    position: 'absolute',
    top: '-40px',
  },
  subBox4: {
    top: '80px',
    position: 'absolute',
  },
  subBox2: {
    top: '80px !important',
  },
  instBox2: {
    paddingTop: '60px',
  },
  instBox: {
    height: '150px',
    width: '220px',
    position: 'relative',
    border: '4px #544d4d solid',
    borderRadius: '5px',
    margin: '20px',
  },
  instText: {
    fontSize: '17px',
    paddingBottom: '20px',
    textTransform: 'uppercase',
    width: '600px',
    fontWeight: theme.typography.fontWeight,
    lineHeight: '31px',
  },
  littleBox: {
    width: '20px',
    height: '20px',
    border: '1px #544d4d solid',
    borderRadius: '5px',
    top: '60px',
    position: 'absolute',
  },
  lbrandom1: {
    position: 'absolute',
    top: '50px',
    left: '60px',
  },
  lbrandom2: {
    position: 'absolute',
    top: '70px',
    left: '100px',
  },

  lb1: {
    position: 'absolute',
    left: '20px',
  },
  lb2: {
    position: 'absolute',
    left: '50px',
  },
  lb3: {
    position: 'absolute',
    left: '80px',
  },
  lb4: {
    position: 'absolute',
    left: '110px',
  },
  lb5: {
    position: 'absolute',
    left: '140px',
  },
  lb6: {
    position: 'absolute',
    left: '170px',
  },
});

export default styles;
