import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import styles from "./../../_styles";
import Grid from "@material-ui/core/Grid";
import { SmallLocations } from "./LocationCmpts";

const useStyles = makeStyles(styles);

const LocationInstructionSlide = ({ current, next }) => {
    const classes = useStyles();
    const nextScreen = () => {
        next(current + 1);
    };

    return (
        <div className={classes.grid}>
            <Paper
                onClick={nextScreen}
                className={classes.paper2 + "  " + classes.pointer}
            >
                <Grid container direction="row">
                    <Grid item xs={4} md={4}>
                        <div className={classes.instBox}>
                            <div className={classes.loc_p1}>
                                <SmallLocations number={2} />
                            </div>
                            <div className={classes.loc_p5}>
                                <SmallLocations number={4} />
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={8} md={8} className={classes.instText}>
                        <br />
                        <br />
                        Você deverá memorizar localizações.
                    </Grid>
                    <Grid item xs={4} md={4}>
                        <div className={classes.instBox}></div>
                    </Grid>
                    <Grid item xs={8} md={8} className={classes.instText}>
                        <br />
                        <br /> Aguardar um intervalo.
                    </Grid>
                    <Grid item xs={4} md={4}>
                        <div className={classes.instBox + " " + classes.instBox2}>
                            <div className={classes.loc_p1}>
                                <SmallLocations number={1} />
                            </div>
                            <div className={classes.loc_p2}>
                                <SmallLocations number={2} />
                            </div>
                            <div className={classes.loc_p3}>
                                <SmallLocations number={3} />
                            </div>
                            <div className={classes.loc_p4}>
                                <SmallLocations number={4} />
                            </div>
                            <div className={classes.loc_p5}>
                                <SmallLocations number={5} />
                            </div>
                            <div className={classes.loc_p6}>
                                <SmallLocations number={6} />
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={8} md={8} className={classes.instText}>
                        <br />
                        <br /> Escolher as localizações que viu anteriormente.
                    </Grid>
                </Grid>
            </Paper>
        </div>
    );
};

const EndedLocationSlide = ({ current, next }) => {
    const classes = useStyles();
    const nextScreen = () => {
        next(current + 1);
    };

    return (
        <div className={classes.grid}>
            <Paper
                onClick={nextScreen}
                className={classes.paper + "  " + classes.pointer}
            >
                <div className={classes.textCenter}>
                    Você concluiu a tarefa das localizações.
                    <br></br> Agora iniciaremos a tarefa das combinações.
                    <div className={classes.clickToContinue}>
                        Clique na tela para continuar.
                    </div>
                </div>
            </Paper>
        </div>
    );
};

export { LocationInstructionSlide, EndedLocationSlide };
