/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import { ShowInfoOnConsole, RetensionSlide } from "../Colors/ColorSlides";
import {
    AnswerRelacionalSlide as AnswerSlide,
    RelacionalChoiceSlide as ChoiceSlide
} from "./RelacionalCmpts";
import { isInvertedNow, getCurrentInvertedState, correctAnswer, getIndexColor, _showConsoleInformation, storageData } from "../../../../utils";

const schema = {
    0: {},
    1: {
        timeout: "6000",
        nShapes: 2,
        correctTrials: 0,
        trials: 6,
        userAnswers: [],
        answers: []
    },
    2: {
        timeout: "9000",
        nShapes: 3,
        correctTrials: 0,
        trials: 6,
        userAnswers: [],
        answers: []
    },
    3: {
        timeout: "12000",
        nShapes: 4,
        correctTrials: 0,
        trials: 6,
        userAnswers: [],
        answers: []
    },
    4: { stop: true },
    5: { stop: true }
};

// level here means sublevel (level = internal step)
const ApplicationRelacionalRunning = ({ labelName = 'relacional', current, nextLvl, nextSubLevel, currentTheme }) => {
    const [level, setLevel] = useState(1);
    const [currentSlide, setCurrentSlide] = useState(null);
    const [reply, setReply] = useState();

    // getting next anwser for the same test
    useEffect(() => {
        if (reply != 0) return;
        setCurrentSlide(<AnswerSlide func={chosenColor} isInverted={getCurrentInvertedState()} />);
    }, [reply]);

    const nextScreen = () => {
        nextSubLevel(0);
        nextLvl(current + 1);
    };

    const chosenColor = (timeLoaded, colorName) => {
        const currentTime = Date.now();
        const diffTime = currentTime - timeLoaded;
        console.log("Interval :", diffTime, "colorName: ", colorName, " ColorIndex: ", getIndexColor(colorName));

        schema[level].userAnswers.push(colorName);
        schema[level].userTimes[schema[level].replies] = diffTime;
        schema[level].replies = schema[level].replies + 1;

        if (schema[level].replies < schema[level].nShapes * 2)
            setReply(schema[level].replies);

        if (schema[level].replies === schema[level].nShapes * 2)
            checkNextLevelOrRetry();
    };

    const checkNextLevelOrRetry = () => {
        const isCorrect = correctAnswer(
            schema[level].userAnswers,
            schema[level].answers
        );

        if (isCorrect) {
            schema[level].correctTrials += 1;
        } else {
            schema[level].wrongTrials += 1;
        }

        const totalTrials = schema[level].correctTrials + schema[level].wrongTrials;
        const isBlueSet = currentTheme === 'set2';
        if (isBlueSet) {
            schema[level].wasInverted = getCurrentInvertedState();
        }

        _showConsoleInformation("Application Relational", schema[level], isCorrect);

        storageData(
            labelName,
            level,
            isCorrect,
            schema[level].userAnswers,
            schema[level].answers,
            schema[level].correctTrials + schema[level].wrongTrials,
            schema[level].userTimes,
            schema[level].wasInverted
        );

        isInvertedNow(currentTheme === 'set2');

        //  Evalution for 3 trials
        if ((level === 4) && (totalTrials === schema[level].trials)) {
            nextScreen();
        } else if (totalTrials === schema[level].trials) {
            // 3 of 3 hits
            setLevel(level + 1);
        } else {
            // request other trial
            colorLoop();
        }
    };

    const AnswerLoop = () => {
        schema[level].userAnswers = [];
        schema[level].replies = 0;
        schema[level].userTimes = {};
        schema[level].wasInverted = null;
        setReply(0);

        setCurrentSlide(<AnswerSlide isInverted={getCurrentInvertedState()} />);
    };

    const retensionLoop = () => {
        setCurrentSlide(<RetensionSlide />);
        setTimeout(() => {
            AnswerLoop();
        }, 1000);
    };

    const setAnswers = colorName => {
        schema[level].answers.push(colorName);
    };

    const showAnswersAndToRetension = timeout => {
        retensionLoop();
    };

    const colorLoop = () => {
        const { timeout, nShapes } = schema[level];
        schema[level].answers = [];
        if (schema[level].stop) {
            nextScreen();
            return;
        }

        setCurrentSlide(
            <ChoiceSlide
                nShapes={nShapes}
                setAnswers={setAnswers}
                timeout={timeout}
                setRetension={showAnswersAndToRetension} />);
    };

    useEffect(() => {
        console.log("useEffect of Level", level);
        nextSubLevel(level);
        schema[level].correctTrials = 0;
        schema[level].wrongTrials = 0;

        colorLoop();
    }, [level]);

    return (
        <div>
            <ShowInfoOnConsole level={level} data={schema[level]} />
            {currentSlide}
        </div>
    );
};

export default ApplicationRelacionalRunning;
