/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import styles from "../../Relacional/_styles";
import { ClickToContinueSlide } from "../Colors/ColorSlides";

const useStyles = makeStyles(styles);

const SmallLocation = ({ number }) => {
  const classes = useStyles();
  if (number === 1) {
    return <div className={classes.smallLocation}>x</div>;
  }
  if (number === 2) {
    return <div className={classes.smallLocation}>x</div>;
  }
  if (number === 3) {
    return <div className={classes.smallLocation}>x</div>;
  }
  if (number === 4) {
    return <div className={classes.smallLocation}>x</div>;
  }
  if (number === 5) {
    return <div className={classes.smallLocation}>x</div>;
  }
  if (number === 6) {
    return <div className={classes.smallLocation}>x</div>;
  }
  return <div className={classes.smallLocation}>x</div>;
};

const SmallLocations = ({ number, onClick, color }) => {
  const classes = useStyles();
  return (
    <div className={classes.smallLocations} onClick={onClick}>
      <SmallLocation number={number} color={color} />
    </div>
  );
};

// /

const LocationBox = ({ number }) => {
  const classes = useStyles();
  return <div className={classes.shape}> X </div>;
};

const Locations = ({ number, onClick }) => {
  const classes = useStyles();
  return (
    //        <div className={classes.shapes} onClick={onClick}>
    <div
      key={number}
      onClick={onClick}
      className={classes[`location${number}of6`]}
    >
      <LocationBox number={number} />
    </div>
  );
};

const AnswerLocationSlide = ({ func }) => {
  const classes = useStyles();
  const timeLoaded = Date.now();
  const [answeredList, setAnsweredList] = useState([]);

  const myclick = (element) => {
    setAnsweredList([...answeredList, element]);
    func(timeLoaded, element);
  };

  const createLocations = (element, number) => {
    if (answeredList.includes(element)) {
      return null;
    }
    return <Locations onClick={() => myclick(element)} number={number} />;
  };

  return (
    <div className={classes.grid}>
      <Paper className={`${classes.paper} ${classes.pointer}`}>
        {createLocations("p1", 1)}
        {createLocations("p2", 2)}
        {createLocations("p3", 3)}
        {createLocations("p4", 4)}
        {createLocations("p5", 5)}
        {createLocations("p6", 6)}
      </Paper>
    </div>
  );
};

const LocationChoiceSlide = ({
  nShapes,
  setAnswers,
  setRetension,
  timeout,
}) => {
  const classes = useStyles();
  const [waiting, setWaiting] = useState(true);

  const listEl = [];
  const myIndex = ["1", "2", "3", "4", "5", "6"];

  useEffect(() => {
    if (!waiting) {
      setTimeout(() => {
        setRetension();
      }, timeout);
    }
  }, [waiting]);

  if (waiting) {
    return (
      <ClickToContinueSlide setWaiting={setWaiting}> </ClickToContinueSlide>
    );
  }

  for (let i = 1; i <= nShapes; i++) {
    const index = Math.floor(Math.random() * myIndex.length);
    const myNameIndex = myIndex[index];
    const myName = `p${myIndex[index]}`;
    myIndex.splice(index, 1);

    const el = (
      <div key={i} className={classes[`location${myNameIndex}of6`]}>
        <LocationBox />
      </div>
    );

    listEl.push(el);
    setAnswers(myName);
  }

  return (
    <div className={classes.grid}>
      <Paper className={`${classes.paper} ${classes.noMouse}`}>{listEl}</Paper>
    </div>
  );
};

export {
  AnswerLocationSlide,
  LocationChoiceSlide,
  LocationBox,
  Locations,
  SmallLocations,
};
