import Peer from 'peerjs';

export const getPeer = async () => {
    // const peer = new Peer({
    //   host: '192.168.0.17',
    //   port: 9000,
    //   path: '/peerjs',
    //   debug: 3,
    //   config: {
    //     iceServers: [{ urls: 'stun:stun1.l.google.com:19302' }, { urls: 'turn:numb.viagenie.ca', credential: 'muazkh', username: 'webrtc@live.com' }],
    //   },
    // });

    /*
    const peer = new Peer(
        {
            host: 'fc.di.gl',
            port: 443,
            secure: true,
            path: '/myapp',
            debug: 3,
        }
    );*/
    const peer = new Peer(
    );


    function retrySocketConnection(e) {
        console.log("retrySocketConnection");
        console.log("e", e);
        // informar que caiu a conexão

    }

    return new Promise((resolve, reject) => {
        peer.on('open', () => {
            resolve(peer);
        })
            .on('disconnected', () => {
                console.log("disconnected 1 ");
                reject("Disconnected");

                //  retrySocketConnection();
            })
            .on('error', (e) => {
                console.log('Non fatal error: ', e.type);
                reject(e.type);
            });

    });
};
