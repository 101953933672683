import { useEffect, useState } from 'react';
import { getPeer } from '../../Services/Peer/peer';

export const useCreatePeer = () => {
    const [peer, setPeer] = useState();
    const [error, setError] = useState();

    // init
    useEffect(() => {
        getPeer().then((peerResult) => {
            setPeer(peerResult);
            peerResult.on('error', (error) => {
                console.error('error', error);
                setError(error.message);
            });
        });
    }, []);

    // listen to data
    useEffect(() => {
        if (peer) {
            peer.on('error', (err) => {
                console.error('error', err);
                setError(error.message);
            });
        }
    }, [peer]);

    return [peer, error];
};

