import { firebaseDatabase } from "../firebaseUtils";

class FirebaseService {


  static pushData = (node, objToSubmit) => {
    console.log("pushData: ", node, objToSubmit);
    //const baseRef = firebaseDatabase.ref("workingmemory-test");
    const userRef = firebaseDatabase.ref(node).push();
    objToSubmit.check = "true";
    userRef.set(objToSubmit);
    //const nodeRef = baseRef.ref(node); //'participants'
    //    const id = nodeRef.push().set(objToSubmit);
    //  console.log("firebase", nodeRef, id);
    return userRef;
  };

  static remove = (id, node) => {
    return firebaseDatabase.ref(node + "/" + id).remove();
  };

  static getDataList = (node, callback) => {
    let queryRef = firebaseDatabase.ref(node);

    queryRef.on("value", dataSnapshot => {
      let items = [];
      dataSnapshot.forEach(childSnapshot => {
        let item = childSnapshot.val();
        item["key"] = childSnapshot.key;
        items.push(item);
      });
      callback(items);
    });

    return queryRef;
  };

  static getIdsCalls = (callback) => {
    let queryRef = firebaseDatabase.ref("videocall");

    console.log("queryRef", queryRef);
    queryRef.on("value", dataSnapshot => {
      let items = [];
      dataSnapshot.forEach(childSnapshot => {
        console.log("Registros (Firebase) ", childSnapshot);
        let item = childSnapshot.val();
        items.push(item);
      });
      callback(items);
    });

    return queryRef;
  };

  static addIdCall = (id) => {
    console.debug("addin id", id);
    let queryRef = firebaseDatabase.ref("videocall").push();
    queryRef.set(id);
    return queryRef;
  };

  static removeIdCall = (id) => {
    console.debug("removeIdCall", id);
    return firebaseDatabase.ref("videocall/" + id).remove();
  };



  static resetIdCall = (id) => {
    console.debug("removing all");
    firebaseDatabase.ref("videocall").remove();
    this.addIdCall(id);
  };

  static updateProgressUser = (current) => {
    console.debug("updateProgressUser", current);
    firebaseDatabase.ref("progressUser").remove();
    let queryRef = firebaseDatabase.ref("progressUser").push();
    queryRef.set(current);
    return queryRef;
  };

  static removeUser = (key, type) => {
    console.debug("removeUser", key);
    firebaseDatabase.ref("participants/" + key).remove();
  };


  static getProgressUser = () => {
    let queryRef = firebaseDatabase.ref("progressUser");
    let items = [];
    queryRef.on("value", dataSnapshot => {
      dataSnapshot.forEach(childSnapshot => {
        let item = childSnapshot.val();
        items.push(item);
      });
    });
    return items;
  };



}

export { FirebaseService };
