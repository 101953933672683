import Workbook from "react-excel-workbook";
import React from "react";
import Button from "@material-ui/core/Button";

const checkIsRight = (cols, resp) => {
    return cols.find((element) => element === resp);
};

const createSubColumns = (baselevel, trial, dt) => {
    const newColumns = {
        question1: "",
        question2: "",
        question3: "",
        question4: "",
        question5: "",
        question6: "",
        resp1: "",
        resp2: "",
        resp3: "",
        resp4: "",
        resp5: "",
        resp6: "",
        resp1_ACC: "",
        resp2_ACC: "",
        resp3_ACC: "",
        resp4_ACC: "",
        resp5_ACC: "",
        resp6_ACC: "",
        resp1_RT: "",
        resp2_RT: "",
        resp3_RT: "",
        resp4_RT: "",
        resp5_RT: "",
        resp6_RT: "",
        ncorrect: "",
        isInverted: "",
        trialcolumns: "",
    };
    let countCorrects = 0;

    if (!dt) {
        return newColumns;
    }

    // checking if there's answer in this level
    if (
        !dt[`level-${baselevel}-${trial}`]
        || !dt[`level-${baselevel}-${trial}`].answers
    ) {
        return newColumns;
    }


    // TODO: check this code, is Inverted is by trial
    newColumns['isInverted'] = dt[`level-${baselevel}-${trial}`].isInverted?.toString();

    // for binding/relational we concat the answer for color and shape/location
    for (let lvl = 1; lvl <= baselevel + 1; lvl += 1) {
        const pair = lvl * 2 - 1;
        // [lvl-1] is the pair with [lvl]
        newColumns[`question${lvl}`] = `${dt[`level-${baselevel}-${trial}`].answers[pair]
            },${dt[`level-${baselevel}-${trial}`].answers[pair - 1]}`; // fixing the wrong order in array
        newColumns[`resp${lvl}`] = `${dt[`level-${baselevel}-${trial}`].userAnswers[pair - 1]
            },${dt[`level-${baselevel}-${trial}`].userAnswers[pair]}`;
        if (dt[`level-${baselevel}-${trial}`].userTimes) {
            newColumns[`resp${lvl}_RT`] = `${dt[
                `level-${baselevel}-${trial}`
            ].userTimes[pair - 1].toString()},${dt[
                `level-${baselevel}-${trial}`
            ].userTimes[pair].toString()}`;
        }
    }


    // Checking that the answers are correct
    for (let lvl = 1; lvl <= baselevel + 1; lvl += 1) {
        if (
            checkIsRight(
                [
                    newColumns.question1,
                    newColumns.question2,
                    newColumns.question3,
                    newColumns.question4,
                    newColumns.question5,
                    newColumns.question6,
                ],
                newColumns[`resp${lvl}`],
            )
        ) {
            // if is correct
            newColumns[`resp${lvl}_ACC`] = "1";
            countCorrects += 1;
        } else {
            newColumns[`resp${lvl}_ACC`] = "0";
        }
    }

    newColumns.ncorrect = countCorrects.toString();

    if (countCorrects === baselevel) {
        newColumns.trialcolumns = "1";
    } else {
        newColumns.trialcolumns = "0";
    }
    return newColumns;
};


function createLineForEachParticipant(element) {
    const { userInfo } = element;
    // participant: userInfo,
    const base = {
        age: userInfo.age ? userInfo.age.toString() : "",
        name: userInfo.name ? userInfo.name.toString() : "",
        gender: userInfo.gender ? userInfo.gender.toString() : "",
        datetime: userInfo.datetime ? userInfo.datetime.toString() : "",
        handedness: userInfo.handedness ? userInfo.handedness.toString() : "",
        isOnline: "sim",
    };
    const partLines = [];
    console.log("Participante: ", JSON.parse(JSON.stringify(element)));
    // cor

    const testTypes = ["binding", "relational", "blue_binding", "blue_relational"];
    const ptTestTypes = ["binding", "relacional", "azul_binding", "azul_relacional"];

    for (let tests = 0; tests < 4; tests++) {
        let prova = 0;
        for (let level = 1; level <= 3; level++) {
            for (let trial = 1; trial <= 6; trial++) {
                prova += 1;
                let line = { ...base };
                line.prova = prova.toString();
                line.nitens = (level + 1).toString();
                line.condicao = ptTestTypes[tests];
                line = {
                    ...line,
                    ...createSubColumns(
                        level,
                        trial,
                        element[`${testTypes[tests]}${level}`],
                        ptTestTypes[tests] === "binding",
                    ),
                };

                partLines.push(line);
            }
        }
    }

    return partLines;
}

function ExcelTable({ title, data }) {
    console.log("data -> ", data);
    const allData = [];
    data.forEach((prtcpnt) => {
        const element = createLineForEachParticipant(prtcpnt);
        allData.push(...element);
    });

    return (
        <>
            <Workbook
                filename={`${title}.xlsx`}
                element={(
                    <Button style={{ position: "relative" }} color="primary">
                        Excel do Binding
                    </Button>
                )}
            >
                <Workbook.Sheet data={allData} name={title}>
                    <Workbook.Column label="Participante" value="participant" />
                    <Workbook.Column label="Nome" value="name" />
                    <Workbook.Column label="Idade" value="age" />
                    <Workbook.Column label="Sexo" value="gender" />
                    <Workbook.Column label="Data" value="datetime" />
                    <Workbook.Column label="Mão Dominante" value="handedness" />
                    <Workbook.Column label="Presencial/Online" value="isOnline" />
                    <Workbook.Column label="Prova" value="prova" />
                    <Workbook.Column label="N Items" value="nitens" />
                    <Workbook.Column label="Condição" value="condicao" />

                    <Workbook.Column label="correto1" value="question1" />
                    <Workbook.Column label="correto2" value="question2" />
                    <Workbook.Column label="correto3" value="question3" />
                    <Workbook.Column label="correto4" value="question4" />
                    <Workbook.Column label="correto5" value="question5" />
                    <Workbook.Column label="correto6" value="question6" />
                    <Workbook.Column label="resp1" value="resp1" />
                    <Workbook.Column label="resp2" value="resp2" />
                    <Workbook.Column label="resp3" value="resp3" />
                    <Workbook.Column label="resp4" value="resp4" />
                    <Workbook.Column label="resp5" value="resp5" />
                    <Workbook.Column label="resp6" value="resp6" />

                    <Workbook.Column label="resp1_ACC" value="resp1_ACC" />
                    <Workbook.Column label="resp2_ACC" value="resp2_ACC" />
                    <Workbook.Column label="resp3_ACC" value="resp3_ACC" />
                    <Workbook.Column label="resp4_ACC" value="resp4_ACC" />
                    <Workbook.Column label="resp5_ACC" value="resp5_ACC" />
                    <Workbook.Column label="resp6_ACC" value="resp6_ACC" />

                    <Workbook.Column label="resp1_RT" value="resp1_RT" />
                    <Workbook.Column label="resp2_RT" value="resp2_RT" />
                    <Workbook.Column label="resp3_RT" value="resp3_RT" />
                    <Workbook.Column label="resp4_RT" value="resp4_RT" />
                    <Workbook.Column label="resp5_RT" value="resp5_RT" />
                    <Workbook.Column label="resp6_RT" value="resp6_RT" />
                    <Workbook.Column label="Ncorrect" value="ncorrect" />
                    <Workbook.Column label="isInverted" value="isInverted" />
                    <Workbook.Column label="TrialCorrect" value="trialcolumns" />
                </Workbook.Sheet>
            </Workbook>
        </>
    );
}

export { ExcelTable };
